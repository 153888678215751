import React from "react";
import { formatDate } from "../helpers/helpers";
import BrandAvatar from "../images/account-statement-avatar.png";

export default function Transaction({ transaction, transactionType }) {
  const {
    campaignId,
    campaignImageLink,
    campaignName,
    maxLeadCreateTs,
    totalAmount,
    totalLead,
    createTs,
    pointsImage,
    pointsProvided,
    pointsProvidedFor,
    pointsProvidedRemark,
  } = transaction;

  return (
    <div className="Transaction mt-3 mb-6 flex justify-between">
      <div className="flex w-[70%] md:w-[75%]">
        {transactionType === "Cash" ? (
          <div className="flex h-14 w-14 items-center justify-center mr-6 overflow-hidden rounded-full border border-coolGrey">
            <img
              className="h-full w-full rounded-full object-cover object-center"
              src={campaignImageLink || pointsImage || BrandAvatar}
              alt="avatar"
            />
          </div>
        ) : (
          <div className="flex h-14 w-[76px] items-center justify-center mr-2 overflow-hidden rounded-[10px] border border-coolGrey">
            <img
              className="h-full w-full rounded-full object-cover object-center"
              src={campaignImageLink || pointsImage || BrandAvatar}
              alt="avatar"
            />
          </div>
        )}

        <div className="w-[65%] md:w-[70%]">
          <p className="truncate text-xs  capitalize text-darkGreen ">
            {campaignName && campaignName}
            {pointsProvidedFor && pointsProvidedFor}
          </p>
          <p className="truncate py-0.5 font-bold capitalize text-sm">
            {totalLead && `${totalLead || 0} Leads Generated`}
            {pointsProvidedRemark && `${pointsProvidedRemark}`}
          </p>
          <p className="text-xs text-bafGrey">
            {maxLeadCreateTs && formatDate(maxLeadCreateTs)}
            {createTs && formatDate(createTs)}
          </p>
        </div>
      </div>

      <p className="text-[0.625rem] xs:text-xl text-darkGreen">
        {totalAmount && `+$${totalAmount?.toFixed(2)}`}{" "}
        {pointsProvided && `+${pointsProvided} pts`}
      </p>
    </div>
  );
}
