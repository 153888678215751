import React, { useState } from "react";
// import { slashedDate, formatUTCtime } from "../helpers/helpers";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { abbreviateNumber } from "../helpers/helpers";

import SmallDownArrow from "../images/small-down-arrow.svg";
import eye from "../images/eye.svg";
import follow from "../images/follow.svg";
import unfollow from "../images/unfollow.png";
import Email from "../images/Email.svg";
import UnfollowModal from "../Modals/UnfollowModal";

export default function AffiliatesTableRow({
  id,
  handleSelect,
  selected,
  affiliateData,
  setRefreshView,
  token,
  refresh,
}) {
  //TODO add messaging function in PHASE 2

  const [expanded, setExpanded] = useState(false);
  const [showUnFollowModal, setShowUnFollowModa] = useState(false);
  const handleSelected = (id) => {
    handleSelect(id);
  };

  //   let formattedDate =
  //     slashedDate(affiliateData.createTs || affiliateData.affiliateDate) +
  //     " " +
  //     formatUTCtime(affiliateData.createTs || affiliateData.affiliateDate);

  const {
    status,
    affiliateId,
    firstName,
    lastName,
    totalPoints,
    tierName,
    tierStartPoint,
    tierEndPoint,
    userImage,
    followingAffiliate,
    countryName,
    stateName,
    totalLeads,
    totalShares,
    totalCampaignsShared,
  } = affiliateData || {};

  let navigate = useNavigate();

  const handleViewProfile = () => {
    navigate(`/affiliate-profile/${affiliateId}`);
  };

  // const handleMessage = () => {
  //   console.log("message");
  // };

  const handleFollow = () => {
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}followAffiliate`,

        {
          followerId: affiliateId,
        },
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        console.log(res);
        // alert(res.data.successMsg || res.data.errorMsg);
        refresh();
      });
  };

  const handleUnFollow = () => {
    setShowUnFollowModa((prev) => !prev);
  };

  return (
    <>
      {showUnFollowModal && (
        <UnfollowModal
          token={token}
          showModal={showUnFollowModal}
          handleModal={handleUnFollow}
          affiliateId={affiliateId}
          firstName={firstName}
        />
      )}
      <div
        className="AffiliatesTableRow w-full odd:bg-veryLightGreen  tracking-wider"
        onClick={() => handleSelected(id)}
      >
        <tr
          className={`${
            selected && "bg-darkPurple"
          } w-[100%] grid grid-cols-[4fr_2fr_2fr_1fr] justify-between items-center p-4 md:grid-cols-[6fr_3.5fr_3.5fr_3.5fr_2fr_2fr_1fr] lg:px-8 gap-2`}
        >
          <td className={`flex items-center text-sm`}>
            <img
              src={userImage}
              alt="avatar"
              className="h-10 hidden md:block rounded-[50%] border border-coolGrey mr-2"
            />{" "}
            <p className="font-bold line-clamp-1">{`${firstName} ${lastName}`}</p>
          </td>
          <td className={`flex md:hidden text-sm justify-center items-center`}>
            {followingAffiliate === 1 ? (
              <img
                src={unfollow}
                alt="unfollow"
                onClick={handleUnFollow}
                className="cursor-pointer w-5 h-5"
              />
            ) : (
              <img
                src={eye}
                alt="eye"
                onClick={handleViewProfile}
                className="cursor-pointer w-5 h-5"
              />
            )}
          </td>
          <td className={`flex md:hidden text-sm justify-center items-center`}>
            {followingAffiliate === 1 ? (
              <img
                src={eye}
                alt="eye"
                onClick={handleViewProfile}
                className="cursor-pointer w-5 h-5"
              />
            ) : (
              <img
                src={follow}
                alt="follow"
                onClick={handleFollow}
                className="cursor-pointer w-5 h-5"
              />
            )}
          </td>
          <td className={`hidden md:table-cell text-sm`}>
            <p className="">{abbreviateNumber(totalLeads)}</p>
          </td>
          <td className={`hidden md:table-cell text-sm`}>
            {abbreviateNumber(totalShares)}
          </td>
          <td className={`hidden md:table-cell text-sm`}>
            {totalCampaignsShared}
          </td>

          <td className={`hidden md:flex text-sm justify-center items-center`}>
            {followingAffiliate === 1 ? (
              <img
                src={unfollow}
                alt="unfollow"
                onClick={handleUnFollow}
                className="cursor-pointer w-5 h-5"
              />
            ) : (
              <img
                src={eye}
                alt="eye"
                onClick={handleViewProfile}
                className="cursor-pointer w-5 h-5"
              />
            )}
          </td>

          {/* <td
            className={`hidden md:flex text-sm justify-center items-center`}
          >
            <img
            src={Email}
            alt="Email"
            onClick={handleMessage}
            className="cursor-pointer w-5 h-5"
          />
          </td> */}

          <td className={`hidden md:flex text-sm justify-center items-center`}>
            {followingAffiliate === 1 ? (
              <img
                src={eye}
                alt="eye"
                onClick={handleViewProfile}
                className="cursor-pointer w-5 h-5"
              />
            ) : (
              <img
                src={follow}
                alt="follow"
                onClick={handleFollow}
                className="cursor-pointer w-5 h-5"
              />
            )}
          </td>

          <td className="text-sm flex justify-center">
            <button className="" onClick={() => setExpanded((prev) => !prev)}>
              <img
                src={SmallDownArrow}
                alt="arrow"
                className={`${expanded && "rotate-180"}`}
              />
            </button>
          </td>
        </tr>
        {/* TABLET & DESKTOP */}
        {expanded && (
          <tr className="hidden w-[100%] md:grid grid-cols-[6fr_3.5fr_3.5fr_3.5fr_2fr_2fr_1fr] justify-between p-4 lg:px-8 gap-2">
            {/* <td className="">
              <p></p>
            </td> */}
            <td className="max-w-[130.8px] col-start-2">
              <p className="text-xs text-bafGrey font-bold md:mb-2">Location</p>
              <p className="text-sm truncate w-[95%]">{`${stateName}, ${countryName}`}</p>
            </td>

            <td className="min-w-fit col-span-3">
              <p className="text-xs text-bafGrey font-bold md:mb-2">Tier</p>
              <p className="text-sm">{tierName}</p>
            </td>
          </tr>
        )}
        {/* MOBILE */}
        {expanded && (
          <div className="grid grid-cols-3 gap-4 w-full md:hidden p-4 mx-auto items-center">
            <div>
              <p className="text-xs text-bafGrey font-bold mb-2 truncate">
                Total Shares
              </p>
              <p className="text-sm">{abbreviateNumber(totalShares)}</p>
            </div>

            <div>
              <p className="text-xs text-bafGrey font-bold mb-2">Campaigns</p>
              <p className="text-sm">{totalCampaignsShared}</p>
            </div>

            <div>
              <p className="text-xs text-bafGrey font-bold mb-2 truncate">
                Total Leads
              </p>
              <p className="text-sm">{abbreviateNumber(totalLeads)}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
