import axios from "axios";
import { React, useEffect } from "react";
import HollowButton from "../Shared/HollowButton";
import { ReactComponent as CloseIcon } from "../images/modal-close-button-white.svg";

export default function UnfollowModal({
  showModal,
  affiliateId,
  handleModal,
  token,
  firstName,
}) {
  useEffect(() => {
    const body = document.body;
    body.classList.add("modal-open");

    return () => {};
  }, []);

  const toggleModal = () => {
    const body = document.body;
    body.classList.remove("modal-open");
    handleModal();
  };

  const unFollowAffiliate = (affiliateId) => {
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}unfollowAffiliate`,

        {
          followerId: affiliateId,
        },
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        window.location.reload();
      });
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-bafGrey bg-opacity-30 outline-none backdrop-brightness-[0.2] focus:outline-none">
            <div className="relative my-6 mx-auto h-[90%] w-[70%] min-w-[350px] max-w-[774px] max-h-[565px] rounded-2xl bg-bafBlack md:h-[80%]">
              <div className="relative flex h-full w-full flex-col justify-center rounded-2xl border-0 bg-black shadow-lg outline-none focus:outline-none">
                <div className="flex h-[400p] flex-col items-center justify-evenly p-5 md:h-[300px]">
                  <h3 className="text-center text-[30px] md:text-[40px] font-semibold text-bafWhite lg:w-[70%]">
                    {`Unfollow ${firstName}?`}
                  </h3>
                  <p className="w-[95%] mt-14 text-center text-lg font-semibold text-bafWhite md:text-xl md:mt-12 lg:w-[95%]">
                    You won’t be updated on this person’s activity anymore, but
                    you can always follow them back anytime!
                  </p>

                  <button
                    className="absolute border-0 bg-transparent text-black top-4 right-4"
                    onClick={() => toggleModal()}
                  >
                    <CloseIcon className="w-5 h-5" />
                  </button>
                </div>
                <div className="mt-14 flex flex-col items-center justify-evenly gap-11 md:flex-row md:justify-center md:gap-16">
                  <HollowButton
                    title="Unfollow"
                    color="bafWhite"
                    border="bafWhite"
                    type="button"
                    backgroundHoverWhite
                    medium
                    fullWidth
                    handleClick={() => unFollowAffiliate(affiliateId)}
                  />
                  <HollowButton
                    title="Cancel"
                    color="bafBlack"
                    background="bafWhite"
                    backgroundHoverPurple
                    type="button"
                    medium
                    fullWidth
                    handleClick={() => toggleModal()}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
