import React from "react";
import { slashedDate, formatTime } from "../helpers/helpers";

export default function PointsTransactionHistoryCard({ transaction }) {
  const {
    createTs,
    pointsProvided,
    pointsProvidedFor,
    pointsProvidedRemark,
    pointsImage,
  } = transaction;

  return (
    <div className="PointsTransactionHistoryCard shadow-[0px_4px_4px_rgba(0,0,0,0.1),5px_-1px_10px_#F3F3F3] rounded-[10px] md:flex md:h-[286px] md:max-w-[975px] md:w-full justify-between items-center bg-bafWhite">
      <div className="h-[181px] md:h-full">
        <img
          src={pointsImage}
          alt=""
          className="object-cover object-center h-[181px] w-full md:h-full md:object-contain"
        />
      </div>

      <div className="flex flex-col gap-5 pt-10 pb-9 px-11 md:w-[50%] md:h-full md:justify-between md:py-9 md:pr-11">
        <div>
          <h1 className="text-[2.5rem] leading-[57px] font-semibold">
            {`${pointsProvided} Points`}
          </h1>
        </div>
        <div className="flex flex-col justify-between gap-7 md:mt-0 md:grid md:grid-cols-2">
          <div className="">
            <p className="text-sm font-semibold mb-1.5">Objective</p>
            <p className="text-sm shadow-[inset_0_-0.6em_#B5FFB8] w-fit md:w-auto truncate active:overflow-visible active:whitespace-normal">
              {`${pointsProvidedFor}`}
            </p>
          </div>

          <div>
            <p className="text-sm font-semibold mb-1.5 md:hidden tablet:block">
              Completion Time
            </p>
            <p className="text-sm font-semibold mb-1.5 hidden md:block tablet:hidden">
              Time
            </p>
            <p className="text-sm truncate active:overflow-visible active:whitespace-normal">
              {`${slashedDate(createTs)} ${formatTime(createTs)}`}
            </p>
          </div>
        </div>

        <div className="">
          <p className="text-sm font-semibold mb-1.5">Objective Description:</p>
          <p className="text-sm">{`${pointsProvidedRemark}`}</p>
        </div>
      </div>
    </div>
  );
}
