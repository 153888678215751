import React from "react";
import affiliateImg from "../images/astronaut-avatar.png";
export default function PushNotification({ notification, close }) {
  console.log(notification);
  const { affiliateId, dealerId, imageLink, message } = notification || {};
  return (
    <article
      className={`${notification ? "visible" : "hidden "
        } animate-inAnimation max-w-[550px]   animate-outAnimation fixed w-[90%] bottom-4 p-4 rounded-xl z-10 flex justify-between items-center bg-bafBlack`}
    >
      <img
        src={imageLink || affiliateImg}
        alt="affiliate"
        className="h-12 w-12 rounded-full"
      />
      <p className="text-bafWhite text-xs">{message}</p>
      <button
        onClick={close}
        className="  border-0 bg-transparent mb-2 text-bafWhite text-4xl font-bold"
      >
        x
      </button>
    </article>
  );
}
