import React from "react";
import HollowButton from "../Shared/HollowButton";
import { abbreviateNumber } from "../helpers/helpers";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LeaveBrandModal from "../Modals/LeaveBrandModal";
import { useState } from "react";
import JoinBrandModal from "../Modals/JoinBrandModal";

export default function BrandCard({
  wrapperClass,
  brand,
  brandPerformance,
  brandType,
  token,
  refresh,
  index,
  brandFilter,
  currentPage,
}) {
  const [showLeaveModal, setShowLeaveModal] = useState(false);

  const [showJoinModal, setShowJoinModal] = useState(false);

  const {
    companyName,
    countryName,
    stateName,
    industryName,
    companyLogoUrl,
    companyBannerUrl,
    joinedDate,
    totalCampaigns,
    totalAffiliates,
    dealerId,
    myShares,
    myLeads,
    myEarnings,
    totalShares,
    totalLeads,
    avgEarning,
  } = brand || {};

  const navigate = useNavigate();

  const brandRedirect = () => {
    navigate(`/company-profile/${dealerId}`);
  };

  const getDisplayIndex = (currentPage) => {
    const displayIndex = {
      0: index + 1,
      1: index + 7,
      2: index + 13,
      3: index + 19,
      4: index + 25,
      5: index + 31,
      6: index + 37,
    };
    return displayIndex[currentPage];
  };

  const getBgColor = (index) => {
    const bgColor = {
      0: "bg-bafOrange",
      1: "bg-[#C6F3EB]",
      2: "bg-[#B5FFB8]",
      3: "bg-[#FFE6DF]",
      4: "bg-[#F2EAFF]",
      5: "bg-[#FAF5AC]",
    };
    return bgColor[index];
  };

  const handleJoinModal = () => {
    setShowJoinModal((prev) => !prev);
  };

  const handleLeaveModal = () => {
    setShowLeaveModal((prev) => !prev);
  };

  const followBrand = () => {
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}followBrand`,
        { dealerId: dealerId },
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        console.log(res);

        if (res.data.responseCode === 0) {
          // alert(`${res.data.successMsg}`);
          handleJoinModal();
          // window.location.reload();
        } else {
          alert(`Error. ${res.data.errorMsg}`);
          window.location.reload();
        }
      });
  };

  return (
    <>
      {showJoinModal && (
        <JoinBrandModal
          dealerId={dealerId}
          showModal={showJoinModal}
          handleModal={handleJoinModal}
          token={token}
          companyName={companyName}
        />
      )}
      {showLeaveModal && (
        <LeaveBrandModal
          dealerId={dealerId}
          showModal={showLeaveModal}
          handleModal={handleLeaveModal}
          token={token}
          companyName={companyName}
        />
      )}
      <div
        className={`BrandCard h-[323px] max-w-[300px] rounded-[10px] bg-bafWhite shadow-[0px_4px_4px_rgba(0,0,0,0.1),5px_-1px_10px_#F3F3F3] 
        ${wrapperClass || null}`}
      >
        <header className={`h-32 bg-cover bg-center bg-no-repeat`}>
          {index >= 0 && brandFilter === "topPerforming" && (
            <div
              className={`text-xl font-semibold ${
                getDisplayIndex(currentPage) > 9 ? "w-12" : "w-9"
              } relative top-6 text-center ${getBgColor(index)}`}
            >
              #{getDisplayIndex(currentPage)}
            </div>
          )}
          <img
            src={companyBannerUrl}
            className={`h-full w-full rounded-t-md object-cover ${
              brandFilter === "topPerforming" ? "-mt-[1.75rem]" : ""
            }`}
            alt="brand header"
          />
        </header>
        <div className="px-[22px] -mt-10">
          <div className="flex items-center justify-between">
            <img
              src={companyLogoUrl}
              className="h-[72px] w-[72px] rounded-full object-cover object-center border border-coolGrey shrink-0 bg-bafWhite"
              alt="brand header"
            />
            <div className="flex flex-col items-end truncate">
              <p className="text-xl font-semibold text-bafWhite">
                {companyName}
              </p>
              <p className="text-[0.625rem] leading-6 mt-2 truncate w-full text-end">
                {stateName} {", "} {countryName}{" "}
                <span className="text-coolGrey">&#8226;</span> {industryName}
              </p>
            </div>
          </div>
          <div
            className={`mt-6 px-[22px] justify-between ${
              brandPerformance ? "hidden" : "flex"
            }`}
          >
            <div className="flex flex-col items-center">
              <p className="text-xs text-bafGrey">Affiliates</p>
              <p className="text-xl font-bold">
                {totalAffiliates?.toLocaleString("en-US")}
              </p>
            </div>

            <div className="flex flex-col items-center">
              <p className="text-xs text-bafGrey">Campaigns</p>
              <p className="text-xl font-bold">
                {totalCampaigns?.toLocaleString("en-US")}
              </p>
            </div>
          </div>

          {/* Additional Info For Brand Performance Sections */}
          <div
            className={`mt-6 justify-between ${
              brandPerformance ? "flex" : "hidden"
            }`}
          >
            <div className="flex flex-col items-center">
              <p className="text-[0.625rem] text-bafBlack">My Shares</p>
              <p className="text-xl font-bold">{abbreviateNumber(myShares)}</p>
              <p className="text-[0.625rem] text-bafGrey">
                out of{" "}
                <span className="text-bafBlack">
                  {abbreviateNumber(totalShares)}
                </span>
              </p>
            </div>

            <div className="flex flex-col items-center">
              <p className="text-[0.625rem] text-bafBlack">My Leads</p>
              <p className="text-xl font-bold">{abbreviateNumber(myLeads)}</p>
              <p className="text-[0.625rem] text-bafGrey">
                out of{" "}
                <span className="text-bafBlack">
                  {abbreviateNumber(totalLeads)}
                </span>
              </p>
            </div>

            <div className="flex flex-col items-center">
              <p className="text-[0.625rem] text-bafBlack">Earnings</p>
              <p className="text-xl font-bold">{`$ ${abbreviateNumber(
                myEarnings
              )}`}</p>
              <p className="text-[0.625rem] text-bafGrey">
                <span className="text-bafBlack">${avgEarning?.toFixed(2)}</span>
                /lead
              </p>
            </div>
          </div>
        </div>
        {brandType && brandType === "exploreBrands" && (
          <footer className="mt-6 flex items-center justify-between px-6">
            <HollowButton
              title="View"
              fullWidth
              small
              backgroundHoverBlack
              className=""
              handleClick={brandRedirect}
            />
            <HollowButton
              title="Join"
              fullWidth
              small
              background="bafBlack"
              color="bafWhite"
              backgroundHoverPurple
              className=""
              handleClick={followBrand}
            />
          </footer>
        )}{" "}
        {brandType && brandType === "affiliatedBrands" && (
          <footer className="mt-6 flex items-center justify-between px-6">
            <HollowButton
              title="Leave"
              fullWidth
              small
              backgroundHoverBlack
              className=""
              handleClick={handleLeaveModal}
            />
            <HollowButton
              title="View"
              fullWidth
              small
              background="bafBlack"
              color="bafWhite"
              backgroundHoverPurple
              className=""
              handleClick={brandRedirect}
            />
          </footer>
        )}
        {brandPerformance && (
          <footer className="mt-6 flex items-center justify-between px-6">
            <HollowButton
              title="Leave"
              fullWidth
              small
              className=""
              handleClick={handleLeaveModal}
            />
            <HollowButton
              title="View"
              fullWidth
              small
              background="bafBlack"
              color="bafWhite"
              backgroundHover
              className=""
              handleClick={brandRedirect}
            />
          </footer>
        )}
      </div>
    </>
  );
}
