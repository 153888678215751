import React from "react";
import { twMerge } from "tailwind-merge";

export default function TransactionHistoryFilter({
  wrapperClassName,
  transactionType,
  setTransactionType,
  setCurrentPage,
}) {
  const handleLeadType = (type) => {
    setTransactionType(type);
    setCurrentPage(0);
  };

  return (
    <div
      className={`TransactionHistoryFilter flex justify-between items-center gap-4 md:gap-x-[60px] lg:justify-start ${wrapperClassName}`}
    >
      <h2
        className={`text-xl font-bold hover:text-bafBlack cursor-pointer
      ${
        transactionType === "Cash"
          ? "shadow-[inset_0_-0.6em_#B5FFB8] text-bafBlack"
          : "text-darkGray"
      }
      `}
        onClick={() => handleLeadType("Cash")}
      >
        Cash
      </h2>
      <h2
        className={`text-xl font-bold hover:text-bafBlack cursor-pointer
      ${
        transactionType === "Points"
          ? "shadow-[inset_0_-0.6em_#B5FFB8] text-bafBlack"
          : "text-darkGray"
      }
      `}
        onClick={() => handleLeadType("Points")}
      >
        Points
      </h2>
    </div>
  );
}
