import React from "react";
import { SpinnerDotted } from "spinners-react";

export default function LoadScreen() {
  return (
    <div className="mt-[25%] flex h-full w-full items-center justify-center">
      <SpinnerDotted className="mt-4" color="#A590E3" enabled />
    </div>
  );
}
