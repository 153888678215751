import React, { useState } from "react";
import HollowButton from "../Shared/HollowButton";
import InviteCardData from "./InviteCardData";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Astronaut from "../images/astronaut-avatar.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import UnfollowModal from "../Modals/UnfollowModal";

export default function AffiliateCard({
  affiliateData,
  repData,
  token,
  wrapperClass,
  following,
  refresh,
}) {
  const [showUnFollowModal, setShowUnFollowModal] = useState(false);

  const {
    affiliateId,
    firstName,
    lastName,
    totalPoints,
    tierName,
    tierImage,
    tierStartPoint,
    tierEndPoint,
    userImage,
    followingAffiliate,
    countryName,
    stateName,
    totalLeads,
    totalShares,
    totalCampaignsShared,
  } = affiliateData;

  let navigate = useNavigate();

  const percentage = ((totalPoints - tierStartPoint) / tierEndPoint) * 100;

  const handleViewProfile = () => {
    navigate(`/affiliate-profile/${affiliateId}`);
  };

  const handleShowUnFollowModal = () => {
    setShowUnFollowModal((prev) => !prev);
  };

  const followAffiliate = () => {
    console.log("init");
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}followAffiliate`,

        {
          followerId: affiliateId,
        },
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        console.log(res);
        // alert(res.data.successMsg || res.data.errorMsg);
        refresh();
      });
  };

  return (
    <>
      {showUnFollowModal && (
        <UnfollowModal
          token={token}
          showModal={showUnFollowModal}
          handleModal={handleShowUnFollowModal}
          affiliateId={affiliateId}
          firstName={firstName}
        />
      )}
      <div
        className={`InviteCardTabletDesktop relative w-[300px] Card rounded-xl bg-bafWhite px-6 py-5 md:h-[238px] lg:w-[301px] ${
          wrapperClass || ""
        }`}
      >
        <header className="relative flex items-center gap-2">
          <div>
            <div className="relative h-14 w-14">
              <img
                src={userImage || Astronaut}
                alt="astronaut"
                className="absolute z-[1] w-full h-full rounded-[50%] bg-bafWhite"
              />
              <div className="absolute h-14 w-14 scale-[1.1] ">
                <CircularProgressbar
                  value={percentage}
                  circleRatio={0.85}
                  background
                  styles={buildStyles({
                    pathColor: "#16DB93",
                    trailColor: "lightGray",
                    rotation: 1 / 2 + 1 / 14,
                    backgroundColor: "white",
                  })}
                />
              </div>
              <img
                src={tierImage}
                alt="badge"
                className="absolute -right-2 bottom-0 z-10 max-h-[1.5rem] rounded-[50%]"
              />
            </div>
          </div>
          {repData && (
            <img src={repData.userPhotoUrl} alt="astronaut" className="h-12" />
          )}
          <div className="ml-2">
            <p className="text-xs text-bafGrey">
              {stateName || (repData && repData.stateName)}, {""}
              {countryName || (repData && repData.countryName)}
            </p>
            <h2 className="max-w-[180px] truncate text-xl font-bold">
              {firstName || (repData && repData.firstName)}{" "}
              {lastName || (repData && repData.lastName)}
            </h2>
            <p className="text-xs text-darkGreen">{tierName}</p>
          </div>
        </header>

        <div className="InviteCardDataWrapper mt-6 flex justify-between">
          <InviteCardData number={totalLeads || 0} category="Total Leads" />
          <InviteCardData number={totalShares || 0} category="Total Shares" />
          <InviteCardData
            number={totalCampaignsShared || 0}
            category="Campaigns"
          />
        </div>

        {following ? (
          <footer className="mt-6 flex items-center justify-between gap-4">
            <HollowButton
              title="Unfollow"
              small
              fullWidth
              color="black"
              backgroundHoverBlack
              className="max-w-none"
              handleClick={handleShowUnFollowModal}
            />

            <Link to={`/affiliate-profile/${affiliateId}`} className="w-full">
              <HollowButton
                title="View Profile"
                small
                fullWidth
                type="button"
                background="bafBlack"
                color="bafWhite"
                backgroundHoverPurple
                handleClick={handleViewProfile}
                className="md:w-[116px] md:px-2"
              />
            </Link>
          </footer>
        ) : (
          <footer className="mt-6 flex items-center justify-between gap-4">
            <Link to={`/affiliate-profile/${affiliateId}`} className="w-full">
              <HollowButton
                title="View Profile"
                small
                fullWidth
                type="button"
                color="bafBlack"
                backgroundHoverBlack
                handleClick={handleViewProfile}
                className="md:w-[116px] md:px-2"
              />
            </Link>
            <HollowButton
              title="Follow"
              small
              fullWidth
              color="bafWhite"
              background="bafBlack"
              backgroundHoverPurple
              className=""
              handleClick={followAffiliate}
            />
          </footer>
        )}
      </div>
    </>
  );
}
