import React from "react";
import checkmark from "../images/check-mark.svg";

export default function ShareButton({
  handleSelect,
  selected,
  icon,
  title,
  description,
}) {
  return (
    <article
      onClick={() => handleSelect(title)}
      className={`${
        selected && "bg-bafWhite text-bafBlack"
      } h-36 flex flex-col mt-6 relative items-center justify-center border border-bafWhite w-32 rounded-lg`}
    >
      {icon}
      <p className="text-sm font-semibold mt-3 mb-2">{title}</p>
      <p className="text-xs">{description}</p>
      <img
        src={checkmark}
        alt="checkmark"
        className={`relative bottom-[-16px] ${
          selected ? "visible" : "invisible"
        } `}
      />
    </article>
  );
}
