import React from "react";
import Tooltip from "react-simple-tooltip";
import { twMerge } from "tailwind-merge";
import { ReactComponent as InfoIcon } from "../images/info-icon.svg";
import "../Pages/tooltip-styles.css";

export default function Input({
  title,
  value,
  onChange,
  id,
  type,
  error,
  onBlur,
  maxWidth,
  readOnly,
  bold,
  disabled,
  toolTipContent,
  labelClass,
  wrapperClass,
  hideIcon,
  onKeyDown,
  socialMedia,
  underline,
  min,
  max,
}) {
  return (
    <div className={twMerge(`w-full md:w-auto ${wrapperClass || ""}`)}>
      <div
        className={`${error && "text-errorRed"
          } form-group relative max-h-fit w-full 
      `}
      >
        <label
          htmlFor={id}
          // pointer-events-none flex items-center
          className={twMerge(`col-form-label mt-2 flex items-center gap-4 pl-1 text-[0.75rem] sm:text-[1.2rem]
          ${bold ? "font-bold" : ""} ${labelClass || ""}`)}
        >
          {title}{" "}
          <Tooltip
            arrow={0}
            content={toolTipContent}
            background="white"
            color="black"
            radius={"10px 0 10px 10"}
            padding={15}
            placement="bottom"
            fontSize="10px"
            className="custom-tooltip"
          >
            {hideIcon ? (
              ""
            ) : (
              <div>
                <InfoIcon />
              </div>
            )}
          </Tooltip>
        </label>
        <input
          readOnly={readOnly}
          type={type}
          id={id}
          className={
            !socialMedia
              ? ` w-full placeholder-transparent px-2 py-2 outline-none ${underline ? "border-b border-bafBlack" : "Input"
              }`
              : "border-b-2 border-darkGreen w-full placeholder-transparent outline-none ring-0 focus:ring-0 font-semibold text-lg"
          }
          placeholder={title}
          value={value}
          onChange={onChange}
          name={id}
          onBlur={onBlur}
          disabled={disabled}
          onKeyDown={onKeyDown}
          min={min || 1}
          max={max}
        />
      </div>
      <p className="ErrorMessage ml-1 mt-2 text-[0.625rem] text-errorRed">
        {error}
      </p>
    </div>
  );
}
