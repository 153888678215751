import React, { useEffect, useState } from "react";
import BrandafLogoWhite from "../images/brandaf-logo-white.svg";
import BrandafLogoBlack from "../images/brandaf-logo-black.svg";
import HollowButton from "../Shared/HollowButton";
import { ReactComponent as InfoIcon } from "../images/info-icon-no-bg.svg";
import { ReactComponent as SampleBadge1 } from "../images/sample-badge-1.svg";
import { ReactComponent as SampleBadge2 } from "../images/sample-badge-2.svg";
import { ReactComponent as SampleBadge3 } from "../images/sample-badge-3.svg";
import axios from "axios";
// import {
//   formatDate,
//   formatTime,
//   slashedDate,
//   uppercaseString,
// } from "../helpers/helpers";

const creditCard = "1111111111111111";

const maskCreditCard = (card) => {
  const formattedCard = creditCard.match(/.{1,4}/g).join(" ");
  const creditCardArray = Array.from(formattedCard);
  return creditCardArray;
};

export default function WalletCashBalanceCard({ token }) {
  const [walletInfo, setWalletInfo] = useState([]);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}getWalletInfo`,
        {},
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        setWalletInfo(res.data.response);
      });
  }, [token]);

  const { totalLeadAmount, tierName, cardRegistered } = walletInfo || [];

  return cardRegistered === 1 ? (
    <div className="WalletCashBalanceCard flex flex-col justify-between bg-lightOrange rounded-[5px] h-[272.7px] max-w-[435px] px-7 py-5 md:h-[247px] md:max-w-[394px] md:w-full md:py-4 md:px-6">
      <div className="flex justify-between">
        <div className="AmountWrapper">
          <p className="text-bafWhite text-[3rem] font-medium mb-2">
            {`$${totalLeadAmount}`}
          </p>
          <p className="text-bafWhite text-xs font-normal">Cash Balance</p>
        </div>
        <div className="TierWrapper mt-4">
          <img src={BrandafLogoWhite} alt="" className="mb-4 ml-auto" />
          <div className="text-end font-semibold">
            <p className="text-bafWhite">
              {tierName?.substring(0, tierName.indexOf("-"))}
            </p>
            <p className="text-bafWhite leading-4">
              {tierName?.substring(tierName.indexOf("-") + 1, tierName.length)}
            </p>
          </div>
        </div>
      </div>
      <div className="CreditCard flex justify-between">
        {creditCard &&
          maskCreditCard(creditCard).map((number, index) => {
            if (number !== " ") {
              return (
                <div
                  key={index}
                  className="w-3 h-3 bg-bafWhite rounded-full"
                ></div>
              );
            } else if (number === " ") {
              return <div key={index} className="w-3"></div>;
            }
          })}
      </div>
      <footer className="flex justify-between items-end">
        <div className="SSNGroup flex items-center gap-4 md:gap-2">
          <div className="flex items-center gap-1">
            <p className="text-bafWhite text-[0.5625rem] italic flex items-center">
              SSN
            </p>
            <InfoIcon />
          </div>
          {/* <HollowButton
            title="Withdraw"
            border="bafWhite"
            color="bafWhite"
            backgroundHoverBlack
            className="w-[78px] text-[0.625rem] h-[20px] py-0 md:text-[0.625rem] md:px-2 hover:border-bafBlack"
          /> */}
        </div>
        <div className="TierBadgeGroup flex items-end gap-2">
          <SampleBadge1 />
          <SampleBadge2 />
          <SampleBadge3 />
        </div>
      </footer>
    </div>
  ) : (
    <div className="WalletCashBalanceCard flex flex-col justify-between bg-bafWhite h-[272.7px] max-w-[435px] shadow-md rounded-[5px] px-7 py-6  md:h-[247px] md:max-w-[394px] md:w-full">
      <div className="flex justify-between">
        <div className="AmountWrapper">
          <p className=" text-[3rem] font-medium mb-4">
            {" "}
            {`$${totalLeadAmount}`}
          </p>
          <p className=" text-xs font-normal">Cash Balance</p>
        </div>
        <div className="TierWrapper mt-4">
          <img src={BrandafLogoBlack} alt="" className="mb-4 ml-auto" />
          <div className="text-end font-semibold">
            <p className="">
              {" "}
              {tierName?.substring(0, tierName.indexOf(["-"]))}
            </p>
            <p className="">
              {" "}
              {tierName?.substring(tierName.indexOf("-") + 1, tierName.length)}
            </p>
          </div>
        </div>
      </div>
      <footer className="flex justify-between items-end">
        <div className=" text-[0.875rem]">No card registered yet.</div>
      </footer>
    </div>
  );
}
