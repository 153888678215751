import { useState, useEffect, useContext } from "react";

import { useFormik } from "formik";
import SignUpInput from "../Shared/SignUpInput";
import Container from "../Shared/Container";
import HollowButton from "../Shared/HollowButton";
import axios from "axios";
import * as Yup from "yup";
import AuthContext from "../Authentication/auth-context";
import { Link } from "react-router-dom";
import YupPassword from "yup-password";
import "react-toggle/style.css";
import astronaut from "../images/login-astronaut.gif";
import SignUpBackground from "./SignUpBackground";

export default function LoginPage() {
  const authCtx = useContext(AuthContext);
  const [error, setError] = useState("");
  const [loginType, setLoginType] = useState("local");

  useEffect(() => {
    document.title = "Brandaf - Affiliate Login";
  }, []);
  const login = `${process.env.REACT_APP_PROD_URL}login`;
  YupPassword(Yup);

  const formik = useFormik({
    initialValues: {
      emailId: "",
      password: "",
    },
    validationSchema: Yup.object({
      emailId: Yup.string()
        .email("Email isn't valid")
        .required("Please enter your email address"),
      password: Yup.string()
        .required("Password is required")
        .minLowercase(1, "Password must contain one lowercase letter")
        .minUppercase(1, "Password must contain one uppercase letter")
        .minSymbols(1, "Password must contain one special character")
        .minNumbers(1, "Password must contain at least 1 number")
        .min(8, "Password must have 8 or more characters"),
    }),
    onSubmit: (values, { resetForm }) => {
      axios
        .post(
          login,
          { ...values, loginType },
          {
            headers: { "Access-Control-Allow-Origin": "*" },
          }
        )
        .then((res) => {
          const user = {
            affiliateId: res.data.response.affiliateId,
          };
          console.log(res.data);
          if (res.data.responseCode === 0) {
            authCtx.login(res.data.response.token);
            authCtx.handleUser(user);
            window.location.reload();
          } else {
            setError(res.data.errorMsg);
          }
        });
    },
  });

  return (
    <div className=" flex min-h-screen flex-col  bg-[url('/src/images/login-background.gif')] bg-bafBlack md:flex-row ">
      <SignUpBackground />

      <form
        className={
          "flex rounded-md bg-bafWhite sm:mt-8 md:flex items-center  sm:min-h-[600px] overflow-y-scroll md:h-screen md:mt-0 md:rounded-none md:w-1/2"
        }
        onSubmit={formik.handleSubmit}
      >
        <Container>
          <div className="  md:px-8  max-w-[600px]">
            <h1 className="mt-8 text-4xl font-semibold">Log In</h1>
            <p className="mt-4 text-sm">
              Don't have an account?
              <Link
                to="/sign-in"
                className="ml-1 shadow-[inset_0_-0.4em_#BFFFBC]"
              >
                Sign up
              </Link>{" "}
              here.
            </p>
            <fieldset className="mt-8 flex flex-col justify-between ">
              <SignUpInput
                title="E-mail"
                value={formik.values.emailId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="emailId"
                error={formik.touched.emailId && formik.errors.emailId}
              />
              <SignUpInput
                type="password"
                title="Password"
                id="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                error={formik.touched.password && formik.errors.password}
              />
            </fieldset>
            <p className="text-sm text-errorRed transition-all">{error}</p>
            <div className="mt-8 md:mt-12 mb-1 ">
              <label
                for="red-toggle"
                class="relative mr-5 inline-flex cursor-pointer items-center"
              >
                <input
                  type="checkbox"
                  value=""
                  id="red-toggle"
                  class="peer sr-only"
                />
                <div class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-0.5 after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-darkTeal peer-checked:after:translate-x-full peer-checked:after:border-white  dark:border-gray-600 dark:bg-gray-700 "></div>
                <span class="ml-3 text-sm  text-gray-900 dark:text-gray-300">
                  Stay Signed in
                </span>
              </label>
            </div>
            <Link to="/forgot-email" className="text-xs text-darkPurple">
              Lost your credentials?
            </Link>
            <footer className="my-8 flex justify-end">
              <HollowButton title="Next" medium className="w-[150px]" />
            </footer>
          </div>
        </Container>
      </form>
    </div>
  );
}
