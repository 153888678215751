import HollowButton from "../Shared/HollowButton";

export default function NotFoundPage() {
  return (
    <div className="bg-[url('/src/images/404-crying-astronaut-mobile.gif')] md:bg-[url('/src/images/404-crying-astronaut.gif')] bg-bafBlack bg-contain bg-no-repeat bg-bottom md:bg-cover lg:[background-position:_0%] relative z-10 min-h-screen flex justify-center overflow-hidden md:justify-start">
      <header className="p-12 md:mt- md:w-[65%] lg:mt-20 xl:mt-40 xl:w-[60%] xl:p-24">
        <h1 className="font-semibold text-bafWhite text-[40px]">Oh no!</h1>
        <p className="text-bafWhite mt-8 text-sm">
          Sorry! It looks like we weren’t able to find the page you’re looking
          for.
        </p>
        <HollowButton
          title="Go Home"
          className="h-[56px] w-[176px] rounded-full mt-10"
        />
      </header>
    </div>
  );
}
