import React, { useState } from "react";
import { formatDate, abbreviateNumber } from "../helpers/helpers";
import HollowButton from "../Shared/HollowButton";
import SmallDownArrow from "../images/small-down-arrow.svg";
import { useNavigate } from "react-router-dom";
import ShareCampaignModal from "../Modals/ShareCampaignModal";

export default function CampaignTableRow({
  id,
  campaign,
  pathName,
  handleSelect,
  selected,
  token,
  leadType,
  index,
  currentPage,
  campaignFilter,
}) {
  const {
    dealerId,
    campaignId,
    campaignName,
    companyName,
    campaignStartDate,
    campaignEndDate,
    totalEarning,
    totalLeads,
    totalShares,
    leadPayoutPerCoversion,
    status,
    brandJoined,
    campaignDescription,
    affiliateFollowingBrand,
  } = campaign || {};

  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const handleSelected = (id) => {
    handleSelect(id);
  };

  const handleView = () => {
    navigate(`/single-campaign/${campaignId}`);
  };

  const handleViewBrand = () => {
    navigate(`/company-profile/${dealerId}`);
  };

  const handleShare = () => {
    setShowShareModal((prev) => !prev);
  };

  const getDisplayIndex = (currentPage) => {
    const displayIndex = {
      0: index + 1,
      1: index + 7,
      2: index + 13,
      3: index + 19,
      4: index + 25,
      5: index + 31,
      6: index + 37,
    };
    return displayIndex[currentPage];
  };

  return (
    <>
      {showShareModal && (
        <ShareCampaignModal
          showModal={showShareModal}
          toggleModal={handleShare}
          campaign={campaign}
          token={token}
        />
      )}
      <div
        className="CampaignTableRow w-full odd:bg-veryLightGreen"
        onClick={() => handleSelected(id)}
      >
        <tr
          className={`${
            selected && "bg-darkPurple"
          } w-[100%] items-center p-4 tablet:px-8 gap-4 grid ${
            campaignFilter === "topPerforming"
              ? "grid-cols-[0.6fr_2.25fr_75px_0.5fr] tablet:grid-cols-[1fr_3fr_repeat(5,2fr)_0.5fr]"
              : "grid-cols-[3fr_75px_0.5fr] tablet:grid-cols-[3fr_repeat(5,2fr)_0.5fr]"
          }`}
        >
          {campaignFilter === "topPerforming" && (
            <td className={`text-sm`}>{`#${getDisplayIndex(currentPage)}`}</td>
          )}
          <td className={`overflow-hidden self-start`}>
            <p className="text-sm font-bold truncate mr-1">{campaignName}</p>
          </td>
          <td className={`flex items-center text-sm tablet:text-sm self-start`}>
            <div
              className={`mr-1 h-2 w-2 rounded-[50%] ${
                status === "active" ? "bg-darkGreen" : "bg-coolGrey"
              }`}
            ></div>
            <span className="text-sm">
              {status &&
                (status === "active" ? "ACTIVE" : status.toUpperCase())}
            </span>
          </td>
          <td className={`hidden tablet:table-cell self-start`}>
            <p className="text-sm">{companyName}</p>
          </td>
          <td className={`hidden tablet:table-cell w-[90%] self-start`}>
            <p className="text-sm">
              {`${formatDate(campaignStartDate, true)} - ${formatDate(
                campaignEndDate,
                true
              )}`}
            </p>
          </td>

          <td className={`hidden tablet:table-cell `}>
            <HollowButton
              title="View"
              fullWidth
              backgroundHoverBlack
              handleClick={handleView}
            />
          </td>
          <td className={`hidden tablet:table-cell`}>
            {leadType === undefined || leadType === "newCampaigns" ? (
              brandJoined === 1 || affiliateFollowingBrand === 1 ? (
                status === "active" ? (
                  <HollowButton
                    title="Share"
                    background="bafBlack"
                    color="bafWhite"
                    backgroundHoverPurple
                    fullWidth
                    handleClick={handleShare}
                    className="tablet:px-3 transition-none"
                  />
                ) : null
              ) : (
                <HollowButton
                  title="Join Brand"
                  background="bafBlack"
                  color="bafWhite"
                  backgroundHoverPurple
                  fullWidth
                  handleClick={handleViewBrand}
                  className="md:px-0"
                />
              )
            ) : leadType === "sharedCampaigns" && status === "active" ? (
              <HollowButton
                title="Reshare"
                background="bafBlack"
                color="bafWhite"
                backgroundHoverPurple
                fullWidth
                handleClick={handleShare}
                className="tablet:px-0"
              />
            ) : null}
          </td>

          <td className="text-sm flex justify-center tablet:ml-0">
            <button className="" onClick={() => setExpanded((prev) => !prev)}>
              <img
                src={SmallDownArrow}
                alt="arrow"
                className={`${expanded && "rotate-180"}`}
              />
            </button>
          </td>
        </tr>
        {/* TABLET & DESKTOP */}
        {expanded && (
          <tr
            className={`hidden w-[100%] gap-4 tablet:grid items-start ${
              campaignFilter === "topPerforming"
                ? "grid-cols-[1fr_3fr_repeat(5,2fr)_0.5fr]"
                : "grid-cols-[3fr_repeat(5,2fr)_0.5fr]"
            } p-4 tablet:px-8`}
          >
            <td
              className={`${
                campaignFilter === "topPerforming" ? "col-span-4" : "col-span-3"
              }`}
            >
              <p className="text-xs text-bafGrey font-bold tablet:mb-2">
                Description
              </p>
              <p className="text-xs break-words line-clamp-2">
                {campaignDescription}
              </p>
            </td>
            <td className="">
              <p className="text-xs text-bafGrey font-bold tablet:mb-2">
                Total Shares
              </p>
              <p className="text-sm">{abbreviateNumber(totalShares)}</p>
              {leadType === "sharedCampaigns" && (
                <p className="text-[10px]">{`out of ${abbreviateNumber(
                  totalShares
                )}`}</p>
              )}
            </td>
            <td className="">
              <p className="text-xs text-bafGrey font-bold tablet:mb-2">
                Total Leads
              </p>
              <p className="text-sm">{abbreviateNumber(totalLeads)}</p>
              {leadType === "sharedCampaigns" && (
                <p className="text-[10px]">{`out of ${abbreviateNumber(
                  totalLeads
                )}`}</p>
              )}
            </td>
            <td className="">
              <p className="text-xs text-bafGrey font-bold tablet:mb-2 tablet:-mr-1">
                Total Earnings
              </p>
              <p className="text-sm">
                {`$${totalEarning?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}`}
              </p>
              {leadType === "sharedCampaigns" && (
                <p className="text-[10px]">{`$${leadPayoutPerCoversion?.toLocaleString(
                  "en-US",
                  { minimumFractionDigits: 2 }
                )} per lead`}</p>
              )}
            </td>
          </tr>
        )}
        {/* MOBILE */}
        {expanded && (
          <div className=" gap-4 w-full items-start tablet:hidden px-4 py-7 mx-auto">
            <div
              className={`grid ${
                campaignFilter === "topPerforming"
                  ? "grid-cols-[0.6fr_2.25fr_75px_0.5fr]"
                  : "grid-cols-[3fr_75px_0.5fr]"
              } gap-4`}
            >
              <div
                className={`${
                  campaignFilter === "topPerforming" && "col-span-2"
                }`}
              >
                <p className="text-xs text-bafGrey font-bold mb-2">Company</p>
                <p className="text-sm break-words tablet:max-w-none">
                  {companyName}
                </p>
              </div>

              <div className="col-span-2">
                <p className="text-xs text-bafGrey font-bold mb-2">
                  Active Date
                </p>
                <p className="text-sm">
                  {`${formatDate(campaignStartDate, true)} - ${formatDate(
                    campaignEndDate,
                    true
                  )}`}
                </p>
              </div>
            </div>
            <div className="col-span-2 mt-7">
              <p className="text-xs text-bafGrey font-bold mb-2">Description</p>
              <p className="text-xs break-words line-clamp-2">
                {campaignDescription}
              </p>
            </div>
            <div className="grid grid-cols-3 mt-7 gap-2">
              <div>
                <p className="text-xs text-bafGrey font-bold mb-2">
                  Total Shares
                </p>
                <p className="text-sm">{abbreviateNumber(totalShares)}</p>
              </div>

              <div>
                <p className="text-xs text-bafGrey font-bold mb-2">
                  Total Leads
                </p>
                <p className="text-sm">{abbreviateNumber(totalLeads)}</p>
              </div>

              <div>
                <p className="text-xs text-bafGrey font-bold mb-2">
                  Total Earnings
                </p>
                <p className="text-sm">{`$${totalEarning?.toLocaleString(
                  "en-US",
                  { minimumFractionDigits: 2 }
                )}`}</p>
              </div>
            </div>
            <footer className="flex justify-center mt-6 gap-4">
              <HollowButton
                title="View"
                fullWidth
                backgroundHoverBlack
                small
                handleClick={handleView}
              />
              {leadType === "newCampaigns" ? (
                brandJoined === 1 || affiliateFollowingBrand === 1 ? (
                  <HollowButton
                    title="Share"
                    background="bafBlack"
                    color="bafWhite"
                    backgroundHoverPurple
                    fullWidth
                    small
                    handleClick={handleShare}
                  />
                ) : (
                  <HollowButton
                    title="Join Brand"
                    background="bafBlack"
                    color="bafWhite"
                    backgroundHoverPurple
                    fullWidth
                    small
                    handleClick={handleViewBrand}
                  />
                )
              ) : status === "active" ? (
                <HollowButton
                  title="Reshare"
                  background="bafBlack"
                  color="bafWhite"
                  backgroundHoverPurple
                  fullWidth
                  small
                  handleClick={handleShare}
                />
              ) : null}
            </footer>
          </div>
        )}
      </div>
    </>
  );
}
