import React, { useState } from "react";
import { abbreviateNumber } from "../helpers/helpers";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import Avatar from "../images/astronaut-avatar.png";
import HollowButton from "./HollowButton";
import LeaveBrandModal from "../Modals/LeaveBrandModal";
import LeavePageModal from "../Modals/LeavePageModal";
import RefreshViewButton from "./RefreshViewButton";

export default function BrandAffiliationCard({
  editMode,
  brand,
  token,
  refresh,
}) {
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showLeavePageModal, setShowLeavePageModal] = useState(false);

  let navigate = useNavigate();

  const { pathname } = useLocation();

  const {
    dealerId,
    companyName,
    companyLogoUrl,
    affiliateFollowingBrand,
    totalAffiliate,
  } = brand || {};

  const handleLeaveModal = () => {
    setShowLeaveModal((prev) => !prev);
  };

  const brandRedirect = () => {
    navigate(`/company-profile/${dealerId}`);
  };

  const handleLeavePageModal = () => {
    setShowLeavePageModal((prev) => !prev);
  };

  const joinBrand = () => {
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}followBrand`,
        { dealerId: dealerId },
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        console.log(res);
        refresh();
      });
  };

  return (
    <>
      <LeaveBrandModal
        showModal={showLeaveModal}
        handleModal={handleLeaveModal}
        token={token}
        dealerId={dealerId}
        companyName={companyName}
      />
      <LeavePageModal
        showModal={showLeavePageModal}
        handleModal={handleLeavePageModal}
        navigate={() => brandRedirect(dealerId)}
      />
      <div className="BrandAffiliationCard mb-5">
        <div className="Card flex items-center gap-3 px-5 py-4 w-full h-[95px] md:max-w-[478px] md:gap-2 md:px-4 tablet:gap-4">
          <img
            src={companyLogoUrl || Avatar}
            alt="avatar"
            className=" h-[50px] w-full max-w-[50px] rounded-full object-cover border border-coolGrey bg-bafWhite"
          />
          <div className="flex flex-wrap items-center gap-1 w-full">
            <h1 className="w-full text-md font-semibold text-xl truncate">
              {companyName}
            </h1>
            <p className="text-sm w-fit md:text-[10px] tablet:text-sm">
              <span className="text-bafBlack font-bold">
                {totalAffiliate && abbreviateNumber(totalAffiliate)}
              </span>{" "}
              <span className="text-darkGray">Affiliates</span>
            </p>

            <div className="ml-auto flex gap-2 items-center justify-center phone:mt-auto phone:flex-row md:flex md:justify-right">
              {pathname.includes("/profile") ? (
                <>
                  {editMode && (
                    <HollowButton
                      title="Leave"
                      type="button"
                      handleClick={handleLeaveModal}
                      background="bafWhite"
                      color="bafBlack"
                      className="rounded-full py-[6px] w-[78px] text-xs md:px-[2px]"
                    />
                  )}
                  <HollowButton
                    title="View"
                    type="button"
                    handleClick={
                      editMode
                        ? () => handleLeavePageModal()
                        : () => brandRedirect(dealerId)
                    }
                    background="bafBlack"
                    color="bafWhite"
                    backgroundHover
                    className="border-0 rounded-full text-xs py-[7px] w-[78px] md:px-[2px]"
                  />
                </>
              ) : (
                <>
                  <HollowButton
                    title={affiliateFollowingBrand ? "Leave" : "Join"}
                    handleClick={
                      affiliateFollowingBrand ? handleLeaveModal : joinBrand
                    }
                    background="bafWhite"
                    backgroundHoverBlack
                    color="bafBlack"
                    className="rounded-full py-[2px] md:px-[2px] md:min-w-[75px] min-w-[68px] text-xs md:text-[10px]"
                  />

                  <HollowButton
                    title="View"
                    handleClick={brandRedirect}
                    background="bafBlack"
                    color="bafWhite"
                    className="rounded-full py-[2px] md:px-[2px] md:min-w-[75px] min-w-[68px] text-xs md:text-[10px]"
                    backgroundHover
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
