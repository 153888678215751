import React from "react";

export default function GeneratedleadsTableFilter({
  wrapperClassName,
  leadType,
  setLeadType,
  positiveLeadsCount,
  rejectedLeadsCount,
  pendingLeadsCount,
  setCurrentPage,
}) {
  const handleLeadType = (type) => {
    setLeadType(type);
    setCurrentPage(0);
  };

  return (
    <div
      className={`GeneratedLeadsFilterWrapper flex justify-between items-center md:gap-x-4 lg:justify-start lg:gap-x-6 ${wrapperClassName}`}
    >
      <div className="flex items-center gap-2">
        <h2
          className={`text-xl font-bold hover:text-[#000000] cursor-pointer
        ${
          leadType === "positive"
            ? "shadow-[inset_0_-0.6em_#B5FFB8] text-bafBlack"
            : "text-darkGray"
        }
        `}
          onClick={() => handleLeadType("positive")}
        >
          Positive
        </h2>
        <div
          className={`rounded-lg hidden md:flex justify-center items-center px-2 h-[1.5rem] ${
            leadType === "positive" ? "bg-[#B5FFB8]" : "bg-[#D7D7D8]"
          } `}
        >
          <p
            className={`text-xs ${
              leadType === "positive" && "text-bafBlack font-bold"
            }`}
          >
            {positiveLeadsCount?.toLocaleString("en-US") || 0}
          </p>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <h2
          className={`text-xl font-bold hover:text-[#000000] cursor-pointer
        ${
          leadType === "rejected"
            ? "shadow-[inset_0_-0.6em_#B5FFB8] text-bafBlack"
            : "text-darkGray"
        }
        `}
          onClick={() => handleLeadType("rejected")}
        >
          Rejected
        </h2>
        <div
          className={`rounded-lg hidden md:flex justify-center items-center px-2 h-[1.5rem] ${
            leadType === "rejected" ? "bg-[#B5FFB8]" : "bg-[#D7D7D8]"
          } `}
        >
          <p
            className={`text-xs ${
              leadType === "rejected" && "text-bafBlack font-bold"
            }`}
          >
            {rejectedLeadsCount?.toLocaleString("en-US") || 0}
          </p>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <h2
          className={`text-xl font-bold hover:text-[#000000] cursor-pointer
          ${
            leadType === "pending"
              ? "shadow-[inset_0_-0.6em_#B5FFB8] text-bafBlack"
              : "text-darkGray"
          }
          `}
          onClick={() => handleLeadType("pending")}
        >
          Pending
        </h2>
        <div
          className={`rounded-lg hidden md:flex justify-center items-center px-2 h-[1.5rem] ${
            leadType === "pending" ? "bg-[#B5FFB8]" : "bg-[#D7D7D8]"
          } `}
        >
          <p
            className={`text-xs ${
              leadType === "pending" && "text-bafBlack font-bold"
            }`}
          >
            {pendingLeadsCount?.toLocaleString("en-US") || 0}
          </p>
        </div>
      </div>
    </div>
  );
}
