import React from "react";
import { Link } from "react-router-dom";

export default function LinkHighlight({
  title,
  to,
  external,
  highlight,
  bold,
}) {
  return external ? (
    <li className="LinkHighlight mx-2 max-w-fit min-w-fit list-none py-1">
      <a
        target="_blank"
        rel="noreferrer"
        href={to}
        className="group hidden min-w-fit max-w-fit text-xs font-semibold transition duration-300 md:inline md:text-sm  lg:font-bold lg:text-sm"
      >
        {title}
        <span className="block h-0.5 max-w-0 bg-lightGreen transition-all duration-500 group-hover:max-w-full"></span>
      </a>
    </li>
  ) : (
    <li className="LinkHighlight max-w-fit min-w-fit list-none py-2">
      <Link
        to={to}
        className={`group max-w-fit min-w-fit text-xs md:text-sm ${
          bold && "font-bold"
        } transition duration-300 hover:shadow-[inset_0_-0.6em_#BFFFBC] lg:text-base ${
          highlight && "shadow-[inset_0_-0.6em_#BFFFBC]"
        } `}
      >
        {title}
      </Link>
    </li>
  );
}
