import React from "react";
import { useState, useEffect, useContext } from "react";
import HollowButton from "../Shared/HollowButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import YupPassword from "yup-password";
import CustomSelect from "../Shared/CustomSelect";
import Checkbox from "../Shared/Checkbox";
import Container from "../Shared/Container";
import Heading from "../Shared/Heading";
// import AuthContext from "./auth-context";
import axios from "axios";
import SignUpInput from "../Shared/SignUpInput";
import InfoInput from "../Shared/InfoInput";

// import DragDropFile from "../Shared/DragDropFile";
// import SignUpTextArea from "../Shared/SignUpTextArea";
// import RadioButton from "../Shared/RadioButton";
import useDropDownValues from "../hooks/useDropDownValues";
import useStateValues from "../hooks/useStateValues";
import useCompanyValues from "../hooks/useCompanyValues";
import useInterestValues from "../hooks/useInterestValues";

import spaceship from "../images/starships.gif";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  slashedDate,
  switchConversion,
  switchHearAbout,
  switchIndustryType,
  switchRegion,
} from "../helpers/helpers";
import { Link, useNavigate, useNavigation } from "react-router-dom";
// import Facebook from "../images/facebook-icon.svg";
// import Instagram from "../images/instagram-icon.svg";
// import Twitter from "../images/twitter-icon.svg";
import SoftwareCreatorsPolicyModal from "../Modals/SoftwareCreatorsPolicyModal";
import PrivacyAgreementModal from "../Modals/PrivacyAgreementModal";
import TermsOfServiceModal from "../Modals/TermsOfServiceModal";
import useCityValues from "../hooks/useCityValues";
import DropdownCheckbox from "../Shared/DropdownCheckbox";
import BrandAffiliationCard from "../Shared/BrandAffiliationCard";
import SignUpBackground from "./SignUpBackground";
import LinkHighlight from "../Shared/LinkHighlight";

YupPassword(Yup);

export default function SignUp() {
  const [page, setPage] = useState(1);
  const [showCreatorsPolicy, setCreatorsPolicy] = useState(false);
  const [showPrivacyAgreement, setPrivacyAgreement] = useState(false);
  const [showTermsOfService, setTermsOfService] = useState(false);
  const [checkCreatorsPolicy, setCheckCreatorsPolicy] = useState(false);
  const [checkPrivacyAgreement, setCheckPrivacyAgreement] = useState(false);
  const [checkTermsOfService, setCheckTermsOfService] = useState(false);
  const [responseError, setResponseError] = useState("");
  const [affiliateId, setAffiliateId] = useState();
  const [firstPageError, setFirstPageError] = useState("");

  const navigate = useNavigate();

  const handleCreatorsPolicy = () => {
    setCreatorsPolicy((prev) => !prev);
  };
  const handlePrivacyAgreement = () => {
    setPrivacyAgreement((prev) => !prev);
  };
  const handleTermsOfService = () => {
    setTermsOfService((prev) => !prev);
  };
  const handleCheckCreatorsPolicy = () => {
    formik.values.creators = true;
  };
  const handleCheckPrivacyAgreement = () => {
    formik.values.privacy = true;
  };
  const handleCheckTermsOfService = () => {
    formik.values.terms = true;
  };

  const nextPage = () => {
    setPage((prevPage) => (prevPage += 1));
    window.scrollTo(0, 0);
  };
  const prevPage = () => {
    setPage((prevPage) => (prevPage -= 1));
    window.scrollTo(0, 0);
  };

  const today = new Date().getFullYear();
  const minAge = today - 13;
  const [dob, setDob] = useState(new Date(String(minAge)));
  console.log(today - 13);

  const rePhoneNumber = "^[0-9]{10}$";
  const reLetters = "^[a-zA-Z]+";
  const reURL =
    "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})";

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      country: "",
      region: "",
      city: "",
      cityId: "",
      company: "",
      companyId: "",
      referralCode: "",
      interests: "",
      tiktokLink: "",
      instagramLink: "",
      linkedinLink: "",
      facebookLink: "",
      twitterrLink: "",
      creators: false,
      privacy: false,
      terms: false,
      marketing: false,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required("First name is required")
        .matches(reLetters, "Name can only contain letters"),
      lastName: Yup.string()
        .required("Last name is required")
        .matches(reLetters, "Name can only contain letters"),
      email: Yup.string()
        .email("Email isn't valid")
        .required("Please enter your email address"),
      phone: Yup.string()
        .matches(rePhoneNumber, "Phone number is not valid")
        .required("Please enter your phone number"),
      password: Yup.string()
        .required("Password is required")
        .minLowercase(1, "Password must contain one lowercase letter")
        .minUppercase(1, "Password must contain one uppercase letter")
        .minSymbols(1, "Password must contain one special character")
        .minNumbers(1, "Password must contain at least 1 number")
        .min(8, "Password must have 8 or more characters"),
      confirmPassword: Yup.string().required("Field is required"),
      company: Yup.string().required("Company name is required"),
      interests: Yup.array().required("Industry type is required"),
      country: Yup.string().required("Country is required"),
      region: Yup.string().required("Province is required"),
      tiktokLink: Yup.string(),
      instagramLink: Yup.string(),
      linkedinLink: Yup.string(),
      metaLink: Yup.string(),
      twitterrLink: Yup.string(),
    }),
    onSubmit: (values, { resetForm }) => {
      const code = localStorage.getItem("code");
      if (page === 2) {
        const register = `${process.env.REACT_APP_PROD_URL}register`;

        const data = {
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          email: values.email,
          password: values.password,
          tiktokLink: values.tiktokLink,
          instagramLink: values.instagramLink,
          facebookLink: values.facebookLink,
          twitterLink: values.twitterrLink,
          referalCode: values.referralCode,
          countryId: values.country === "Canada" ? 2 : 1,
          stateId: switchRegion(values.region),
          cityId: values.cityId,
          intrestAreaList: values.interests.map((interest) =>
            switchIndustryType(interest)
          ),
          companyList: [values.companyId],
          dob: slashedDate(dob),
        };
        console.log(data);
        axios
          .post(register, data, {
            headers: { "Access-Control-Allow-Origin": "*" },
          })
          .then((res) => {
            console.log(res);
            if (res.data.responseCode === 0) {
              setAffiliateId(res.data.response.affiliateId);
              nextPage();
            } else {
              setResponseError(res.data.errorMsg);
            }
          });
      } else {
        axios
          .post(
            validateCode,
            {
              affiliateId: affiliateId || 493,
              otpNumber: code,
            },
            {
              headers: { "Access-Control-Allow-Origin": "*" },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.data.responseCode === 0) {
              const token = res.data.response.token;
              localStorage.setItem("token", token);
              nextPage();
              setTimeout(() => {
                window.location.reload();
                // navigate("/overview");
              }, 5000);
            } else {
              setError(res.data.errorMsg);
            }
          });
      }
    },
  });

  console.log(
    formik.values.interests &&
      formik.values.interests.map((interest) => switchIndustryType(interest))
  );
  console.log(formik.errors);
  const countryId = formik.values.country === "Canada" ? 2 : 1;

  const validateUser = async () => {
    const data = {
      emailId: formik.values.email,
      phoneNumber: formik.values.phone,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_PROD_URL}checkPhoneEmailUsed`,
      data
    );

    if (response.data.responseCode === -1) {
      setFirstPageError(response.data.errorMsg);
    } else {
      nextPage();
    }
  };

  //* Custom Hooks
  const dropDown = useDropDownValues();
  const states = useStateValues(countryId);
  const cities = useCityValues(switchRegion(formik.values.region));
  const companies = useCompanyValues();
  const interests = useInterestValues();

  //* Populate Dropdown Values With API Data
  const countries =
    dropDown.countryList &&
    dropDown.countryList.map((type) => ({
      value: type.countryName,
      label: type.countryName,
    }));

  const interestNames =
    interests &&
    interests.intrestList &&
    interests.intrestList.map((interest) => interest.industryName);

  const stateValues =
    states &&
    states.stateList &&
    states.stateList.map((state) => ({
      value: state.stateName,
      label: state.stateName,
    }));

  const citiesValues =
    cities &&
    cities.cityList &&
    cities.cityList.map((city) => ({
      value: city.cityName,
      label: city.cityName,
      id: city.cityId,
    }));

  const companyValues =
    companies &&
    companies.companyList &&
    companies.companyList.map((company) => ({
      value: company.companyName,
      label: company.companyName,
      id: company.dealerId,
    }));

  //* Disable Values
  const disableNextButton =
    !formik.values.firstName ||
    !formik.values.lastName ||
    !formik.values.phone ||
    formik.errors.firstName ||
    formik.errors.lastName ||
    !formik.values.email ||
    formik.errors.email ||
    formik.errors.phone
      ? true
      : false;

  const disableSubmitButton =
    formik.errors.password ||
    !formik.values.marketing ||
    !formik.values.creators ||
    !formik.values.terms ||
    !formik.values.privacy
      ? true
      : false;

  // const disableCRM =
  //   formik.values.konect === "No" || !formik.values.konect ? true : false;
  // const disableTikTok = formik.values.tiktok ? false : true;
  // const disableInstagram = formik.values.instagram ? false : true;
  // const disableMeta = formik.values.meta ? false : true;
  // const disableTwitter = formik.values.twitter ? false : true;
  // const disableYoutube = formik.values.youtube ? false : true;
  // const disableLinkedin = formik.values.linkedin ? false : true;
  // const disableOther = formik.values.other ? false : true;

  // const resetFormField = (field, resetField) => {
  //   console.log(field, resetField);
  //   if (!formik.values[field]) {
  //     return;
  //   } else {
  //     formik.values[resetField] = "";
  //   }
  // };

  // const resetCrm = () => {
  //   formik.values.crm = "";
  // };

  const [error, setError] = useState("");

  const handleInterest = (interests) => {
    formik.setFieldValue("interests", interests);
  };
  useEffect(() => {
    document.title = "Brandaf - Affiliate Sign Up";
  }, []);
  const forgotPassword =
    "https://api.brandfiliate.net/v2/dealer/forgotPasswordNew";

  const validateCode = `${process.env.REACT_APP_PROD_URL}validateOtp`;

  const useSSNFields = () => {
    const [ssnValues, setValue] = React.useState({
      ssn1: "",
      ssn2: "",
      ssn3: "",
      ssn4: "",
      ssn5: "",
      ssn6: "",
    });
    const resetCode = Object.values(ssnValues);
    const actualCode = resetCode.join("");

    return {
      handleChange: (e) => {
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");

        // Check if they hit the max character length
        if (value.length >= maxLength) {
          // Check if it's not the last input field
          if (parseInt(fieldIndex, 10) < 6) {
            // Get the next input field
            const nextSibling = document.querySelector(
              `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
            );

            // If found, focus the next field
            if (nextSibling !== null) {
              nextSibling.focus();
            }
          }
        }

        useSSNFields.resetCode = actualCode;

        setValue({
          ...ssnValues,
          [`ssn${fieldIndex}`]: value,
        });
      },
      resetCode: actualCode,
    };
  };

  const handlePageChange = (path) => {
    navigate(path);
  };

  const formikObject = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string().required("Please enter your phone number"),
    }),
    onSubmit: (values, { resetForm }) => {
      const code = localStorage.getItem("code");
      if (page === 1) {
        axios
          .post(
            forgotPassword,
            { phoneNumber: formik.values.phone },
            {
              headers: { "Access-Control-Allow-Origin": "*" },
            }
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.responseCode === 0) {
              setPage((page) => page + 1);
            } else {
              setError(res.data.errorMsg);
              setTimeout(() => {
                setError("");
              }, 5000);
            }
          });
      } else {
        axios
          .post(
            validateCode,
            {
              phoneNumber: formik.values.phone,
              resetCode: code,
            },
            {
              headers: { "Access-Control-Allow-Origin": "*" },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.data.responseCode === 0) {
              handlePageChange(
                `/reset-password/resetCode/${code}/userId/${res.data.response.userId}`
              );
            } else {
              setError(res.data.errorMsg);
            }
          });
      }
    },
  });
  const resendCode = () => {
    axios
      .post(
        forgotPassword,
        { phoneNumber: formik.values.phone },
        {
          headers: { "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.responseCode === 0) {
          return;
        } else {
          setError(res.data.errorMsg);
          setTimeout(() => {
            setError("");
          }, 5000);
        }
      });
  };

  const handleCompany = (value) => {
    formik.setFieldValue("company", value.value);
    formik.setFieldValue("companyId", value.id);
  };

  const handleCity = (value) => {
    console.log(value);
    formik.setFieldValue("city", value.value);
    formik.setFieldValue("cityId", value.id);
  };

  const SSNField = () => {
    const { handleChange, resetCode } = useSSNFields();
    if (resetCode.length === 6) {
      localStorage.setItem("code", resetCode);
    }
    return (
      <>
        <input
          className={`h-12 w-10 rounded-md border text-center ${
            error ? "border-red" : "border-bafBlack"
          }`}
          name="ssn-1"
          maxLength={1}
          onChange={handleChange}
          onPaste={handleChange}
        />
        <input
          className={`h-12 w-10 rounded-md border text-center ${
            error ? "border-red" : "border-bafBlack"
          }`}
          name="ssn-2"
          maxLength={1}
          onChange={handleChange}
        />
        <input
          className={`h-12 w-10 rounded-md border text-center ${
            error ? "border-red" : "border-bafBlack"
          }`}
          name="ssn-3"
          maxLength={1}
          onChange={handleChange}
        />

        <input
          className={`h-12 w-10 rounded-md border text-center ${
            error ? "border-red" : "border-bafBlack"
          }`}
          name="ssn-4"
          maxLength={1}
          onChange={handleChange}
        />
        <input
          className={`h-12 w-10 rounded-md border text-center ${
            error ? "border-red" : "border-bafBlack"
          }`}
          name="ssn-5"
          maxLength={1}
          onChange={handleChange}
        />
        <input
          className={`h-12 w-10  rounded-md  !border text-center ${
            error ? "border-red" : "!border-bafBlack"
          }`}
          name="ssn-6"
          maxLength={1}
          onChange={handleChange}
        />
      </>
    );
  };

  return (
    <div className=" ] min-h-screen overflow-hidden bg-bafBlack  bg-[url('/src/images/stars.gif')]  bg-center md:flex">
      <SignUpBackground />

      <form
        onSubmit={formik.handleSubmit}
        className={` max-h-screen min-h-screen  overflow-y-scroll scrollbar-hide ${
          page === 1 || page === 2
            ? "flex-1 md:w-1/2 md:bg-bafWhite lg:w-1/2"
            : ""
        }`}
      >
        {/* First Page */}
        {page === 1 && (
          <>
            <section className="my-8 rounded-xl bg-bafWhite md:mt-[4rem] md:flex items-center md:h-screen md:rounded-none md:px-2">
              <Container>
                <div className=" h-[50%] max-w-[700px]  md:px-8 lg:px-12 xl:pr-36">
                  <h1 className="mt-8 text-4xl font-semibold">Sign Up</h1>
                  <p className="mt-4 text-sm">
                    Have an account?{" "}
                    <Link
                      to="/login"
                      className="shadow-[inset_0_-0.4em_#BFFFBC]"
                    >
                      Log in
                    </Link>{" "}
                    here.
                  </p>
                  <fieldset className="mt-8 flex flex-col justify-between ">
                    <SignUpInput
                      title="First Name"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      id="firstName"
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                    <SignUpInput
                      title="Last Name"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      id="lastName"
                      onBlur={formik.handleBlur}
                      error={formik.touched.lastName && formik.errors.lastName}
                    />
                    <SignUpInput
                      title="Phone Number"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="phone"
                      error={formik.touched.phone && formik.errors.phone}
                    />
                    <SignUpInput
                      title="E-mail"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="email"
                      error={formik.touched.email && formik.errors.email}
                    />
                  </fieldset>
                  <p className="text-sm text-errorRed">{firstPageError}</p>
                  <div className="mt-8 flex justify-end">
                    <HollowButton
                      title="Next"
                      medium
                      handleClick={validateUser}
                      disabled={disableNextButton}
                      className="w-[150px]"
                      type="button"
                    />
                  </div>
                </div>
              </Container>
            </section>
            {/* <footer className="bg-black fixed inset-x-0 bottom-0 mt-10 h-20"></footer> */}
          </>
        )}

        {/* Second Page */}
        {page === 2 && (
          <section className=" rounded-xl bg-bafWhite md:my-[8rem] lg:px-2 xl:mx-[6rem]">
            <Container>
              <Heading>Hey {formik.values.firstName}!</Heading>
              <p className="text-xs sm:text-sm">
                Let's get you started and create your account.
              </p>
              {/* Password */}
              <SignUpInput
                type="password"
                title="Password"
                id="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                error={formik.touched.password && formik.errors.password}
              />
              <SignUpInput
                type="password"
                title="Re-Type Password"
                id="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.password &&
                  formik.values.password !== formik.values.confirmPassword &&
                  "Passwords don't match"
                }
              />
              <p className="py-4">Password must have the following:</p>
              <ul className="list-disc">
                <li className="ml-8 tracking-wider">
                  length of 8 or more characters
                </li>
                <li className="ml-8 tracking-wider">one lowercase letter</li>
                <li className="ml-8 tracking-wider">one uppercase letter</li>
                <li className="ml-8 tracking-wider">one numerical</li>
                <li className="ml-8 tracking-wider">one special character</li>
              </ul>

              {/* Marketing */}
              <section className="Location">
                <Heading>Location</Heading>
                <p className="text-xs sm:text-sm">Where are you from?</p>
                <fieldset className=" flex  flex-col justify-evenly">
                  <CustomSelect
                    options={countries}
                    value={formik.values.country}
                    onBlur={formik.handleBlur}
                    label="Country"
                    onChange={(value) =>
                      formik.setFieldValue("country", value.value)
                    }
                    error={formik.touched.country && formik.errors.country}
                    className=""
                  />

                  <CustomSelect
                    options={stateValues}
                    value={formik.values.region}
                    onBlur={formik.handleBlur}
                    label="Region"
                    onChange={(value) =>
                      formik.setFieldValue("region", value.value)
                    }
                    error={formik.touched.region && formik.errors.region}
                    className=" "
                  />

                  <CustomSelect
                    options={citiesValues}
                    value={formik.values.city}
                    onBlur={formik.handleBlur}
                    label="City"
                    onChange={(value) => handleCity(value)}
                    error={formik.touched.city && formik.errors.city}
                    className=" "
                  />
                </fieldset>
              </section>

              {/* Billing */}
              <section className="Date Of Birth">
                <Heading>Date Of Birth</Heading>
                <p className="text-xs sm:text-sm">When is your birthday?</p>
                <fieldset className="mt-2  flex flex-col justify-evenly">
                  <DatePicker
                    selected={dob}
                    onChange={(date) => setDob(date)}
                    className="Input border-none"
                    maxDate={new Date(String(minAge))}
                  />
                </fieldset>
              </section>

              {/* Brand Sign Up */}
              <section className="BrandSignUp mt-8">
                <h1 className="mt-8 inline-block">Referral Code</h1>{" "}
                <span className="font-semibold">(Optional)</span>
                <p className="mb-8 text-xs sm:text-sm">
                  We're you referred by someone?
                </p>
                <SignUpInput
                  title="Referral Code (Optional)"
                  value={formik.values.referralCode}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  id="referralCode"
                  error={
                    formik.touched.referralCode && formik.errors.referralCode
                  }
                />
                <fieldset className="mt-8">
                  <Heading>Interests</Heading>
                  <p className="mb-8 text-xs sm:text-sm">
                    What topics are you interested in?
                  </p>
                  {/* Custom drop down will go here  */}
                  <DropdownCheckbox
                    options={interestNames}
                    handleList={handleInterest}
                  />
                </fieldset>
                <fieldset className="mt-8">
                  <h1 className="mt-8 inline-block">Affiliation</h1>{" "}
                  <span className="font-semibold">(Optional)</span>
                  <p className="mb-8 text-xs sm:text-sm">
                    Choose a company you'd like to promote.
                  </p>
                  {/* Custom drop down will go here  */}
                  <CustomSelect
                    options={companyValues}
                    value={formik.values.company}
                    onBlur={formik.handleBlur}
                    label="Company"
                    onChange={(value) => handleCompany(value)}
                    error={formik.touched.company && formik.errors.company}
                    className=" "
                  />
                  <div>{/* <BrandAffiliationCard /> */}</div>
                </fieldset>
                <fieldset className="mt-8">
                  <Heading>Social Media Links</Heading>
                  {/* Custom link component will go here  */}
                  <div className="Card p-4 my-3">
                    <InfoInput
                      socialMedia
                      hideIcon
                      title="Facebook"
                      value={formik.values.facebookLink}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      id="facebookLink"
                      error={
                        formik.touched.facebookLink &&
                        formik.errors.facebookLink
                      }
                    />
                  </div>
                  <div className="Card p-4 my-3">
                    <InfoInput
                      socialMedia
                      hideIcon
                      title="Instagram"
                      value={formik.values.instagramLink}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      id="instagramLink"
                      error={
                        formik.touched.instagramLink &&
                        formik.errors.instagramLink
                      }
                    />
                  </div>
                  <div className="Card p-4 my-3">
                    <InfoInput
                      socialMedia
                      hideIcon
                      title="Twitter"
                      value={formik.values.twitterrLink}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      id="twitterrLink"
                      error={
                        formik.touched.twitterrLink &&
                        formik.errors.twitterrLink
                      }
                    />
                  </div>
                  <div className="Card p-4 my-3">
                    <InfoInput
                      socialMedia
                      hideIcon
                      title="Tiktok"
                      value={formik.values.tiktokLink}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      id="tiktokLink"
                      error={
                        formik.touched.tiktokLink && formik.errors.tiktokLink
                      }
                    />
                  </div>
                </fieldset>
              </section>

              {/* Terms of Service */}
              <section className="TermsOfService">
                <Heading>Terms of Service</Heading>
                <div className="my-14 flex flex-col gap-8">
                  <Checkbox
                    name="marketing"
                    label={
                      <label className="text-xs lg:text-sm">
                        I accept future marketing from Brandaf
                      </label>
                    }
                    onChange={formik.handleChange}
                    value={formik.values.marketing}
                    roundedCorners
                    backgroundColor="transparent"
                    borderColor="darkTeal"
                    checked={formik.values.marketing}
                  />

                  <Checkbox
                    name="creators"
                    checked={formik.values.creators}
                    label={
                      <label className="text-xs lg:text-sm">
                        I agree to the{" "}
                        <a className="underline">
                          {" "}
                          <button
                            className="shadow-[inset_0_-0.4em_#BFFFBC]"
                            onClick={handleCreatorsPolicy}
                          >
                            {" "}
                            Brandaf Software Creators Policy{" "}
                          </button>{" "}
                        </a>{" "}
                      </label>
                    }
                    onChange={formik.handleChange}
                    value={formik.values.creators}
                    roundedCorners
                    backgroundColor="transparent"
                    borderColor="darkTeal"
                  />

                  <Checkbox
                    name="privacy"
                    checked={formik.values.privacy}
                    label={
                      <label className="text-xs lg:text-sm">
                        I agree to the{" "}
                        <a className="underline">
                          {" "}
                          <button
                            className="shadow-[inset_0_-0.4em_#BFFFBC]"
                            onClick={handlePrivacyAgreement}
                          >
                            {" "}
                            Privacy Agreements{" "}
                          </button>{" "}
                        </a>{" "}
                      </label>
                    }
                    onChange={formik.handleChange}
                    value={formik.values.privacy}
                    roundedCorners
                    backgroundColor="transparent"
                    borderColor="darkTeal"
                  />

                  <Checkbox
                    name="terms"
                    checked={formik.values.terms}
                    label={
                      <label className="text-xs lg:text-sm">
                        I have agreed and read the{" "}
                        <a className="underline">
                          {" "}
                          <button
                            className="shadow-[inset_0_-0.4em_#BFFFBC]"
                            onClick={handleTermsOfService}
                          >
                            {" "}
                            Terms of Service{" "}
                          </button>{" "}
                        </a>{" "}
                      </label>
                    }
                    onChange={formik.handleChange}
                    value={formik.values.terms}
                    roundedCorners
                    backgroundColor="transparent"
                    borderColor="darkTeal"
                  />
                </div>
                <p className="mb-4 text-sm text-errorRed">{responseError}</p>
              </section>
              <SoftwareCreatorsPolicyModal
                showModal={showCreatorsPolicy}
                handleModal={setCreatorsPolicy}
                showCheck={checkCreatorsPolicy}
                handleCheck={handleCheckCreatorsPolicy}
              />
              <PrivacyAgreementModal
                showModal={showPrivacyAgreement}
                handleModal={setPrivacyAgreement}
                showCheck={checkPrivacyAgreement}
                handleCheck={handleCheckPrivacyAgreement}
              />
              <TermsOfServiceModal
                showModal={showTermsOfService}
                handleModal={setTermsOfService}
                showCheck={checkTermsOfService}
                handleCheck={handleCheckTermsOfService}
              />
              <div className="flex justify-between">
                <HollowButton
                  title="Back"
                  handleClick={prevPage}
                  className="w-[150px]"
                  medium
                />
                <HollowButton
                  title="Submit"
                  medium
                  color="bafWhite"
                  background="bafBlack"
                  className="w-[150px]"
                  disabled={disableSubmitButton}
                />
              </div>
            </Container>
            {/* Second page ends */}
          </section>
        )}
        {/* Third Page */}
        {page === 3 && (
          <section className=" flex h-full min-h-[450px] w-full  flex-col justify-center rounded-md bg-bafWhite p-4 sm:mt-8 sm:min-h-[500px] md:mt-0 md:w-[50vw]">
            <div className="flex max-w-[400px] flex-col md:ml-8 lg:mx-20">
              <h2 className=" text-4xl">One Time Verification Code</h2>
              <p className=" mt-4 text-xs max-w-[350px]">
                A one time verification code is being sent to you through
                e-mail. Please enter the code below to activate your account.
              </p>
              <fieldset className="my-4 flex min-w-[300px] max-w-[400px] justify-between">
                <SSNField />
              </fieldset>
              <p className="mt-2 sm:mt-4 text-xs ">
                Didn’t get the code?{" "}
                <span
                  onClick={resendCode}
                  className="  shadow-[inset_0_-0.4em_#BFFFBC] hover:cursor-pointer"
                >
                  Resend now.
                </span>
              </p>
              <p className="text-sm mt-2 text-errorRed"> {error}</p>
              <footer className="mt-6 flex items-center justify-between">
                <HollowButton
                  title="Back"
                  handleClick={prevPage}
                  className="w-[150px]"
                  medium
                />
                <HollowButton
                  title="Submit"
                  medium
                  color="bafWhite"
                  background="bafBlack"
                  className="w-[150px]"
                />
              </footer>
            </div>
          </section>
        )}
        {/* Fourth Page */}
        {page === 4 && (
          <section className=" flex h-full min-h-[450px] w-full  flex-col justify-center rounded-md bg-bafWhite p-4 sm:mt-8 sm:min-h-[500px] md:mt-0 md:w-[50vw]">
            <div className="flex max-w-[400px] flex-col md:ml-8 lg:mx-20">
              <h1 className=" text- text-4xl font-semibold max-w-[350px]">
                Your account is now activated!
              </h1>
              <p className=" mt-4 text-xs max-w-[350px]">
                You will be automatically re-directed to your Overview page in 5
                seconds.
              </p>
              <p className=" mt-4 text-xs max-w-[350px]">
                If you are unable to be re-directed, please click{" "}
                <LinkHighlight to="/overview" highlight>
                  here
                </LinkHighlight>{" "}
                to go to your Overview page.
              </p>
            </div>
          </section>
        )}
      </form>
    </div>
  );
}
