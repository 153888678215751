import React, { useContext } from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import AuthContext from "../Authentication/auth-context";
import Logo from "../images/brand-logo-black.svg";
import Hamburger from "../images/Hamburger.svg";
import LinkHighlight from "./LinkHighlight";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import Menu from "./Menu";
import PushNotification from "./PushNotification";
import io from "socket.io-client";

const socket = io.connect("https://push.brandfiliate.com/", {
  reconnect: true,
});

export default function Header({ token }) {
  const [showMenu, setShowMenu] = useState(false);
  const [showHamburger, setShowHamburger] = useState(false);
  const [notification, setNotification] = useState();
  const { pathname } = useLocation();

  const showHeader =
    pathname !== "/sign-in" &&
    pathname !== "/login" &&
    pathname !== "/forgot-email" &&
    pathname !== "/forgot-phone" &&
    pathname !== "/unaffiliate-page" &&
    !pathname.includes("/reset-password");
  const [affiliate, setAffiliate] = useState({});

  useEffect(() => {
    socket.on("connect", () => {
      console.log("true");
    });

    socket.on("disconnect", () => { });

    socket.on("broadcastMessage", (msg) => {
      console.log(msg);
      setNotification(msg);
      setTimeout(() => {
        setNotification("");
      }, 5000);
    });

    return () => {
      // socket.off("connect");
      // socket.off("disconnect");
      // socket.off("pong");
    };
  }, []);

  const closeNotification = () => {
    setNotification("");
  };

  const authCtx = useContext(AuthContext);
  const menuRef = useRef();
  let navigate = useNavigate();
  const ref = useRef(null);

  //* Close drop down menu when user clicks outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShowHamburger(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [showHamburger]);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}affiliateBasicInfo`,

        {},
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        console.log(res);
        setAffiliate(res.data.response);
      });
  }, [token]);

  const { firstName, lastName } = affiliate || {};

  useEffect(() => {
    const closeDropDown = (e) => {
      console.log(e);
      if (
        e.target.id !== "paragraph" &&
        e.target.id !== "arrow" &&
        showMenu === true
      ) {
        setShowMenu(false);
      }
    };
    document.body.addEventListener("click", closeDropDown);
    return () => document.body.removeEventListener("click", closeDropDown);
  }, [showMenu]);

  const handleLogout = () => {
    authCtx.logout();
    navigate("/login");
    window.location.reload();
  };
  const user = JSON.parse(localStorage.getItem("user"));
  console.log(affiliate);
  let { affiliateId: notificationId } = notification || {};
  const { affiliateId } = affiliate || {};
  console.log(typeof notificationId, affiliateId);

  return (
    <>
      {(Number(notificationId) === affiliateId || notificationId === "all") && (
        <div className="flex justify-center md:justify-end md:pr-4 ">
          <PushNotification
            notification={notification}
            close={closeNotification}
            id={affiliate && affiliate.id}
          />
        </div>
      )}
      {showHeader && (
        <header className="relative z-10 mb-[2px] flex items-center justify-between bg-bafWhite px-4 py-4 drop-shadow-lg md:px-10">
          <div className="flex items-center">
            <img
              src={Hamburger}
              alt="hamburger-menu"
              className=" md:hidden mr-4"
              ref={ref}
              onClick={() => setShowHamburger(!showHamburger)}
            />
            <img
              src={Logo}
              alt="logo"
              className="cursor-pointer h-10 md:h-14"
              onClick={() => navigate("/overview")}
            />
          </div>
          <nav className="flex items-center max-w-[800px] xl:max-w-[850px] lg:mr-10 xl:mr-24  justify-evenly md:w-[70%] xxl:mr-36 ">
            <div className="hidden w-3/4 items-center justify-evenly md:flex font-bold text-xl">
              <LinkHighlight
                external
                to="https://brandaf.com/how-it-works"
                title="How it Works"
              >
                How it Works
              </LinkHighlight>

              <LinkHighlight
                external
                title="About Us"
                to="https://brandaf.com/about"
              >
                About Us
              </LinkHighlight>
              <LinkHighlight
                external
                to="https://brandaf.com/support"
                title="Support"
              >
                Support
              </LinkHighlight>
              <LinkHighlight
                external
                to="https://brandaf.com/blog"
                title="Blog"
              ></LinkHighlight>
            </div>
            <div
              className="flex items-center"
              onClick={() => setShowMenu(!showMenu)}
            >
              <p
                id="paragraph"
                className=" mr-3  min-w-fit flex text-xs font-bold md:text-sm md:font-semibold md:mt-[2px] lg:font-bold cursor-pointer"
                onClick={() => setShowMenu(!showMenu)}
                ref={menuRef}
              >
                Hey {firstName}
                {"!"}
              </p>
              <IoIosArrowDown
                className="text-2xl hover:cursor-pointer text-darkGreen font-black ml-[-8px] md:mt-[2px] lg:mt-1"
                onClick={() => setShowMenu(!showMenu)}
                ref={menuRef}
                id="arrow"
              />
              {/* <img src={DownArrow} alt="down arrow" /> */}
            </div>
            {showMenu && (
              <div className="absolute overflow-hidden top-16 z-10 flex min-w-[170px] flex-col rounded-md bg-bafWhite shadow-[0px_4px_4px_rgba(0,0,0,0.1),5px_-1px_10px_#F3F3F3] md:right-5 hover:bg-sky-700 lg:right-16 xl:right-36">
                <div className="border-transparent border-l-4 hover:bg-lightGreen hover:border-darkGreen px-2">
                  <Menu to="profile" title="My Profile"></Menu>
                </div>
                <div className="border-transparent border-l-4 hover:bg-lightGreen hover:border-darkGreen px-2">
                  <Menu to="brands" title="Explore Brands"></Menu>
                </div>
                <div className="border-transparent border-l-4 hover:bg-lightGreen hover:border-darkGreen px-2">
                  <Menu to={"top-affiliates"} title="Top Performers"></Menu>
                </div>
                {user && user.admin && (
                  <div className="border-transparent border-l-4 hover:bg-lightGreen hover:border-darkGreen px-2">
                    <Menu to="brand-admin" title="Settings"></Menu>
                  </div>
                )}
                <div className="border-transparent border-l-4 hover:bg-lightGreen hover:border-darkGreen px-2">
                  <Menu
                    external
                    to={"https://brandaf.com/support"}
                    title="FAQ"
                  ></Menu>
                </div>
                <div className="border-transparent border-l-4 hover:bg-lightGreen hover:border-darkGreen px-2">
                  <p
                    className=" hover:cursor-pointer pl-1 py-[14px] text-[12px] md:text-[14px] lg:text-[16px]"
                    onClick={handleLogout}
                  >
                    Logout
                  </p>
                </div>
              </div>
            )}
          </nav>
          {showHamburger && (
            <div className="absolute top-16 z-10 pt-2 flex w-full flex-col rounded-md bg-bafWhite shadow-lg -ml-4">
              <div className="hover:bg-lightGreen hover:border-l-4 hover:border-darkGreen px-2 py-3 text-center font-semibold text-sm">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://brandaf.com/how-it-works"
                >
                  {" "}
                  How it Works{" "}
                </a>
              </div>
              <div className="hover:bg-lightGreen hover:border-l-4 hover:border-darkGreen px-2 py-3 text-center font-semibold text-sm">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://brandaf.com/about"
                >
                  {" "}
                  About Us{" "}
                </a>
              </div>
              <div className="hover:bg-lightGreen hover:border-l-4 hover:border-darkGreen px-2 py-3 text-center font-semibold text-sm">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://brandaf.com/support"
                >
                  {" "}
                  Support{" "}
                </a>
              </div>
              <div className="hover:bg-lightGreen hover:border-l-4 hover:border-darkGreen px-2 py-3 text-center font-semibold text-sm">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://brandaf.com/blog"
                >
                  {" "}
                  Blog{" "}
                </a>
              </div>
            </div>
          )}
        </header>
      )}
    </>
  );
}
