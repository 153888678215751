import React from "react";

import StatsCard from "../Cards/StatsCard";
import CashTransactionHistoryCard from "../Cards/CashTransactionHistoryCard";
import PointsTransactionHistoryCard from "../Cards/PointsTransactionHistoryCard";
import GeneratedleadsTableFilter from "../Shared/GeneratedLeadsTableFilter";
import LayoutButton from "../Shared/LayoutButtons";
import AffiliateActivityChart from "../Charts/AffiliateActivityChart";
import ActivityChart from "../Charts/ActivityChart";
import SingleCampaignPerformanceChart from "../Charts/SingleCampaignPerformanceChart";
import BrandTable from "../Tables/BrandTable";
import WalletCashBalanceCard from "../Cards/WalletCashBalanceCard";
import PointsBalanceProgressCard from "../Cards/PointsBalanceProgressCard";
import ReferralCodeCard from "../Cards/ReferralCodeCard";
import BrandCard from "../Cards/BrandCard";

export default function Test() {
  return (
    <div className="h-[100vh] flex flex-wrap gap-4">
      <GeneratedleadsTableFilter />
      <div className="w-full">
        <LayoutButton />
      </div>
      <StatsCard />
      <CashTransactionHistoryCard />
      <PointsTransactionHistoryCard />
      <AffiliateActivityChart />
      <ActivityChart />
      <SingleCampaignPerformanceChart />
      <BrandTable />
      <WalletCashBalanceCard />
      <PointsBalanceProgressCard />
      <ReferralCodeCard />
      <BrandCard />
    </div>
  );
}
