import React, { useState, useRef, useEffect } from "react";
import HollowButton from "../Shared/HollowButton";
// import CSVToArray from "csv-to-array-browser";
import DragDropFile from "../Shared/DragDropFile";
import Throw from "../images/image.png";
import Pepe from "../images/pepe.png";
import PepeMad from "../images/pepe mad.png";
import axios from "axios";
import Todo from "../images/todo.png";
import Mmm from "../images/mmm.png";
import Crying from "../images/crying.png";
import PepeAngry from "../images/pepe angry.jpeg";
import Pepee from "../images/pepee.png";
import { ReactComponent as CloseIcon } from "../images/modal-close-button-white.svg";

// import { ReactComponent as ImportIcon } from "../images/import-icon.svg";
// import { isCompositeComponent } from "react-dom/test-utils";
const ModalCrm = ({ token, showModal, handleModal, profileImage }) => {
  const [page, setPage] = useState(1);
  // const [emailId, setEmailId] = useState([]);
  // const [fileName, setFileName] = useState("");
  // const [error, setError] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [campaignPreview, setCampaignPreview] = useState();
  const [campaignError, setCampaignError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  // const [imageSelected, setImageSelected] = useState("");

  const images = [Pepe, Pepee, PepeAngry, PepeMad, Todo, Mmm, Crying, Throw];

  // const userInfo = localStorage.getItem("user");
  // let userObj = {};
  // if (userInfo) {
  //   userObj = JSON.parse(userInfo);
  // }
  const ref = useRef();

  useOnClickOutside(ref, () => handleModal());

  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  }

  useEffect(() => {
    if (!selectedCampaign) {
      // setCampaignPreview(undefined);
      return;
    }
    const campaignObjectUrl = URL.createObjectURL(selectedCampaign);
    setCampaignPreview(campaignObjectUrl);

    return () => {
      URL.revokeObjectURL(campaignObjectUrl);
    };
  }, [selectedCampaign]);

  function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  const saveProfilePicture = async () => {
    const formData = new FormData();
    if (selectedCampaign) {
      formData.append("userPhoto", selectedCampaign);
      console.log(selectedCampaign);
    } else {
      const imageFile = await urltoFile(campaignPreview, "file");
      formData.append("userPhoto", imageFile);
    }
    axios
      .post(`${process.env.REACT_APP_PROD_URL}updateProfilePic`, formData, {
        headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
      })
      .then((res) => {
        console.log(res);
        if (res.data.responseCode === 0) {
          setSuccessMessage("Your profile picture has been updated");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      });
  };

  const selectImage = (imageLink) => {
    console.log(imageLink);
    setCampaignPreview(imageLink);
    setSelectedCampaign("");
  };

  const resetModal = () => {
    handleModal();
    setPage(1);
    const body = document.body;

    body.classList.remove("modal-open");

    setPage(1);
    setSelectedCampaign("");
    setCampaignPreview("");
  };

  const handleCampaignFile = (file) => {
    console.log(file);

    if (
      !file.name.includes(".jpeg") &&
      !file.name.includes(".jpg") &&
      !file.name.includes(".png")
    ) {
      setCampaignError("File must be a jpeg, jpg, or png");
      return;
    } else {
      setCampaignError("");
      setSelectedCampaign(file);
    }
  };

  useEffect(() => {
    const body = document.body;
    body.classList.add("modal-open");

    return () => {};
  }, []);

  return (
    <>
      {/* <button
        className=" mt-6 mr-1 mb-1
      flex items-center gap-2 rounded-full border border-bafBlack px-6 py-2 font-bold text-black shadow outline-none focus:outline-none"
        type="button"
        onClick={() => handleModal(true)}
      >
        <ImportIcon />
        Import CRM Data
      </button> */}
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-bafGrey bg-opacity-80 outline-none focus:outline-none">
            <div
              ref={ref}
              className="crm-modal relative mx-auto my-6 w-[70%] min-w-[370px] max-h-[90vh] overflow-scroll scrollbar-hide max-w-[750px] rounded-2xl bg-bafBlack py-6 md:my-10 md:py-10 lg:w-[60%]"
            >
              <button
                className="absolute border-0 bg-transparent text-black top-4 right-4"
                onClick={resetModal}
              >
                <CloseIcon className="w-5 h-5" />
              </button>
              {page === 1 && (
                <div className=" relative flex w-full flex-col rounded-lg border-0 px-4  md:px-20">
                  <div className="flex flex-col justify-center py-5">
                    <h2 className="text-center text-3xl font-semibold text-bafWhite md:mb-10 md:text-4xl">
                      Change Profile Picture
                    </h2>
                    <p className="my-6 text-center max-w-[400px] mx-auto font-semibold text-bafWhite text-xl">
                      You can choose one of our many avatars, or upload your own
                      photo!
                    </p>
                  </div>
                  <div className="grid grid-cols-4 gap-4 place-items-center max-w-[420px] mx-auto">
                    {images.map((image) => (
                      <img
                        onClick={() => selectImage(image)}
                        className="h-16 w-16 border border-bafWhite rounded-full"
                        src={image}
                        alt="avatar"
                      ></img>
                    ))}
                  </div>
                  <div className="mt-12">
                    <p className="text-center text-bafWhite text-xl mb-8">
                      Upload your photo here:
                    </p>
                    <DragDropFile
                      title="Drag or upload"
                      handleFile={(file) => handleCampaignFile(file)}
                      acceptedFileType=".jpg,.jpeg,.png"
                      fileName={campaignError}
                      buttonTitle="Upload"
                      small
                    />
                  </div>
                  <div>
                    <p className="text-bafWhite mb-4  mt-12 text-center font-semibold text-xl">
                      Preview
                    </p>

                    <img
                      src={campaignPreview ? campaignPreview : profileImage}
                      alt="preview"
                      className="h-40 w-40 rounded-full mx-auto border border-bafWhite"
                    />
                    <p className="text-xs text-bafWhite my-4 text-center">
                      {successMessage}
                    </p>
                  </div>
                  <div className="flex flex-col  mt-12  mb-6 items-center justify-evenly gap-6  p-6 md:flex-row">
                    <HollowButton
                      title="Cancel"
                      color="bafWhite"
                      type="button"
                      border="bafWhite"
                      backgroundHoverWhite
                      medium
                      className="w-[150px]"
                      handleClick={resetModal}
                    />
                    <HollowButton
                      title="Save"
                      background="bafWhite"
                      backgroundHoverPurple
                      medium
                      handleClick={saveProfilePicture}
                      className="w-[150px]"
                      disabled={!campaignPreview}
                    />
                  </div>
                </div>
              )}

              {page === 2 && (
                <div className=" relative flex w-full flex-col rounded-lg border-0 px-4 shadow-lg outline-none focus:outline-none md:px-20">
                  <div className="flex flex-col justify-center py-5">
                    <h2 className="text-center text-3xl font-semibold text-bafWhite md:mb-10">
                      CRM Data Imported!
                    </h2>
                    <p className="mt-2 text-center font-semibold text-bafWhite md:text-left">
                      Your CSV has been successfully uploaded.
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-6 rounded-b p-6 md:flex-row">
                    <HollowButton
                      title="Awesome"
                      background="bafWhite"
                      backgroundHoverPurple
                      handleClick={resetModal}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ModalCrm;
