import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";

export default function useCityValues(id) {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_PROD_URL}getCities/stateId/${id}`)
      .then((res) => setCities(res.data.response));
  }, [id]);
  return cities;
}
