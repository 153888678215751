import React from "react";
import { formatDate, slashedDate, formatTime } from "../helpers/helpers";

export default function CashTransactionHistoryCard({ transaction }) {
  const {
    payee,
    transactionId,
    transactionTime,
    transactionStatus,
    campaignId,
    campaignName,
    amount,
    campaignStartDate,
    campaignEndDate,
  } = transaction;

  return (
    <div className="CashTransactionHistoryCard shadow-[0px_4px_4px_rgba(0,0,0,0.1),5px_-1px_10px_#F3F3F3] rounded-[10px] md:flex md:h-[286px] md:max-w-[975px] md:w-full justify-between items-center px-11 py-8 bg-bafWhite">
      <div className="flex flex-col gap-6 mb-6 md:mb-0 md:gap-0 md:justify-between md:h-full">
        <div className="AmountWrapper">
          <p className="text-sm font-semibold mb-4">Amount</p>
          <p className="text-[2.5rem] leading-10 font-semibold truncate">
            {`$${amount?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })} USD`}
          </p>
        </div>
        <div className="PayeeWrapper mt-4">
          <p className="text-sm font-semibold">Payee</p>
          <p
            href="#"
            className="text-sm max-w-fit shadow-[inset_0_-0.6em_#B5FFB8]"
          >
            {`${payee}`}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-6 md:w-[50%] md:h-full md:justify-between">
        {/* <div className="flex justify-between mt-4 md:mt-0"> */}
        <div className="grid grid-cols-2 gap-4 mt-4 md:mt-0">
          <div>
            <p className="text-sm font-semibold phone:hidden md:block tablet:hidden">
              Status
            </p>
            <p className="text-sm font-semibold hidden phone:block md:hidden tablet:block">
              Transaction Status
            </p>
            <p className="text-sm capitalize">{transactionStatus}</p>
          </div>

          <div>
            <p className="text-sm font-semibold phone:hidden md:block tablet:hidden">
              Time
            </p>
            <p className="text-sm font-semibold hidden phone:block md:hidden tablet:block">
              Transaction Time
            </p>
            <p className="text-sm truncate active:overflow-visible active:whitespace-normal">
              {`${slashedDate(transactionTime)} ${formatTime(transactionTime)}`}
            </p>
          </div>
        </div>

        <div className="">
          <p className="text-sm font-semibold">Transaction ID</p>
          <p className="text-sm">{transactionId}</p>
        </div>

        <div className="">
          <p className="text-sm font-semibold">Note:</p>
          <p className="text-sm">
            {`Payment for generating lead(s) for the “${campaignName}”
            campaign from ${formatDate(
              campaignStartDate,
              true
            )} to ${formatDate(campaignEndDate, true)}.`}
          </p>
        </div>
      </div>
    </div>
  );
}
