import React, { useState } from "react";
import { Link } from "react-router-dom";
import Transaction from "./Transaction";
import TransactionHistoryFilter from "./TransactionHistoryFilter";

export default function TransactionHistory({
  transactions,
  transactionType,
  setTransactionType,
}) {
  return (
    <div className="TransactionHistory Card bg-bafWhite w-full h-[237px] py-5 px-5 overflow-y-auto flex flex-col lg:max-w-[639px]">
      <header className="flex justify-between items-center">
        <TransactionHistoryFilter
          transactionType={transactionType}
          setTransactionType={setTransactionType}
        />
        <Link to="/wallet" className="text-sm font-bold">
          View All
        </Link>
      </header>
      <main className="overflow-y-scroll overflow-x-hidden mt-[14px] mr-[-1rem] pr-[12px] md:pr-[46px]">
        {transactions && transactions.length > 0 ? (
          transactions.map((transaction) => {
            return (
              <Transaction
                transactionType={transactionType}
                transaction={transaction}
                key={transaction.campaignId}
              />
            );
          })
        ) : (
          <p className="text-coolGrey font-bold mt-12">
            No transactions found.
          </p>
        )}
      </main>
    </div>
  );
}
