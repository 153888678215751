import React, { useState, useEffect } from "react";
import axios from "axios";

import Container from "./Container";
import Avatar from "../images/astronaut-avatar.png";
import HollowButton from "./HollowButton";
import JoinBrandModal from "../Modals/JoinBrandModal";
import LeaveBrandModal from "../Modals/LeaveBrandModal";

export default function BrandNavigation({ brand, token, id }) {
  const [showModal, setShowModal] = useState(false);
  const [brandData, setBrandData] = useState([]);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}brandInfo`,
        { dealerId: id },
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        setBrandData(res.data.response);
      });
  }, [token, id]);

  const followBrand = () => {
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}followBrand`,
        { dealerId: dealerId },
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        console.log(res);

        if (res.data.responseCode === 0) {
          // alert(`${res.data.successMsg}`);
          handleModal();
          // window.location.reload();
        } else {
          alert(`Error. ${res.data.errorMsg}`);
          window.location.reload();
        }
      });
  };

  const {
    dealerId,
    countryName,
    stateName,
    companyName,
    companyLogoUrl,
    industryName,
    affiliateFollowingBrand,
    totalCampaigns,
    totalAffiliates,
  } = brandData || {};

  const handleModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <>
      {affiliateFollowingBrand === 1 ? (
        <LeaveBrandModal
          dealerId={dealerId}
          showModal={showModal}
          handleModal={handleModal}
          token={token}
          companyName={companyName}
        />
      ) : (
        <JoinBrandModal
          dealerId={dealerId}
          showModal={showModal}
          handleModal={handleModal}
          token={token}
          companyName={companyName}
        />
      )}
      <header className="BrandNavigation bg-bafWhite flex items-center justify-center h-[288px] md:h-[246px] md:py-6">
        <Container>
          <div className="flex flex-col justify-between items-center gap-7 md:items-start md:flex-row md:min-w-[450px]">
            <div className="flex items-center gap-2 md:gap-12 truncate w-full md:w-auto">
              <div className="h-[80px] w-[80px] md:h-[105px] md:w-[105px]">
                <img
                  src={companyLogoUrl || Avatar}
                  alt="avatar"
                  className="w-full h-full object-cover object-center rounded-[50%] border border-coolGrey"
                />
              </div>
              <div className="truncate">
                <h1 className="mb-2 min-w-fit max-w-fit font-semibold shadow-[inset_0_-0.6em_#B5FFB8] text-2xl xs:text-[32px] xs:shadow-[inset_0_-0.4em_#B5FFB8]">
                  {companyName}
                </h1>
                <p className="mt-1 text-xs text-coolGrey xs:text-sm truncate">
                  <span className="text-darkGreen">{`${industryName} `}</span>
                  &#8226;
                  <span className="text-darkGray">{` ${stateName}, ${countryName}`}</span>
                </p>
                <p className="mt-3 font-normal text-xs text-darkGray xs:text-sm truncate">
                  <span className="text-bafBlack font-bold">{`${totalCampaigns} `}</span>
                  Campaigns &#8226;
                  <span className="text-bafBlack font-bold">{` ${totalAffiliates} `}</span>
                  <span className="text-darkGray font-normal">Affiliates</span>
                </p>
              </div>
            </div>
            {affiliateFollowingBrand === 1 ? (
              <HollowButton
                title="Leave"
                fullWidth
                handleClick={handleModal}
                backgroundHoverBlack
                className="rounded-full h-[56px] lg:w-[300px] lg:max-w-[300px]"
              />
            ) : (
              <HollowButton
                title="Join brand"
                fullWidth
                background="bafBlack"
                color="bafWhite"
                backgroundHover
                handleClick={() => followBrand()}
                // handleClick={handleModal}
                className="rounded-full h-[56px] lg:w-[300px] lg:max-w-[300px]"
              />
            )}
          </div>
        </Container>
      </header>
    </>
  );
}
