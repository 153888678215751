import axios from "axios";
import React, { useState } from "react";
import { AiFillBook } from "react-icons/ai";
import Moment from "react-moment";
import ViewMessageModal from "../Modals/ViewMessageModal";
import HollowButton from "./HollowButton";
import bookmarkedIcon from "../images/bookmarked.svg";
import notBookmarked from "../images/notbookmarked.svg";
import Tooltip from "react-simple-tooltip";

export default function FullNotification({ notification, token, refresh }) {
  const {
    createTs,
    fromUserImage,
    // fromUserName,
    message,
    notiId,
    readFlag,
    firstClickUrl,
    bookmarked,
  } = notification || {};
  const [showModal, setShowModal] = useState(false);
  // console.log(message)
  const firstSearch = message && message.indexOf('"');

  const handleModal = () => {
    markAsRead();
    setShowModal((prev) => !prev);
  };

  function getPosition(string, subString, index) {
    return string && string.split(subString, index).join(subString).length;
  }
  const secondSearch = getPosition(message, '"', 2);
  const campaignTitle = message && message.slice(firstSearch + 1, secondSearch);
  // const firstText = message && message.slice(0, firstSearch);
  // const secondText = message && message.slice(secondSearch + 1);
  // console.log(notiId, bookmarked, readFlag)
  const markAsRead = () => {
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}markNotificationViewed`,
        {
          notiId: notiId,
        },
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        console.log(res);
        refresh();
      });
  };

  const bookmarkNotification = () => {
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}bookMarkNotification`,
        {
          notiId: notiId,
        },
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        console.log(res);
        refresh();
      });
  };

  const unbookmarkNotification = () => {
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}unBookMarkNotification`,
        {
          notiId: notiId,
        },
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        console.log(res);
        refresh();
      });
  };

  const deleteNotification = () => {
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}deleteNotification`,
        {
          notiId: notiId,
        },
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        console.log(res);
        refresh();
      });
  };
  console.log(readFlag);
  return (
    <>
      {showModal && (
        <ViewMessageModal
          showModal={showModal}
          handleModal={handleModal}
          date={createTs}
          image={fromUserImage}
          localLink={firstClickUrl}
          campaignName={campaignTitle}
          message={message}
        />
      )}

      <article
        className={`Card  relative my-3   ${
          !readFlag && "bg-lightMint"
        } flex justify-evenly p-4 py-6 sm:max-w-none md:items-center}`}
      >
        <div className="flex md:w-[150px] lg:w-[180px]">
          {bookmarked ? (
            <img
              src={bookmarkedIcon}
              alt="bookmark"
              className="mr-4 h-10 mt-4   hover:cursor-pointer sm:mr-6"
              onClick={unbookmarkNotification}
            />
          ) : (
            <img
              src={notBookmarked}
              alt="bookmark"
              className="mr-4 h-10 mt-4 hover:cursor-pointer sm:mr-6 "
              onClick={bookmarkNotification}
            />
          )}
          <img
            src={fromUserImage}
            alt="avatar"
            className="h-14 w-14 lg:h-16 lg:w-16 rounded-full"
          />
          {!readFlag ? (
            <span className="relative border-2 border-bafWhite left-[-10px] top-[-1px] inline-block h-4 w-4 rounded-full bg-darkGreen"></span>
          ) : (
            ""
          )}
        </div>
        <div className="ml-6 lg:ml-0 min-w-[200px] items-center sm:flex md:ml-0">
          <div className="ml-4 max-w-[400px] lg:mr-12 md:mt-4 md:min-w-[350px] lg:min-w-[400px] lg:max-w-[500px]">
            <p className="min-h-[40px] max-w-[140px] sm:min-w-[230px] text-xs line-clamp-3 sm:max-w-[300px] md:text-[14px] md:min-w-[400px] ">
              {message}
            </p>
            <p className="mt-4 mb-6 text-xs text-darkGray">
              <Moment interval={1000} fromNow>
                {createTs}
              </Moment>
            </p>
          </div>
          <div className="">
            <HollowButton
              title="View"
              small
              className="w-[150px]"
              handleClick={handleModal}
              backgroundHoverBlack
            />
          </div>
          <div className="absolute top-0 right-0">
            <Tooltip
              content="This notification will be deleted. "
              className="rounded-lg text-xs"
              background="#fff"
              color="black"
              radius={10}
              padding={12}
            >
              <button
                className="  block h-6 w-6 border-0 bg-transparent py-0 pr-2 text-2xl font-bold "
                onClick={deleteNotification}
              >
                x
              </button>
            </Tooltip>
          </div>
        </div>
      </article>
    </>
  );
}
