import React from "react";
import find from "lodash/find";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import {
  getDayOfWeek,
  abbreviateNumber,
  formatMonth,
} from "../helpers/helpers";

import { orderBy } from "lodash";
import moment from "moment/moment";

export default function ActivityChart({ timePeriod, graphData }) {
  const Period = (timePeriod) => {
    if (timePeriod === "today") {
      return "Today";
    } else if (timePeriod === "last7Days") {
      return "Last 7 Days";
    } else if (timePeriod === "lastMonth") {
      return "Last Month";
    } else if (timePeriod === "allTime") {
      return "All Time";
    }
  };

  const sortedChart = orderBy(graphData, ["date"], ["asc"]);

  //* Format Dates In Chart`
  let formattedYearChart = [];
  if (sortedChart) {
    formattedYearChart = sortedChart.map((chartItem) => {
      const month =
        moment(chartItem.day || chartItem.date)
          .utc()
          .format("M") - 1;
      const formattedMonth = formatMonth(month);
      const date = moment(chartItem.day || chartItem.date)
        .utc()
        .format("ddd MMM D YYYY, h:mm:ss a");
      const dayOfWeek = getDayOfWeek(date, true);
      const monthDay = moment(chartItem.day || chartItem.date)
        .utc()
        .format("DD");
      const formattedDate = moment(chartItem.day || chartItem.date)
        .utc()
        .format("MMM DD");
      const timeOfDay = moment(chartItem.day || chartItem.date)
        .utc()
        .format("hh A");

      if (timePeriod === "today") {
        return {
          name: timeOfDay,
          leads: chartItem.leadCount || chartItem.leads,
          shares: chartItem.sharedCount || chartItem.shares,
        };
      } else if (timePeriod === "last7Days") {
        return {
          name: dayOfWeek + monthDay,
          leads: chartItem.leadCount || chartItem.leads,
          shares: chartItem.sharedCount || chartItem.shares,
        };
      } else if (timePeriod === "lastMonth") {
        return {
          name: formattedDate?.replace(" ", ""),
          leads: chartItem.leadCount || chartItem.leads,
          shares: chartItem.sharedCount || chartItem.shares,
        };
      } else {
        return {
          name: formattedMonth,
          leads: chartItem.leadCount || chartItem.leads,
          shares: chartItem.sharedCount || chartItem.shares,
        };
      }
    });
  }

  //* Group The Data By Month - Incrementing If Month Is Already There
  const groupedChart = [];
  for (const item of formattedYearChart) {
    const found = find(groupedChart, {
      name: item.name,
    });
    if (!found) {
      groupedChart.push(item);
    } else {
      groupedChart[groupedChart.length - 1].leads += item.leads;
      groupedChart[groupedChart.length - 1].shareCount += item.shareCount;
    }
  }

  //* Change Tool Tip Box Content
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-bafWhite p-2 border-bafBlack border rounded-[1px]">
          {payload[0] && (
            <p className="font-bold text-[0.625rem] capitalize">{`${abbreviateNumber(
              payload[0].value
            )} ${payload[0].name}`}</p>
          )}
          {payload[1] && (
            <p className="font-bold text-[0.625rem] capitalize">{`${abbreviateNumber(
              payload[1].value
            )} ${payload[1].name}`}</p>
          )}
        </div>
      );
    }
    return null;
  };

  const customLegendTextStyling = (value, entry, index) => {
    return (
      <span className="text-bafBlack text-[0.75rem] capitalize">{value}</span>
    );
  };

  const customLegendWrapperStyle = () => {
    if (window.innerWidth < 768) {
      return {
        paddingTop: "30px",
        marginRight: "-20px",
      };
    } else {
      return {
        paddingTop: "30px",
        marginRight: "-20px",
      };
    }
  };

  //* Change X Axis Ticks
  const CustomizedXAxisTick = ({ x, y, stroke, payload }) => {
    let dayOfWeek = payload.value?.slice(0, 3);
    let date = payload.value?.substring(3);

    let hour = payload.value?.slice(0, 2);
    let amPM = payload.value?.substring(3);

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={5} className="">
          <tspan
            textAnchor="middle"
            x="0"
            className="text-[0.625rem] font-bold"
          >
            {timePeriod === "today" ? hour : dayOfWeek}
          </tspan>
          <tspan textAnchor="middle" x="0" dy="15" className="text-[0.625rem]">
            {timePeriod === "today" ? amPM : date}
          </tspan>
        </text>
      </g>
    );
  };

  //* Format Y Axis Ticks
  const YAxisFormatter = (number) => {
    if (number > 999) {
      return number / 1000 + "K";
    } else {
      return number;
    }
  };

  return (
    <div className="AffiliateActivityChart rounded-[10px] shadow-[0px_4px_4px_rgba(0,0,0,0.1),5px_-1px_10px_#F3F3F3] bg-bafWhite p-2 h-[309px] md:h-[316px] md:max-w-[478px]">
      <p className="text-[0.75rem] ml-7 my-2">
        My Performance in This Campaign
      </p>
      {graphData.length > 0 ? (
        <ResponsiveContainer height="88%">
          <LineChart
            width={500}
            height={300}
            data={groupedChart}
            margin={{
              top: 20,
              right: 20,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey={"name"}
              tick={CustomizedXAxisTick}
              axisLine={{ stroke: "black" }}
              tickLine={false}
              tickMargin={10}
              interval={0}
              style={{ fill: "black", fontSize: "10px" }}
              label={{ value: `${Period(timePeriod)}`, fontSize: 10, dy: 40 }}
            />
            <YAxis
              tickMargin={10}
              axisLine={{ stroke: "black" }}
              style={{ fill: "black", fontSize: "10px" }}
              tickLine={{ stroke: "#A8A8A8" }}
              label={{
                value: "Amount",
                angle: -90,
                dx: -15,
                fontSize: 10,
              }}
              tickFormatter={YAxisFormatter}
            />
            <Tooltip
              cursor={{
                stroke: "black",
                strokeDasharray: "5 5",
              }}
              itemStyle={{ color: "black" }}
              content={CustomTooltip}
              wrapperStyle={{ outline: "none" }}
              animationDuration={300}
            />
            <Legend
              iconType="circle"
              align="right"
              wrapperStyle={customLegendWrapperStyle()}
              formatter={customLegendTextStyling}
            />
            <Line
              key={Math.random()}
              type="monotone"
              dataKey="shares"
              stroke="#16DB93"
              dot={{ fill: "#16DB93", r: 2 }}
              activeDot={{
                stroke: "black",
                fill: "white",
                r: 2,
                strokeWidth: 1,
              }}
            />
            <Line
              key={Math.random()}
              type="monotone"
              dataKey="leads"
              stroke="#8884d8"
              dot={{ fill: "#8884d8", r: 2 }}
              activeDot={{
                stroke: "black",
                fill: "white",
                r: 2,
                strokeWidth: 1,
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <div className="h-[240px] flex items-center justify-center">
          <p>No data to display.</p>
        </div>
      )}
    </div>
  );
}
