import React, { useState } from "react";
import SmallDownArrow from "../images/small-down-arrow.svg";
import { slashedDate } from "../helpers/helpers";
import HollowButton from "../Shared/HollowButton";
import { useNavigate } from "react-router-dom";
import LeaveBrandModal from "../Modals/LeaveBrandModal";
import JoinBrandModal from "../Modals/JoinBrandModal";

export default function BrandRow({
  id,
  handleSelect,
  selected,
  brands,
  brandType,
  token,
  index,
  currentPage,
  brandFilter,
}) {
  const {
    dealerId,
    companyName,
    companyLogoUrl,
    joinedDate,
    totalCampaigns,
    totalAffiliates,
  } = brands || {};

  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);
  const [showLeaveBrandModal, setShowLeaveBrandModal] = useState(false);
  const [showJoinBrandModal, setShowJoinBrandModal] = useState(false);

  const handleSelected = (id) => {
    handleSelect(id);
  };

  const formattedTimeStamp = (timeStamp) => {
    const date = slashedDate(timeStamp);
    return `${date}`;
  };

  const handleViewBrand = () => {
    navigate(`/company-profile/${dealerId}`);
  };

  const handleLeaveBrandModal = () => {
    setShowLeaveBrandModal((prev) => !prev);
  };

  const handleJoinBrandModal = () => {
    setShowJoinBrandModal((prev) => !prev);
  };

  const getDisplayIndex = (currentPage) => {
    const displayIndex = {
      0: index + 1,
      1: index + 7,
      2: index + 13,
      3: index + 19,
      4: index + 25,
      5: index + 31,
      6: index + 37,
    };
    return displayIndex[currentPage];
  };

  return (
    <>
      {showJoinBrandModal && (
        <JoinBrandModal
          showModal={showJoinBrandModal}
          handleModal={handleJoinBrandModal}
          token={token}
          dealerId={dealerId}
          companyName={companyName}
        />
      )}
      {showLeaveBrandModal && (
        <LeaveBrandModal
          showModal={showLeaveBrandModal}
          handleModal={handleLeaveBrandModal}
          token={token}
          dealerId={dealerId}
          companyName={companyName}
        />
      )}
      <tbody
        className="BrandTableRow w-full odd:bg-[#B5FFB8]/30 last:rounded-b-md"
        onClick={() => handleSelected(id)}
      >
        <tr
          className={`${
            selected && "bg-darkPurple"
          } w-full grid hover:bg-mint ${
            brandFilter === "topPerforming" ? "gap-4" : "gap-6"
          } items-center p-4 md:px-6 md:gap-4 
          ${
            brandFilter === "topPerforming"
              ? "grid-cols-[0.5fr_3.5fr_1.25fr_1.25fr_0.5fr] md:grid md:grid-cols-[0.60fr_3fr_1.5fr_1.5fr_1.67fr_3.33fr]"
              : "grid-cols-[3fr_1fr_1fr_0.5fr] md:grid md:grid-cols-[3.33fr_1.67fr_1.67fr_1.67fr_3.33fr]"
          }
          `}
        >
          {brandFilter === "topPerforming" && (
            <td className={`text-sm`}>{`#${getDisplayIndex(currentPage)}`}</td>
          )}
          <td className={`flex items-center text-sm w-[95%] md:w-auto`}>
            <img
              src={companyLogoUrl}
              alt="avatar"
              className="h-9 w-9 rounded-[50%] mr-2 bg-bafWhite"
            />
            <p className="font-bold truncate">{companyName}</p>
          </td>
          <td className={`table-cell text-sm md:w-auto`}>
            <p className="">{totalAffiliates?.toLocaleString("en-US")}</p>
          </td>
          <td className={`table-cell text-sm md:w-auto`}>
            {totalCampaigns?.toLocaleString("en-US")}
          </td>
          <td className={`hidden md:table-cell text-sm md:w-auto`}>
            {formattedTimeStamp(joinedDate)}
          </td>
          {brandType && brandType === "exploreBrands" && (
            <td
              className={`hidden text-sm md:flex overflow-hidden items-center gap-4 justify-between`}
            >
              <HollowButton
                title="View"
                backgroundHoverBlack
                fullWidth
                small
                handleClick={handleViewBrand}
              />
              <HollowButton
                title="Join"
                color="bafWhite"
                background="bafBlack"
                backgroundHoverPurple
                fullWidth
                small
                handleClick={handleJoinBrandModal}
              />
            </td>
          )}
          {brandType && brandType === "affiliatedBrands" && (
            <td
              className={`hidden text-sm md:flex overflow-hidden items-center gap-4 justify-between`}
            >
              <HollowButton
                title="Leave"
                backgroundHoverBlack
                fullWidth
                small
                handleClick={handleLeaveBrandModal}
              />
              <HollowButton
                title="View"
                color="bafWhite"
                background="bafBlack"
                backgroundHoverPurple
                fullWidth
                small
                handleClick={handleViewBrand}
              />
            </td>
          )}

          <td className="text-sm md:hidden w-[50%]">
            <img
              onClick={() => setExpanded((prev) => !prev)}
              className={`${expanded && "rotate-180"}`}
              src={SmallDownArrow}
              alt="arrow"
            />
          </td>
        </tr>
        {/* MOBILE */}
        {expanded && (
          <tr className="grid grid-cols-[25%_65%] gap-[10%] md:hidden px-4 pb-5 pt-2 mx-auto">
            <td>
              <p className="text-xs text-bafGrey font-extrabold mb-1 truncate">
                Joined Date
              </p>
              <p className="text-sm truncate">
                {formattedTimeStamp(joinedDate)}
              </p>
            </td>

            {brandType && brandType === "exploreBrands" && (
              <td className="flex items-center justify-between gap-4">
                <HollowButton
                  title="View"
                  backgroundHoverBlack
                  fullWidth
                  className="max-w-[116px] min-w-min h-[38px]"
                  handleClick={handleViewBrand}
                />
                <HollowButton
                  title="Join"
                  fullWidth
                  background="bafBlack"
                  color="bafWhite"
                  backgroundHoverPurple
                  className="max-w-[116px] min-w-min h-[38px]"
                  handleClick={handleJoinBrandModal}
                />
              </td>
            )}

            {brandType && brandType === "affiliatedBrands" && (
              <td className="flex items-center justify-between gap-4">
                <HollowButton
                  title="Leave"
                  backgroundHoverBlack
                  fullWidth
                  small
                  handleClick={handleLeaveBrandModal}
                />
                <HollowButton
                  title="View"
                  fullWidth
                  background="bafBlack"
                  color="bafWhite"
                  backgroundHoverPurple
                  small
                  handleClick={handleViewBrand}
                />
              </td>
            )}
          </tr>
        )}
      </tbody>
    </>
  );
}
