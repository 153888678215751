// import axios from "axios";
import React from "react";
import { useEffect } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
// import AuthContext from "../Authentication/auth-context";
import HollowButton from "../Shared/HollowButton";
import { ReactComponent as CloseIcon } from "../images/modal-close-button-white.svg";

export default function ViewMessageModal({
  showModal,
  handleModal,
  company,
  token,
  date,
  image,
  campaignName,
  localLink,
  message,
}) {
  // let navigate = useNavigate();
  function getPosition(string, subString, index) {
    if (!string) {
      return;
    }
    return string.split(subString, index).join(subString).length;
  }
  //* API Call To Get Performance Data
  useEffect(() => { }, [token]);
  const toggleModal = () => {
    handleModal();
    const body = document.body;
    body.classList.remove("modal-open");
  };

  useEffect(() => {
    const body = document.body;
    body.classList.add("modal-open");

    return () => { };
  }, []);

  const index = localLink && localLink.indexOf("s");
  const linkName = localLink && localLink.slice(index, localLink.length - 1);
  // console.log(linkName);
  const secondSearch = getPosition(localLink, "s", 2);
  const secondText = localLink && localLink.slice(secondSearch - 1);
  // console.log('message', message);
  // console.log("second", secondText);
  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-[#4b4b4b] bg-opacity-60 outline-none focus:outline-none ">
            <div className="relative  my-6 mx-auto min-h-[70%] w-[70%] min-w-[320px] max-w-[650px] rounded-2xl bg-bafBlack">
              <div className=" ld:px-42 relative z-0 flex w-full flex-col  border-0 px-8 outline-none focus:outline-none md:px-24 sm:px-14">
                <button
                  className="absolute top-3 right-0 float-right block h-6 w-6 border-0 bg-transparent py-0 pr-10 text-3xl font-bold text-bafWhite "
                  onClick={() => toggleModal()}
                >
                  <CloseIcon className="w-5 h-5" />
                </button>
                <header className="flex pt-24 pb-14 ">
                  <img
                    src={image}
                    alt="avatar"
                    className="mr-8 h-16 w-16 rounded-full"
                  />
                  <div>
                    <h3 className="pointer-events-none text-3xl font-semibold text-bafWhite">
                      Brandaf
                    </h3>
                    <p className="mt-2 text-sm text-bafGrey">
                      {" "}
                      <Moment interval={linkName} fromNow>
                        {date}
                      </Moment>
                    </p>
                  </div>
                </header>
                {localLink && (
                  <main className=" mb-12">
                    <p className="md:text-md mb-4 text-sm text-bafWhite">
                      Brandaf has finished designing the campaign landing for{" "}
                      <span className="font-bold"> "{campaignName}" </span> page
                      here.
                    </p>
                    <p className="md:text-md mb-4 text-sm text-bafWhite">
                      Please review the contents before approving for your
                      campaign to go live at its scheduled date and time.
                    </p>
                    <p className="md:text-md mb-4 text-sm text-bafWhite">
                      You can review the campaign post’s contents{" "}
                      {secondText ? (
                        <Link
                          to={`${secondText}`}
                          className="shadow-[inset_0_-0.4em_#A590E3]"
                        >
                          here
                        </Link>
                      ) : (
                        <span> here </span>
                      )}
                      &nbsp;for comparison.
                    </p>
                    <p className="md:text-md mb-4 text-sm text-bafWhite">
                      For changes and requests, e-mail <span className="font-bold">info@brandaffiliate.com </span>
                      with  <span className="font-bold">#MB9384</span> in the subject line.
                    </p>
                  </main>
                )}

                {!localLink && (
                  <p className="md:text-md mx-auto  mt-10 mb-3 max-w-[300px] text-sm text-bafWhite">
                    {message}
                  </p>
                )}

                <footer className="z-10 mt-[5%] mb-4 flex items-center justify-evenly p-6">
                  <HollowButton
                    title={localLink ? "Approve" : "Close"}
                    color="bafWhite"
                    border="bafWhite"
                    type="button"
                    backgroundHoverWhite
                    medium
                    handleClick={() => toggleModal()}
                  />
                </footer>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
