import axios from "axios";
import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Container from "../Shared/Container";
import HollowButton from "../Shared/HollowButton";
import SignUpInput from "../Shared/SignUpInput";
import astronaut from "../images/login-astronaut.gif";
import SignUpBackground from "./SignUpBackground";

export default function ForgotEmail() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    document.title = "Brandaf - Affiliate Login";
  }, []);
  const login = `${process.env.REACT_APP_PROD_URL}forgotPassword`;

  const formik = useFormik({
    initialValues: {
      emailId: "",
    },
    onSubmit: (values, { resetForm }) => {
      axios
        .post(login, values, {
          headers: { "Access-Control-Allow-Origin": "*" },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.responseCode === 0) {
            setSuccess(res.data.successMsg);
            setPage((page) => page + 1);
          } else {
            setError(res.data.errorMsg);
          }
        });
    },
  });

  const nextPage = () => {
    setPage((prevPage) => (prevPage += 1));
    window.scrollTo(0, 0);
  };

  const prevPage = () => {
    setPage((prevPage) => (prevPage -= 1));
    window.scrollTo(0, 0);
  };

  return (
    <div className=" flex min-h-screen overflow-y-hidden flex-col  bg-[url('/src/images/login-background.gif')] bg-bafBlack md:flex-row  ">
      <SignUpBackground />
      {page === 1 && (
        <form
          className={
            "flex min-h-[470px] sm:mt-8 sm:min-h-[600px] md:rounded-none  rounded-md bg-bafWhite md:h-screen md:mt-0 md:w-1/2 overflow-y-scroll"
          }
          onSubmit={formik.handleSubmit}
        >
          <Container>
            <div className=" md:mt-[180px] md:px-8  lg:px-12  xl:pr-36 max-w-[700px]">
              <h1 className="mt-8 text-3xl font-semibold">
                Enter E-mail Address
              </h1>
              <p className="mt-4 text-sm">
                Don't have an account?
                <Link
                  to="/sign-in"
                  className="ml-1 shadow-[inset_0_-0.4em_#BFFFBC]"
                >
                  Sign Up
                </Link>{" "}
                here.
              </p>
              <fieldset className="mt-8 flex flex-col justify-between ">
                <SignUpInput
                  title="E-mail"
                  value={formik.values.emailId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="emailId"
                  error={
                    (formik.touched.emailId && formik.errors.emailId) || error
                  }
                />
              </fieldset>
              <p className="text-sm text-errorRed">{error}</p>
              <Link to="/forgot-phone" className="ml-4 text-xs text-darkPurple">
                Forgot your email?
              </Link>
              <footer className="my-8 flex justify-between sm:mt-20 md:mt-32">
                <Link to="/login">
                  <HollowButton title="Back" medium className="w-[150px]" />
                </Link>
                <HollowButton title="Next" medium className="w-[150px]" />
              </footer>
            </div>
          </Container>
        </form>
      )}
      {/* Third Page */}
      {page === 2 && (
        <section className=" flex min-h-[470px] w-full flex-col justify-between rounded-md bg-bafWhite py-8  sm:min-h-[600px] md:h-[100vh] md:min-w-[450px]">
          <Container>
            <div className="md:h-[500px] md:px-8 lg:px-12">
              <div>
                <h1 className=" md:mt-[180px] text-4xl font-bold">
                  Reset Link Sent!
                </h1>
                <p className=" mt-4 max-w-[300px] text-xs">{success}</p>
              </div>
              <footer className="mt-[200px]">
                <Link to="/login">
                  <HollowButton title="Back" medium className="w-[150px]" />
                </Link>
              </footer>
            </div>
          </Container>
        </section>
      )}
    </div>
  );
}
