import React, { useState, useEffect } from "react";
import HollowButton from "../Shared/HollowButton";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
// import {
//   formatDate,
//   formatTime,
//   slashedDate,
//   uppercaseString,
// } from "../helpers/helpers";

export default function ReferralCodeCard({ token }) {
  const [copied, setCopied] = useState(false);
  const [referralInfo, setReferralInfo] = useState([]);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}getReferalCode`,
        {},
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        setReferralInfo(res.data.response);
      });
  }, [token]);

  const { referalCode } = referralInfo || [];

  return (
    <div className="ReferralCodeCard flex justify-between items-center shadow-[0px_4px_4px_rgba(0,0,0,0.1),5px_-1px_10px_#F3F3F3] rounded-[10px] border-b border-b-bafOrange max-w-[435px] h-[100px]  p-5 bg-bafWhite md:max-w-[540px] md:px-6">
      <div className="max-w-[65%]">
        <p className="text-xs">Referral Code</p>
        <h4 className="text-[28px] font-semibold truncate">{`${referalCode}`}</h4>
      </div>
      <CopyToClipboard text={`${referalCode}`} onCopy={() => setCopied(true)}>
        <span>
          <HollowButton
            title="Copy"
            type="button"
            className="w-[94px] h-[38px] text-[14px] md:w-[116px]"
            backgroundHoverBlack
          />
        </span>
      </CopyToClipboard>
    </div>
  );
}
