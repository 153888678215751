import { useState, useRef } from "react";
import HollowButton from "./HollowButton";

function DragDropFile({
  handleFile,
  preview,
  title,
  originalImage,
  fileName,
  upload,
  additional,
  acceptedFileType,
  buttonTitle,
  allowClick,
  small,
}) {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  // const handleClick = (e) => {
  //   if (allowClick) {
  //     e.preventDefault()
  //   }
  // }
  console.log(allowClick);
  return (
    <form
      className={`${small ? "h-20" : " h-[16rem] lg:h-[20rem]"
        }relative mt-2 w-[28rem] max-w-full mx-auto text-center `}
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple={false}
        className="hidden disabled"
        onChange={handleChange}
        accept={acceptedFileType}
      // onClick={e => e.preventDefault()}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        onClick={allowClick ? "" : (e) => e.preventDefault()}
        className={`flex h-full items-center justify-center ${!upload && "rounded-md border-2 border-dashed border-bafWhite"
          } ${dragActive ? "bg-lightPurple" : ""}`}
      >
        <div>
          {preview ? (
            <img
              src={preview}
              className="h-[120px] w-[120px] mx-auto"
              alt="preview"
            />
          ) : (
            originalImage && (
              <img
                src={originalImage}
                className="mx-auto h-[130px] w-[130px]"
                alt="preview"
              />
            )
          )}
          <p
            className={`max-w-[170px] mx-auto text-xs mt-2 ${dragActive ? "text-darkPurple" : "text-bafGrey"
              }`}
          >
            {title}
          </p>

          {!upload ? (
            <HollowButton
              backgroundHover
              handleClick={onButtonClick}
              title={buttonTitle || "Upload Image"}
              background="darkPurple"
              color="bafWhite"
              border="none"
              className="mt-3 "
            ></HollowButton>
          ) : (
            <div className="drag-drop-box flex w-[350px] flex-col items-center rounded-xl border-2 border-bafWhite py-14 text-bafWhite md:flex-row md:justify-center md:py-8">
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fill-rule="evenodd"
                clip-rule="evenodd"
                className="w-100% mb-8 fill-bafWhite md:mb-0"
              >
                <path d="M8 11h-6v10h20v-10h-6v-2h8v14h-24v-14h8v2zm-1-4l5-6 5 6h-4v11h-2v-11h-4z" />
              </svg>
              <p className="mx-10 text-center md:mx-4">
                <span className="text-darkPurple">Drag and drop</span> or{" "}
                <span className="text-darkTeal">browse</span> files
              </p>
            </div>
          )}

          <p className="mt-4 text-darkPurple">{fileName}</p>
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          className="absolute top-0 right-0 bottom-0 left-0 h-full w-full rounded-xl"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
}
export default DragDropFile;
