import React, { useEffect, useState } from "react";
import { ReactComponent as SampeCoin } from "../images/sample-coin.svg";
import axios from "axios";
// import {
//   formatDate,
//   formatTime,
//   slashedDate,
//   uppercaseString,
// } from "../helpers/helpers";

export default function PointsBalanceProgressCard({ token }) {
  const [pointsInfo, setPointsInfo] = useState([]);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}getPointsBalance`,
        {},
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        setPointsInfo(res.data.response);
      });
  }, [token]);

  const {
    totalPoints,
    currentTierName,
    tierStartPoint,
    tierEndPoint,
    nextTierName,
    currentTierImage,
    nextTierImage,
  } = pointsInfo || {};

  const progressPercentage = (totalPoints / tierEndPoint) * 100;

  const ProgressBar = ({ progressPercentage }) => {
    return (
      <div className="h-2 w-[50%] rounded-md border border-coolGrey xs:w-[65%] md:w-[65%] lg:w-[70%]">
        <div
          style={{ width: `${progressPercentage}%` }}
          className={`h-full bg-darkGreen`}
        ></div>
      </div>
    );
  };

  return (
    <div className="PointsBalanceProgressCard flex flex-col justify-between shadow-[0px_4px_4px_rgba(0,0,0,0.1),5px_-1px_10px_#F3F3F3] rounded-[10px] border-b border-b-darkGreen bg-bafWhite max-w-[435px] h-[134px] px-4 py-5 md:max-w-[540px] md:px-6">
      <header className="flex justify-between items-center gap-2">
        <p className="text-xs w-full max-w-fit truncate">Points Balance</p>
        <div className="flex items-center gap-2">
          <p className="text-bafGrey text-xs truncate">{`Next badge: ${nextTierName?.substring(
            0,
            nextTierName.indexOf("-")
          )}`}</p>
          <img
            src={`${nextTierImage}`}
            alt="next tier badge"
            className="max-h-8"
          />
        </div>
      </header>

      <p className="text-[1.75rem] leading-10 font-semibold">
        {totalPoints?.toLocaleString("en-US")}
      </p>

      <footer className="w-full flex items-center justify-between">
        <ProgressBar progressPercentage={progressPercentage} />
        <p className="text-bafGrey text-xs">{`out of ${tierEndPoint?.toLocaleString(
          "en-US"
        )} pts`}</p>
      </footer>
    </div>
  );
}
