import React from "react";
import Select from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow:
      "inset -4px -4px 4px #FFFFFF, inset 4px 4px 4px rgba(0, 0, 0, 0.05)",
    // border: "2px solid black",
    borderRadius: "10px",
    padding: "0.2rem 1rem",
    fontWeight: "",
    border: "none",
  }),
};

export default function CustomSelect({
  error,
  label,
  onChange,
  options,
  value,
  onBlur,
  className,
  labelClassName,
}) {
  const defaultValue = (options, value) => {
    return options
      ? options.find((option) => {
          return option.value === value;
        })
      : "";
  };
  return (
    <div className={`${className} my-4`}>
      <label className={`ml-2 text-sm sm:text-md ${labelClassName}`}>
        {label}
      </label>
      <Select
        defaultValue={defaultValue(options, value)}
        value={defaultValue(options, value) || null}
        onBlur={onBlur}
        onChange={(value) => onChange(value)}
        options={options}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "white",
            primary: "#a590e3",
          },
        })}
      />

      <p className="ErrorMessage ml-1 mt-2 text-[10px] text-errorRed">
        {error}
      </p>
    </div>
  );
}
