import React, { useState } from "react";
import CampaignTableRow from "./CampaignTableRow";

export default function CampaignTable({
  campaigns,
  token,
  leadType,
  currentPage,
  campaignFilter,
}) {
  const [selectedCampaigns, setSelectedCampaigns] = useState([
    { id: "1", selected: false },
    { id: "2", selected: false },
    { id: "3", selected: false },
  ]);

  // const campaignsWithId =
  //   campaigns &&
  //   campaigns.map((campaign, i) => {
  //     return { id: i, selected: false, ...campaign };
  //   });

  //* Set Selected Campaign Based On ID
  const handleSelect = (id) => {
    const updatedCampaigns =
      selectedCampaigns &&
      selectedCampaigns.map((campaign) => {
        if (campaign.id === id) {
          return { ...campaign, selected: !campaign.selected };
        }
        return campaign;
      });

    setSelectedCampaigns(updatedCampaigns);
  };
  campaigns = campaigns || [];
  return (
    <div className="CampaignTable bg-bafWhite rounded-md w-full mb-8">
      <table className="w-full table-fixed ">
        <thead className="">
          <tr
            className={`CategoryRow border-[#ABABAB] mb-4 px-4 pt-6 tablet:px-8 gap-4 grid ${
              campaignFilter === "topPerforming"
                ? "grid-cols-[0.6fr_2.25fr_75px_0.5fr] tablet:grid-cols-[1fr_3fr_repeat(5,2fr)_0.5fr]"
                : "grid-cols-[3fr_75px_0.5fr] tablet:grid-cols-[3fr_repeat(5,2fr)_0.5fr]"
            }`}
          >
            {campaignFilter === "topPerforming" && (
              <th className={`text-start text-[#ABABAB] text-xs`}>Rank</th>
            )}
            <th className={`text-start text-[#ABABAB] text-xs `}>Campaign</th>

            <th className={`table-cell text-[#ABABAB] text-xs text-start`}>
              Status
            </th>
            <th
              className={`hidden tablet:table-cell text-[#ABABAB] text-xs text-start `}
            >
              Company
            </th>

            <th
              className={`hidden tablet:table-cell text-[#ABABAB] text-xs text-start `}
            >
              Active Date
            </th>
          </tr>
        </thead>
        <tbody>
          {campaigns.map((campaign, index) => (
            <CampaignTableRow
              key={campaign.id}
              id={campaign.id}
              campaign={campaign}
              selected={campaign.selected}
              handleSelect={handleSelect}
              token={token}
              leadType={leadType}
              index={index}
              currentPage={currentPage}
              campaignFilter={campaignFilter}
            />
          ))}
          {campaigns.length === 0 && (
            <div className="CampaignsTableRow w-full odd:bg-veryLightGreen last:rounded-b-md">
              <tr
                className={` w-[100%] flex justify-between items-center p-4 tablet:px-8 tablet:gap-4`}
              >
                <td>No campaigns found.</td>
              </tr>
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
}
