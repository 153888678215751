import { useMemo } from "react";

const RightArrowSvg = ({ onClick }) => (
  <button type="button" className="ml-2" onClick={onClick}>
    <svg
      width="39"
      height="15"
      viewBox="0 0 39 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.844 8.42149C38.2299 8.03554 38.2299 7.40978 37.844 7.02382L31.5544 0.734271C31.1685 0.348313 30.5427 0.348313 30.1568 0.734271C29.7708 1.12023 29.7708 1.74599 30.1568 2.13195L35.7475 7.72266L30.1568 13.3134C29.7708 13.6993 29.7708 14.3251 30.1568 14.711C30.5427 15.097 31.1685 15.097 31.5544 14.711L37.844 8.42149ZM0.907227 8.71096H37.1451V6.73435H0.907227V8.71096Z"
        fill="black"
      />
    </svg>
  </button>
);

const LeftArrowSvg = ({ onClick }) => (
  <button type="button" className="mr-4" onClick={onClick}>
    <svg
      width="38"
      height="15"
      viewBox="0 0 38 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.06325 7.02382C0.677288 7.40978 0.677288 8.03554 1.06325 8.4215L7.35279 14.711C7.73875 15.097 8.36451 15.097 8.75047 14.711C9.13643 14.3251 9.13643 13.6993 8.75047 13.3134L3.15976 7.72266L8.75047 2.13195C9.13643 1.74599 9.13643 1.12023 8.75047 0.734274C8.36451 0.348316 7.73875 0.348316 7.35279 0.734274L1.06325 7.02382ZM38 6.73435L1.76208 6.73435L1.76209 8.71097L38 8.71096L38 6.73435Z"
        fill="black"
      />
    </svg>
  </button>
);

const Pagination = ({
  pages = 1,
  currentPage = 0,
  maxDisplay = 4,
  isLast = false,
  handlePageChange,
  isFirst,
}) => {
  const pageList = [...new Array(pages)].map((v, i) => i);
  const groupIndex = useMemo(
    () => Math.floor(currentPage / maxDisplay),
    [currentPage, maxDisplay]
  );

  const handleNextGroup = () => {
    handlePageChange((groupIndex + 1) * maxDisplay);
  };

  const previousGroup = () => {
    handlePageChange((groupIndex - 1) * maxDisplay);
  };

  return (
    <div className="mb-10 mt-[60px] flex items-center justify-center">
      {!isFirst && (
        <div className="ml-2 flex items-center">
          <LeftArrowSvg onClick={previousGroup} />
        </div>
      )}

      {pageList.map((page, index) => {
        if (index < maxDisplay && pageList.length > 1) {
          return (
            <button
              type="button"
              key={"page" + page}
              className={`px-4 text-2xl ${
                groupIndex * maxDisplay + page === currentPage
                  ? "text-darkGreen font-bold"
                  : "text-coolGrey"
              }`}
              onClick={() => handlePageChange(groupIndex * maxDisplay + page)}
            >
              {}
              {groupIndex * maxDisplay + page + 1 < pages + 1 &&
                groupIndex * maxDisplay + page + 1}
            </button>
          );
        }
      })}
      {isLast && (
        <div className="ml-2 flex items-center">
          <RightArrowSvg onClick={handleNextGroup} />
        </div>
      )}
    </div>
  );
};

export default Pagination;
