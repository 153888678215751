import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";

export default function useDropDownValues() {
  const [dropDownValues, setDropDownValues] = useState([]);
  useEffect(() => {
    axios
      .get("https://api.brandfiliate.net/v2/dealer/registrationDropdownValues")
      .then((res) => setDropDownValues(res.data.response));
  }, []);

  return dropDownValues;
}
