import React, { useState } from "react";
import Multiselect from "multiselect-react-dropdown";

export default function DropdownCheckbox({ options, handleList }) {
  // const [options] = useState([
  //   "Option1",
  //   "Option2",
  //   "Option3",
  //   "Option4",
  //   "Pink",
  //   "Red",
  // ]);
  const removed = (e) => {
    console.log(e);
    console.log(e.length);
  };
  const selected = (e) => {
    console.log(e);
    console.log(e.length);
    handleList(e);
  };
  return (
    <>
      <div className="">
        {/* <h3 className="mt-16 text-bold"> Select multi dropdown checkbox </h3> */}
        <p> Currently selected </p>
        <Multiselect
          isObject={false}
          options={options}
          onRemove={(event) => {
            removed(event);
          }}
          onSelect={(event) => {
            selected(event);
          }}
          // showCheckbox
          avoidHighlightFirstOption
          showArrow
          //   customArrow
          placeholder="Search Category"
          id="css_custom"
          className="w-full"
        />
      </div>
    </>
  );
}
