import axios from "axios";
import React from "react";
import { useEffect } from "react";
import HollowButton from "../Shared/HollowButton";
import InfoInput from "../Shared/InfoInput";
import { useFormik } from "formik";
import * as Yup from "yup";
// import TextArea from "../Shared/TextArea";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as CloseIcon } from "../images/modal-close-button-black.svg";

export default function BankingModal({
  showModal,
  toggleModal,
  token,
  email,
  id,
  title,
  country,
  minWithdrawal,
}) {
  const [message, setMessage] = useState("");
  const [page, setPage] = useState(1);
  const today = new Date().getFullYear();
  const minAge = today - 13;
  const [dob, setDob] = useState(new Date(String(minAge)));
  const [error, setError] = useState("");

  useEffect(() => {
    const body = document.body;
    body.classList.add("modal-open");

    return () => {};
  }, []);

  const closeModal = () => {
    const body = document.body;
    body.classList.remove("modal-open");
    toggleModal();
  };

  const API_PARAM =
    title === "New Message"
      ? "sendAffiliateMessage"
      : "sendAffiliateMultipleMessage";

  const reLetters = "^[a-zA-Z]+$";
  const reNumbers = /^\d+$/;
  const dashes = "^[0-9.-]*$";

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      bankNumber: "",
      accountNumber: "",
      withdrawalAmount: "",
      zipCode: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Please enter your first name")
        .matches(reLetters, "Name can only contain letters"),

      lastName: Yup.string()
        .required("Please enter your last name")
        .matches(reLetters, "Name can only contain letters"),
      bankNumber: Yup.string()
        .required("Please enter bank number")
        .matches(dashes, "Must be all numbers")
        .min(9, "Number must be 9 characters")
        .max(9, "Number must be 9 characters"),
      accountNumber: Yup.string()
        .required("Please enter account number")
        .matches(reNumbers, "Must be all numbers")
        .min(12, "Number must be 12 characters")
        .max(12, "Number must be 12 characters"),
      socialNumber: Yup.string()
        .required("Please enter social number")
        .matches(reNumbers, "Must be all numbers")
        .min(9, "Number must be 9 characters")
        .max(9, "Number must be 9 characters"),
      withdrawalAmount: Yup.number()
        .required("Please enter withdrawal amount")
        .min(minWithdrawal, `Minimum withdrawal is $${minWithdrawal}`)
        .max(500, "Maximum withdrawal is $500"),
      zipCode: Yup.string()
        .required("Please enter your zip code")
        .min(5)
        .max(7),
    }),
    onSubmit: (values) => {
      const data = {
        firstName: values.firstName,
        lastName: values.lastName,
        dob: dob,
        accountNumber: values.accountNumber,
        withdrawalAmount: values.withdrawalAmount,
        zipCode: values.zipCode,
      };

      const americanData = {
        ...data,
        routingNumber: values.bankNumber,
        ssn: values.socialNumber,
      };

      const canadaData = {
        ...data,
        transitNumber: values.bankNumber,
        socialNumber: values.socialNumber,
      };
      setPage(2);
      if (page === 1) {
        setPage(2);
      } else if (page === 2) {
        const API_PARAM =
          country === "USA" ? "withdrawAmerican" : "withdrawCanadian";
        axios
          .post(
            `${process.env.REACT_APP_PROD_URL}${API_PARAM}`,
            country === "USA" ? americanData : canadaData,
            {
              headers: {
                Authorization: token,
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.data.responseCode === 0) {
              setMessage(res.data.successMsg);
              setPage(3);
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            } else {
              setError(res.data.errorMsg);
            }
          });
      }
    },
  });
  const nextPage = () => {
    setPage((page) => page + 1);
  };
  const disableNextButton =
    !formik.values.firstName ||
    formik.errors.firstName ||
    formik.errors.lastName ||
    formik.errors.bankNumber ||
    formik.errors.accountNumber ||
    formik.errors.zipCode ||
    formik.errors.socialNumber;

  const disableWithdrawButton = formik.errors.withdrawalAmount;

  const americanRouting = `The routing number for your bank account can be viewed in your banking application's profile page, or contact your local bank for more details.`;
  const canadianTransit = `The transit number for your bank account can be viewed in your banking application's profile page, or contact your local bank for more details.`;
  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center  overflow-x-hidden  bg-bafGrey bg-opacity-30 backdrop-brightness-[0.2] outline-none focus:outline-none">
            <form
              onSubmit={formik.handleSubmit}
              className=" BankingModal relative mx-auto mb-[100px] mt-[100px]  max-h-[90vh] w-[90%] min-w-[350px] max-w-[750px] rounded-2xl bg-bafWhite"
            >
              <div className="BankingModal relative min-h-[450px] flex w-full flex-col rounded-2xl border-0 bg-white outline-none focus:outline-none px-2 sm:px-4  md:px-8">
                <button
                  className="absolute border-0 bg-transparent text-black top-4 right-4"
                  type="button"
                  onClick={closeModal}
                >
                  <CloseIcon className="w-5 h-5" />
                </button>
                {page === 1 && (
                  <>
                    {" "}
                    <div className="flex flex-col justify-evenly py-5 px-12 md:px-20">
                      <h3 className="my-8  mt-12 text-center text-2xl md:text-4xl font-semibold text-bafBlack">
                        Banking Details
                      </h3>
                      <p className="text-center font-semibold text-xl">
                        Fill in the details of a void cheque below and we will
                        process your request as soon as possible.
                      </p>
                      <div className="grid md:grid-cols-2 gap-4 md:gap-8 mt-10">
                        <InfoInput
                          title="First Name"
                          value={formik.values.firstName}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          id="firstName"
                          error={
                            formik.touched.firstName && formik.errors.firstName
                          }
                          className="w-[50%]"
                          hideIcon
                          underline
                          bold
                        />
                        <InfoInput
                          title="Last Name"
                          value={formik.values.lastName}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          id="lastName"
                          error={
                            formik.touched.lastName && formik.errors.lastName
                          }
                          hideIcon
                          underline
                          bold
                        />
                        <fieldset className=" max-h-[73px] border-b border-bafBlack flex flex-col justify-evenly">
                          <span className="text-[0.75rem]  sm:text-[1.2rem] font-bold ml-1">
                            Birthday
                          </span>
                          <DatePicker
                            selected={dob}
                            onChange={(date) => setDob(date)}
                            className="Input border-none"
                            maxDate={new Date(String(minAge))}
                          />
                        </fieldset>
                        <InfoInput
                          title="Zip Code"
                          value={formik.values.zipCode}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          id="zipCode"
                          error={
                            formik.touched.zipCode && formik.errors.zipCode
                          }
                          hideIcon
                          underline
                          bold
                        />
                        <InfoInput
                          title={
                            country === "USA"
                              ? "Routing Number"
                              : "Transit Number"
                          }
                          value={formik.values.bankNumber}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          id="bankNumber"
                          type="number"
                          bold
                          toolTipContent={
                            <p className="w-[150px] font-normal md:w-[200px]">
                              {country === "USA"
                                ? americanRouting
                                : canadianTransit}
                            </p>
                          }
                          error={
                            formik.touched.bankNumber &&
                            formik.errors.bankNumber
                          }
                          underline
                        />
                        <InfoInput
                          title={
                            country === "USA"
                              ? "Account Number"
                              : "Checking Account Number"
                          }
                          value={formik.values.accountNumber}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          id="accountNumber"
                          error={
                            formik.touched.accountNumber &&
                            formik.errors.accountNumber
                          }
                          hideIcon
                          underline
                          bold
                        />
                        <InfoInput
                          title={
                            country === "USA"
                              ? "SSN"
                              : "Social Insurance Number"
                          }
                          value={formik.values.socialNumber}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          id="socialNumber"
                          error={
                            formik.touched.socialNumber &&
                            formik.errors.socialNumber
                          }
                          hideIcon
                          underline
                          bold
                        />
                      </div>
                      <p>{message}</p>
                    </div>
                    <div className="flex items-center my-10   justify-evenly  p-6">
                      <HollowButton
                        title="Close"
                        type="button"
                        medium
                        backgroundHoverBlack
                        handleClick={closeModal}
                        className="w-[120px] md:w-[150px]"
                      />
                      <HollowButton
                        title="Confirm"
                        medium
                        disabled={disableNextButton}
                        color="bafWhite"
                        backgroundHoverPurple
                        background="bafBlack"
                        className="w-[120px] md:w-[150px]"
                        handleClick={nextPage}
                      />
                    </div>
                  </>
                )}
                {/* SECOND PAGE */}
                {page === 2 && (
                  <div className="mt-12 px-8 md:px-20 text-center">
                    <h1>Withdrawal Amount</h1>
                    <p className="mt-4 font-semibold">
                      You must request a minimum of ${minWithdrawal}. For help
                      and support, please contact support@brandaf.com
                    </p>
                    <div className="mt-12 md:mt-20 md:px-10">
                      <InfoInput
                        title={"Amount to Withdraw"}
                        value={formik.values.withdrawalAmount}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        id="withdrawalAmount"
                        type="number"
                        error={
                          formik.touched.withdrawalAmount &&
                          formik.errors.withdrawalAmount
                        }
                        hideIcon
                        underline
                        bold
                      />
                      <p className="text-end mt-4 font-semibold text-bafGrey">
                        $500 Maximum
                      </p>
                    </div>
                    <p className="text-xs my-4 text-errorRed">{error}</p>
                    <div className="flex items-center my-10   justify-evenly  p-6">
                      <HollowButton
                        title="Cancel"
                        type="button"
                        medium
                        handleClick={() => setPage(1)}
                        className="w-[120px] md:w-[150px]"
                      />
                      <HollowButton
                        title="Withdraw"
                        medium
                        disabled={disableWithdrawButton}
                        color="bafWhite"
                        backgroundHover
                        background="bafBlack"
                        className="w-[120px] md:w-[150px]"
                      />
                    </div>
                  </div>
                )}
                {/* THIRD PAGE */}
                {page === 3 && (
                  <div className="text-center px-8 md:px-24">
                    <h1 className="mt-20">Request Sent!</h1>
                    <p className="my-8 font-semibold">
                      Your request of ${formik.values.withdrawalAmount} USD has
                      been sent to Brandaf. A fee of 15% will be deducted and
                      there will be a grace period for 20 days before the
                      request has been approved and deposited into your bank
                      account.
                    </p>
                    <HollowButton
                      title="Awesome!"
                      medium
                      color="bafWhite"
                      backgroundHover
                      background="bafBlack"
                      className="w-[120px] md:w-[150px] my-12"
                      handleClick={closeModal}
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
        </>
      ) : null}
    </>
  );
}
