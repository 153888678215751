import React from "react";

export default function Checkbox({
  name,
  value,
  onChange,
  label,
  round,
  roundedCorners,
  backgroundColor,
  borderColor,
  onClick,
  checked,
  purple,
}) {
  return (
    <div className={`Checkbox max-w-fit`}>
      <input
        onClick={onClick}
        type="checkbox"
        className={`Checkbox outline-none focus:ring-0 focus:outline-none ${
          purple ? "text-darkPurple" : "text-lightGreen "
        }  border-2 border-${borderColor || ""} bg-${
          backgroundColor || "[#E5E5E5]"
        } ${round && "rounded-full"} ${
          roundedCorners && "rounded-md"
        } mr-2 h-6 w-6`}
        name={name}
        value={value}
        id={name}
        onChange={onChange}
        checked={checked}
      />
      <label for={name}>{label}</label>
    </div>
  );
}
