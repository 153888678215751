import React from "react";
import find from "lodash/find";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { getDayOfWeek, abbreviateNumber } from "../helpers/helpers";

import { orderBy } from "lodash";
import moment from "moment/moment";

export default function SingleCampaignPerformanceChart() {
  const campaigns = [
    {
      date: "2022-11-19T00:00:00.000Z",
      leadCount: 1888,
      sharedCount: 2777,
    },
    {
      date: "2022-11-18T00:00:00.000Z",
      leadCount: 1560,
      sharedCount: 1778,
    },
    {
      date: "2022-11-17T00:00:00.000Z",
      leadCount: 3123,
      sharedCount: 3434,
    },
    {
      date: "2022-11-16T00:00:00.000Z",
      leadCount: 2222,
      sharedCount: 2344,
    },
    {
      date: "2022-11-15T00:00:00.000Z",
      leadCount: 6654,
      sharedCount: 4345,
    },
    {
      date: "2022-11-14T00:00:00.000Z",
      leadCount: 3234,
      sharedCount: 3234,
    },
    {
      date: "2022-11-13T00:00:00.000Z",
      leadCount: 6535,
      sharedCount: 0,
    },
  ];

  const sortedChart = orderBy(campaigns, ["date"], ["asc"]);

  //* Format Dates In Chart`
  let formattedYearChart = [];
  if (sortedChart) {
    formattedYearChart = sortedChart.map((chartItem) => {
      const date = moment(chartItem.date)
        .utc()
        .format("ddd MMM D YYYY, h:mm:ss a");
      const dayOfWeek = getDayOfWeek(date, true);
      const monthDay = moment(chartItem.date).utc().format("DD");

      return {
        name: dayOfWeek + monthDay,
        leads: chartItem.leadCount,
        shares: chartItem.sharedCount,
      };
    });
  }

  //* Group The Data By Month - Incrementing If Month Is Already There
  const groupedChart = [];
  for (const item of formattedYearChart) {
    const found = find(groupedChart, {
      name: item.name,
    });
    if (!found) {
      groupedChart.push(item);
    } else {
      groupedChart[groupedChart.length - 1].leadCount += item.leadCount;
      groupedChart[groupedChart.length - 1].shareCount += item.shareCount;
    }
  }

  //* Change Tool Tip Box Content
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-bafWhite p-2 border-bafBlack border rounded-[1px]">
          {payload[0] && (
            <p className="font-bold text-[0.625rem] capitalize">{`${abbreviateNumber(
              payload[0].value
            )} ${payload[0].name}`}</p>
          )}
          {payload[1] && (
            <p className="font-bold text-[0.625rem] capitalize">{`${abbreviateNumber(
              payload[1].value
            )} ${payload[1].name}`}</p>
          )}
        </div>
      );
    }
    return null;
  };

  const customLegendTextStyling = (value, entry, index) => {
    return (
      <span className="text-bafBlack text-[0.75rem] capitalize">{value}</span>
    );
  };

  const customLegendWrapperStyle = () => {
    if (window.innerWidth < 768) {
      return {
        paddingTop: "20px",
        marginRight: "-20px",
      };
    } else {
      return {
        paddingTop: "15px",
        paddingBottom: "5px",
        marginRight: "-20px",
      };
    }
  };

  //* Change X Axis Ticks
  const CustomizedXAxisTick = ({ x, y, stroke, payload }) => {
    let dayOfWeek = payload.value.slice(0, 3);
    let date = payload.value.substring(3);

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={5}>
          <tspan
            textAnchor="middle"
            x="0"
            className="text-[0.625rem] font-bold"
          >
            {dayOfWeek}
          </tspan>
          <tspan textAnchor="middle" x="0" dy="15" className="text-[0.625rem]">
            {date}
          </tspan>
        </text>
      </g>
    );
  };

  //* Format Y Axis Ticks
  const YAxisFormatter = (number) => {
    if (number > 999) {
      return number / 1000 + "K";
    } else {
      return number;
    }
  };

  return (
    <div className="AffiliateActivityChart rounded-[10px] shadow-[0px_4px_4px_rgba(0,0,0,0.1),5px_-1px_10px_#F3F3F3] bg-bafWhite p-2 h-[309px] w-[436px] md:h-[309px] md:w-[478px]">
      <p className="text-[0.75rem] ml-7 my-2">My Performance This Campaign</p>
      {campaigns.length > 0 ? (
        <ResponsiveContainer height="90%">
          <LineChart
            width={500}
            height={300}
            data={groupedChart}
            margin={{
              top: 20,
              right: 20,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey={"name"}
              tick={CustomizedXAxisTick}
              axisLine={{ stroke: "black" }}
              tickLine={false}
              tickMargin={10}
              style={{ fill: "black", fontSize: "10px" }}
            />
            <YAxis
              tickMargin={10}
              axisLine={{ stroke: "black" }}
              style={{ fill: "black", fontSize: "10px" }}
              tickLine={{ stroke: "#A8A8A8" }}
              label={{
                value: "Leads and Shares",
                angle: -90,
                dx: -20,
                fontSize: 12,
              }}
              tickFormatter={YAxisFormatter}
            />
            <Tooltip
              cursor={{
                stroke: "black",
                strokeDasharray: "5 5",
              }}
              itemStyle={{ color: "black" }}
              content={CustomTooltip}
              wrapperStyle={{ outline: "none" }}
            />
            <Legend
              iconType="circle"
              align="right"
              wrapperStyle={customLegendWrapperStyle()}
              formatter={customLegendTextStyling}
            />
            <Line
              type="basic"
              dataKey="shares"
              stroke="#65CCC9"
              dot={{ fill: "#65CCC9", r: 2 }}
              activeDot={{
                stroke: "black",
                fill: "white",
                r: 2,
                strokeWidth: 1,
              }}
            />
            <Line
              type="basic"
              dataKey="leads"
              stroke="#8884d8"
              dot={{ fill: "#8884d8", r: 2 }}
              activeDot={{
                stroke: "black",
                fill: "white",
                r: 2,
                strokeWidth: 1,
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <div className="h-[240px] flex items-center justify-center">
          <p>No data to display.</p>
        </div>
      )}
    </div>
  );
}
