import React from "react";
import { Link } from "react-router-dom";

export default function Menu({ title, to, external, highlight, bold }) {
  return external ? (
    <div className="hover:border-darkGreen-600 h-full w-full">
      <li className="LinkHighlight h-full w-full  min-w-fit list-none ">
        <a
          target="_blank"
          rel="noreferrer"
          href={to}
          className={`pl-1 block group w-full min-h-[40px] h-full text-xs py-[14px] md:text-sm  min-w-fit ${
            bold && "font-bold"
          } transition duration-300 lg:text-base `}
        >
          {title}
        </a>
      </li>
    </div>
  ) : (
    <div className="hover:border-darkGreen-600 h-full w-full">
      <li className="LinkHighlight h-full w-full  min-w-fit list-none ">
        <Link
          to={to}
          className={`pl-1 block group w-full min-h-[40px] h-full text-xs py-[14px] md:text-sm  min-w-fit ${
            bold && "font-bold"
          } transition duration-300 lg:text-base `}
        >
          {title}
        </Link>
      </li>
    </div>
  );
}
