import moment from "moment/moment";

//* Will Return Month String
export const formatMonth = (date) => {
  let month = "";
  switch (date) {
    case 0:
      month = "Jan";
      break;
    case 1:
      month = "Feb";
      break;
    case 2:
      month = "Mar";
      break;
    case 3:
      month = "Apr";
      break;
    case 4:
      month = "May";
      break;
    case 5:
      month = "Jun";
      break;
    case 6:
      month = "Jul";
      break;
    case 7:
      month = "Aug";
      break;
    case 8:
      month = "Sep";
      break;
    case 9:
      month = "Oct";
      break;
    case 10:
      month = "Nov";
      break;
    case 11:
      month = "Dec";
      break;
    default:
      break;
  }
  return month;
};

//* Will Return 'ex... July 10, 2022'
export const formatDate = (timeStamp, short) => {
  const date = new Date(timeStamp);
  const month = date.getMonth();
  const fullMonth = formatMonth(month);
  const day = date.getDate();
  const year = date.getFullYear();
  if (short) {
    return `${fullMonth} ${day}`;
  } else {
    return `${fullMonth} ${day}, ${year}`;
  }
};

//* Will Return 'ex... 10/10/2022'
export const slashedDate = (timeStamp, short) => {
  const date = new Date(timeStamp);

  const month = formatMonth(date.getMonth());
  const nunmberedMonth = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const year = date.getFullYear();
  if (short) {
    return `${month}/${day}`;
  } else {
    return `${nunmberedMonth}/${day}/${year}`;
  }
};

//* Will Return 'ex... July 10, 2022'
export const formatCampaignDate = (date) => {
  const month = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();
  return `${year}-${month + 1}-${day}`;
};

//* Will Return 'ex... 18:33 UTC'
export const formatUTCtime = (timeStamp) => {
  const time = moment(timeStamp).utc().format("HH:mm z");
  return `${time}`;
};

//* Will Return 'ex... 10:30 AM'
export const formatTime = (timestamp, short) => {
  let timeOfDay = "AM";
  const date = new Date(timestamp);
  let hours = ("0" + date.getHours()).slice(-2);
  let minutes = date.getMinutes();
  let seconds = ("0" + date.getSeconds()).slice(-2);
  // insert 0 before minutes that are under 10
  minutes = minutes <= 9 ? "0" + minutes : minutes;
  if (hours > 12) {
    hours = hours - 12;
    timeOfDay = "PM";
  }
  if (short) {
    return `${hours}:${minutes} ${timeOfDay}`;
  } else {
    return `${hours}:${minutes}:${seconds}${timeOfDay}`;
  }
};

//* Will Return 'ex... Monday'
export const getDayOfWeek = (date, short) => {
  const dayOfWeek = new Date(date).getDay();

  if (short) {
    return isNaN(dayOfWeek)
      ? null
      : ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][dayOfWeek];
  } else {
    return isNaN(dayOfWeek)
      ? null
      : [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ][dayOfWeek];
  }
};

export const switchCompanyType = (companyType) => {
  switch (companyType) {
    case "Start up":
      return 1;
    case "Agency":
      return 2;
    case "Brand":
      return 3;
    case "Other":
      return 4;
    default:
      break;
  }
};

export const switchIndustryType = (industryType) => {
  switch (industryType) {
    case "Sports":
      return 1;
    case "Wine":
      return 2;
    case "Automotive":
      return 3;
    case "Animals":
      return 4;
    case "Accessories":
      return 5;
    case "Art/Design/Photography":
      return 6;
    case "Baby/Kids/Family":
      return 7;
    case "Books/Media":
      return 8;
    case "Business/Finance/Insurance":
      return 9;
    case "Clothing":
      return 10;
    case "Education":
      return 11;
    case "Tech":
      return 12;
    case "Events & Activities":
      return 13;
    case "Flowers & Florists":
      return 14;
    case "Food/Drink & Venue":
      return 15;
    case "Gifts & Occasions":
      return 16;
    case "Home & Décor/Furniture":
      return 17;
    case "Home Improvement & Appliances":
      return 18;
    case "Non-Profit":
      return 19;
    case "Office Supplies":
      return 20;
    case "Personal Health & Beauty":
      return 21;
    case "Shoes & Handbags":
      return 22;
    case "Fitness & Outdoors":
      return 23;
    case "Streaming":
      return 24;
    case "Subscription Boxes/Services":
      return 25;
    case "Toys/Games":
      return 26;
    case "Travel/Vacations":
      return 27;
    default:
    case "":
      break;
  }
};

export const switchHearAbout = (referralType) => {
  switch (referralType) {
    case "Google/Search Engine":
      return 1;
    case "Facebook/Instagram":
      return 2;
    case "LinkedIn":
      return 3;
    case "Influencer Marketing Hub":
      return 4;
    case "Master Class":
      return 5;
    case "Podcast":
      return 6;
    case "Event/Conference":
      return 7;
    case "News Feature/Article/Blog":
      return 8;
    case "Friend/Affiliate or Influencer Referral":
      return 9;
    case "Other":
      return 10;
    default:
      break;
  }
};

export const switchRegion = (regionType) => {
  switch (regionType) {
    case "Alabama":
      return 1;
    case "Alaska":
      return 2;
    case "Arizona":
      return 3;
    case "Arkansas":
      return 4;
    case "California":
      return 5;
    case "Colorado":
      return 6;
    case "Connecticut":
      return 7;
    case "Delaware":
      return 8;
    case "Florida":
      return 9;
    case "Georgia":
      return 10;
    case "Hawaii":
      return 11;
    case "Idaho":
      return 12;
    case "Illinois":
      return 13;
    case "Indiana":
      return 14;
    case "Iowa":
      return 15;
    case "Kansas":
      return 16;
    case "Kentucky":
      return 17;
    case "Louisiana":
      return 18;
    case "Maine":
      return 19;
    case "Maryland":
      return 20;
    case "Massachusetts":
      return 21;
    case "Michigan":
      return 22;
    case "Minnesota":
      return 23;
    case "Mississippi":
      return 24;
    case "Missouri":
      return 25;
    case "Montana":
      return 26;
    case "Nebraska":
      return 27;
    case "Nevada":
      return 28;
    case "New Hampshire":
      return 29;
    case "New Jersey":
      return 30;
    case "New Mexico":
      return 31;
    case "New York":
      return 32;
    case "North Carolina":
      return 33;
    case "North Dakota":
      return 34;
    case "Ohio":
      return 35;
    case "Oklahoma":
      return 36;
    case "Oregon":
      return 37;
    case "Pennsylvania":
      return 38;
    case "Rhode Island":
      return 39;
    case "South Carolina":
      return 40;
    case "South Dakota":
      return 41;
    case "Tennessee":
      return 42;
    case "Texas":
      return 43;
    case "Utah":
      return 44;
    case "Vermont":
      return 45;
    case "Virginia":
      return 46;
    case "Washington":
      return 47;
    case "West Virginia":
      return 48;
    case "Wisconsin":
      return 49;
    case "Wyoming":
      return 50;
    case "Newfoundland and Labrador":
      return 51;
    case "Alberta":
      return 52;
    case "Saskatchewan":
      return 53;
    case "Prince Edward Island":
      return 54;
    case "British Columbia":
      return 55;
    case "Manitoba":
      return 56;
    case "New Brunswick":
      return 57;
    case "Nova Scotia":
      return 58;
    case "Quebec":
      return 59;
    case "Ontario":
      return 60;
    default:
      break;
  }
};

export const switchConversion = (conversionType) => {
  switch (conversionType) {
    case "Shopping Cart Checkout":
      return 1;
    case "Lead Form":
      return 2;
    case "Phone Sales":
      return 3;
    case "All of the above":
      return 4;
    case "'None of the above'":
      return 5;
    default:
      break;
  }
};

export const uppercaseString = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

//* Will return 604 123 4567
export const formatPhoneNumber = (number) => {
  return number.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
};

//* Will return 604-123-4567 or 1-604-123-4567
export const formatInternationalPhoneNumber = (number) => {
  var cleaned = ("" + number).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, " ", match[2], "-", match[3], "-", match[4]].join("");
  }
  return null;
};

//* Will return 1.5k or 10.0k etc
export const abbreviateNumber = (number) => {
  if ((number > 999) & (number < 1000000)) {
    return (number / 1000).toFixed(1) + "k";
  } else if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + "m";
  } else {
    return number;
  }
};
