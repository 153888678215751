import React, { useState } from "react";
import MiniAvatar from "../Shared/MiniAvatar";

// import { useState } from "react";
import { formatDate, formatTime } from "../helpers/helpers";
// import KebabMenuButton from "../Shared/KebabMenuButton";
import HollowButton from "../Shared/HollowButton";
import { useNavigate } from "react-router-dom";
import ShareCampaignModal from "../Modals/ShareCampaignModal";

export default function UpcomingCampaignCard({
  expanded,
  campaign,
  noShadow,
  token,
  setRefreshView,
}) {
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const campaignRedirect = () => {
    navigate(`/single-campaign/${campaign && campaign.campaignId}`);
  };
  const handleModal = (id, email, title) => {
    console.log("clicked");
    // setModalId(id);
    // setEmail(email);
    // setTitle(title);
    setShowModal((prev) => !prev);
  };
  return (
    <>
      {showModal && (
        <ShareCampaignModal
          showModal={showModal}
          toggleModal={handleModal}
          campaign={campaign}
          token={token}
        />
      )}
      <div
        className={`UpcomingCampaignCard ${
          noShadow ? "rounded-[10px]" : "Card"
        }  m-2 w-[300px]  bg-bafWhite  lg:w-[290px] ${
          expanded ? "h-[420px]" : "h-[250px] pt-4 pl-1 pr-1 pb-1"
        }`}
      >
        {expanded && (
          <div className="relative">
            {/* <div className="absolute right-3 top-3">
            <KebabMenuButton
              upcomingCampaignOptions
              campaignID={campaign && campaign.campaignId}
              campaignStatus={campaign && campaign.status}
              token={token}
              setDeleteRefresh={setRefreshView}
            />
          </div> */}
            <img
              className=" h-32 w-full rounded-t-md object-cover"
              src={campaign && campaign.campaignThumbNailLink}
              alt="brand"
            />
            <div className="flex relative justify-end bottom-6">
              <p className=" w-[130px] truncate rounded-l-lg border-2 border-black bg-bafWhite p-3 text-sm">
                {campaign && campaign.companyName}
              </p>
            </div>
          </div>
        )}

        <div className="px-6">
          {/* {!expanded && (
          <div className="float-right">
            <KebabMenuButton
              upcomingCampaignOptions
              campaignID={campaign && campaign.campaignId}
              campaignStatus={campaign && campaign.status}
              token={token}
              setDeleteRefresh={setRefreshView}
            />
          </div>
        )} */}
          <h4 className="truncate text-xl font-semibold capitalize mb-2">
            {campaign && campaign.campaignName}
          </h4>
          {!expanded && (
            <p className="text-[10px] text-bafGrey">
              By {campaign && campaign.companyName} &#8226;{" "}
              <span className="text-bafBlack">
                {" "}
                ${campaign && campaign.leadPayout}{" "}
              </span>
              <span>Per Lead</span>
            </p>
          )}

          {expanded && (
            <p className="truncate text-xs">
              {campaign && campaign.campaignDescription}
            </p>
          )}
          <div className="mt-6 h-4">
            <div
              className={`h-px 
            ${
              campaign &&
              (campaign.status === "inactive" || campaign.status === "disabled")
                ? "bg-darkPurple"
                : "bg-darkGreen"
            }`}
            ></div>
            <div
              className={`bg-[#C6F3EB] max-w-fit rounded-md px-3 z-10 relative top-[-15px] 
            ${
              campaign &&
              (campaign.status === "inactive" || campaign.status === "disabled")
                ? "bg-lightPurple"
                : "bg-[#C6F3EB]"
            }`}
            >
              <p
                className={`inline uppercase text-[10px] leading-[23px]
              ${
                campaign &&
                (campaign.status === "inactive" ||
                  campaign.status === "disabled")
                  ? "text-darkPurple"
                  : "text-darkGreen"
              }`}
              >
                {campaign && campaign.status === "new"
                  ? "ACTIVE"
                  : campaign && campaign.status}
              </p>
            </div>
            <div className="Affiliates z-[1] ml-36 -mt-9 flex max-w-fit float-right">
              {campaign &&
                campaign.affiliateImgList &&
                campaign.affiliateImgList.map((img) => (
                  <MiniAvatar src={img} />
                ))}
              <span
                className={`flex items-center justify-center rounded-full px-2 text-[10px] border border-white 
              ${
                campaign &&
                (campaign.status === "inactive" ||
                  campaign.status === "disabled")
                  ? "bg-lightPurple text-darkPurple"
                  : "bg-[#EBFFEC] text-darkGreen border-darkGreen"
              }`}
              >
                {campaign && campaign.totalShares > 0 && campaign.totalShares}
              </span>
            </div>
          </div>
          <div className="mt-4 flex justify-between">
            <div className="flex flex-col items-center">
              <p className="text-[10px] text-bafGrey">Leads</p>
              <h5 className="text-xl font-semibold">
                {campaign && campaign.totalLeadsGenerated}
              </h5>
              <p
                className={`text-[10px] font-bold 
              ${
                campaign &&
                (campaign.status === "inactive" ||
                  campaign.status === "disabled")
                  ? "text-darkPurple"
                  : "text-darkGreen"
              }`}
              >
                {campaign && campaign.leadGoal} Goal
              </p>
            </div>

            <div className="flex flex-col items-center">
              <p className="text-[10px] text-bafGrey">Start</p>
              <h5 className="text-xl font-semibold">
                {formatDate(campaign && campaign.campaignStartDate, true)}
              </h5>
              <p className="text-[10px] font-bold text-bafGrey">
                {formatTime(campaign && campaign.campaignStartDate, true)}
              </p>
            </div>

            <div className="flex flex-col items-center">
              <p className="text-[10px] text-bafGrey">End</p>
              <h5 className="text-xl font-semibold">
                {formatDate(campaign && campaign.campaignEndDate, true)}
              </h5>
              <p className="text-[10px] font-bold text-bafGrey">
                {formatTime(campaign && campaign.campaignEndDate, true)}
              </p>
            </div>
          </div>
          <footer className="mt-4 flex items-center justify-between ">
            <HollowButton
              title="View"
              small={true}
              color="black"
              backgroundHoverBlack
              className="w-[110px]"
              handleClick={campaignRedirect}
            />
            <HollowButton
              title="Share"
              small={true}
              color="bafWhite"
              background="bafBlack"
              backgroundHoverPurple
              className="w-[110px]"
              handleClick={handleModal}
            />
          </footer>
        </div>
      </div>
    </>
  );
}
