import React, { useState } from "react";
import Header from "../Shared/Header";
// import AffiliateNavigation from "../Shared/AffiliateNavigation";
import AffiliateCard from "../Cards/AffiliateCard";
import CampaignCard from "../Cards/CampaignCard";
import FullNotification from "../Shared/FullNotification";
import ChangeProfileModal from "../Modals/ChangeProfileModal";
import UpcomingCampaignCard from "../Cards/UpcomingCampaignCard";
import BankingModal from "../Modals/BankingModal";
import BrandNavigation from "../Shared/BrandNavigation";
import ShareCampaignModal from "../Modals/ShareCampaignModal";
import TransactionHistory from "../Shared/TransactionHistory";
import CampaignTable from "../Tables/CampaignTable";
import AffiliatesTable from "../Tables/AffiliatesTable";
// import BrandAffiliationCard from "../Shared/BrandAffiliationCard 2";

export default function BrentTest() {
  const [showModal, setShowModal] = useState(false);
  const handleModal = () => {
    setShowModal((prev) => !prev);
  };
  return (
    <div className="px-4">
      <div className="flex flex-wrap gap-8">
        {/* <AffiliateNavigation /> */}
        {/* <AffiliateHeader />
        <AffiliateCard />
        <CampaignCard />
        <UpcomingCampaignCard />
        <UpcomingCampaignCard expanded />
        <FullNotification />
        <ChangeProfileModal />
  <BankingModal showModal={showModal} toggleModal={() => handleModal()} /> */}
        <ShareCampaignModal toggleModal={handleModal} showModal={showModal} />
        <button onClick={() => setShowModal(!showModal)}>Open Modal</button>

        {/* <BrandAffiliationCard /> */}
      </div>
    </div>
  );
}
