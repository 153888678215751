import React, { useEffect } from 'react'

export default function ModalWrapper({ children, handleClose, title }) {

  useEffect(() => {
    const body = document.body;
    body.classList.add("modal-open");

    return () => {
      body.classList.remove("modal-open")
    };
  }, []);
  return (
    <div className="fixed inset-0 z-50 flex items-center  justify-center  bg-darkGray bg-opacity-80">
      <div className="relative  my-6 min-h-[450px] w-[70%] min-w-[350px] scrollbar-hide overflow-scroll max-h-[90vh] max-w-[750px] rounded-2xl bg-bafBlack">
        <div className={`${title} text-bafWhite flex flex-col  px-8  md:px-28 lg:px-36`}>
          <button
            className="ModalWrapperButton absolute top-0 right-0 float-right block h-6 w-6 border-0 bg-transparent py-0 pr-10 text-3xl font-bold text-bafWhite "
            onClick={handleClose}
            type="button"
          >
            x
          </button>
          {children}

        </div>
      </div>

    </div>
  )
}
