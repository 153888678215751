import React from "react";
import { twMerge } from "tailwind-merge";

export default function RefreshViewButton({
  small,
  color,
  large,
  background,
  className,
  onClick,
}) {
  const refreshViewButtonClass = twMerge(
    `RefreshButton flex items-center justify-center min-w-fit transition-all duration-200 text-center text-[14px] text-${color} border-3 border border-bafBlack bg-${background} 
     h-[38px] hover:bg-bafBlack hover:text-bafWhite w-full max-w-[206px] md:max-w-[171px] py-2
    ${small ? "px-2" : "px-2"} rounded-3xl font-bold ${small && "text-sm"} 
    ${large && "w-[250px]"} 
    ${
      className || ""
    } before:content-[url('../src/images/refresh-view-icon.svg')] before:mt-[6px] before:mr-2 before:hover:content-[url('../src/images/refresh-icon-hover.svg')]`
  );

  return (
    <button
      //* Data needs to be array of arrays OR array of objects
      className={refreshViewButtonClass}
      onClick={onClick}
    >
      Refresh View
    </button>
  );
}
