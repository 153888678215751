import React, { useState } from "react";
import HollowButton from "../Shared/HollowButton";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin, FaTwitter, FaInstagram } from "react-icons/fa";

import useDownloader from "react-use-downloader";
import axios from "axios";
import { useEffect } from "react";
import ShareButton from "../Shared/ShareButton";
import checkmark from "../images/check-mark.svg";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import ModalWrapper from "./ModalWrapper";
export default function ShareCampaignModal({
  showModal,
  toggleModal,
  campaign,
  token,
}) {
  const [facebookSelected, setFacebookSelected] = useState(false);
  const [linkedInSelected, setLinkedInSelected] = useState(false);
  const [instagramSelected, setInstagramSelected] = useState(false);
  const [twitterSelected, setTwitterSelected] = useState(false);

  const [page, setPage] = useState(1);
  const { download, percentage } = useDownloader();
  const [videoDownloaded, setVideoDownloaded] = useState(false);
  const [copied, setCopied] = useState(false);

  console.log(token);
  const {
    campaignDescription,
    campaignId,
    campaignImageLink,
    campaignVideoLink,
    campaignName,
    companyName,
    leadPayoutPerCoversion,
    shortUrlLink,
    status,
    totalLeads,
    totalLeadsThisWeek,
    totalShares,
    totalSharesThisWeek,
  } = campaign || {};
  const handleShare = (apiLink) => {
    // e.preventDefault();

    console.log("made it");
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}${apiLink}`,

        {
          campaignId: campaignId,
        },
        {
          headers: {
            Authorization: token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        console.log(res);
        setTimeout(() => {
          setPage(2);
        }, 5000);
      });
  };

  const startingIndex = campaignVideoLink && campaignVideoLink.lastIndexOf("/");
  const videoFileName =
    campaignVideoLink &&
    campaignVideoLink.slice(startingIndex + 1, campaignVideoLink.length);

  const imageFileName =
    campaignImageLink &&
    campaignImageLink.slice(startingIndex, campaignImageLink.length);

  const handleSelect = (platform) => {
    if (platform === "Facebook") {
      setFacebookSelected(true);
      if (!facebookSelected) {
        handleShare("sharedOnFb");
      }
    }

    if (platform === "LinkedIn") {
      setLinkedInSelected(true);
      if (!linkedInSelected) {
        handleShare("sharedOnLinkedIn");
      }
    }

    if (platform === "Twitter") {
      setTwitterSelected(true);
      if (!twitterSelected) {
        handleShare("sharedOnTwitter");
      }
    }
  };

  const handleInstagram = () => {
    setInstagramSelected((prev) => !prev);
    window.location.replace("instagram://story-camera");
    handleShare("sharedOnInstagram");
  };

  const handleClose = () => {
    toggleModal();
    setPage(1);
    setFacebookSelected(false);
    setTwitterSelected(false);
    setLinkedInSelected(false);
    setVideoDownloaded(false);
    const body = document.body;
    // body.classList.remove("modal-open");
  };

  const handleVideoDownload = () => {
    download(campaignVideoLink, videoFileName);
    setTimeout(() => {
      setVideoDownloaded(true);
    }, 3000);
  };

  const handleImageDownload = () => {
    download(campaignImageLink, imageFileName);

    window.location.replace("instagram://story-camera");
    // window.location.replace("instagram-stories://share");
  };

  console.log(shortUrlLink);
  console.log(isMobile);
  return (
    <>
      {showModal ? (
        <>
          <ModalWrapper handleClose={handleClose} title="ShareCampaignModal">
            {page === 1 && (
              <>
                <h1 className="text-center mt-12">Share Campaign:</h1>
                <h3 className="text-center mt-4">{campaignName}</h3>
                <a href={campaignImageLink} target="_blank" rel="noreferrer">
                  <img
                    src={campaignImageLink}
                    className="h-[200px] w-full  mx-auto mt-8 rounded-lg"
                    alt="campaign"
                  />
                </a>

                <p className="text-xs text-darkGray mt-6">Brand</p>
                <p className="font-semibold">{companyName}</p>
                <p className="text-[10px] text-darkGray mt-6">
                  {campaignDescription}
                </p>
                {campaignVideoLink && status === "active" && (
                  <>
                    <p className="text-center text-xs font-bold mt-6 max-w-[300px]">
                      Download the campaign video to gain extra points, or share
                      directly to your social media!
                    </p>

                    {!videoDownloaded ? (
                      <div className="mt-6 mx-auto">
                        <HollowButton
                          title="Download Video"
                          medium
                          backgroundHoverWhite
                          handleClick={() => handleVideoDownload()}
                          border="bafWhite"
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col items-center mt-4">
                        {" "}
                        <img src={checkmark} alt="checkmark" />
                        <p className="text-sm text-center px-12 md:px-0">
                          Campaign video has been downloaded
                        </p>
                      </div>
                    )}
                  </>
                )}

                <p className="text-center text-xs max-w-[260px] mx-auto font-bold mt-12">
                  Please select which platforms you would like to share this
                  campaign to:
                </p>
                <section className="flex justify-evenly flex-wrap">
                  <LinkedinShareButton title={campaignName} url={shortUrlLink}>
                    <ShareButton
                      icon={<FaLinkedin className="text-[40px] mt-6" />}
                      selected={linkedInSelected}
                      title={"LinkedIn"}
                      description={"Post"}
                      handleSelect={handleSelect}
                    />
                  </LinkedinShareButton>
                  <FacebookShareButton title={campaignName} url={shortUrlLink}>
                    <ShareButton
                      icon={<FaFacebook className="text-[40px] mt-6" />}
                      selected={facebookSelected}
                      title={"Facebook"}
                      description={"Post"}
                      handleSelect={handleSelect}
                    />
                  </FacebookShareButton>
                  <TwitterShareButton title={campaignName} url={shortUrlLink}>
                    <ShareButton
                      icon={<FaTwitter className="text-[40px] mt-6" />}
                      selected={twitterSelected}
                      title={"Twitter"}
                      description={"Tweet"}
                      handleSelect={handleSelect}
                    />
                  </TwitterShareButton>

                  <div className="phone:hidden">
                    <CopyToClipboard
                      text={shortUrlLink}
                      onCopy={() => setCopied(true)}
                    >
                      <span>
                        <ShareButton
                          icon={<FaInstagram className="text-[40px] mt-4" />}
                          selected={instagramSelected}
                          title={"Instagram"}
                          description={"Story"}
                          handleSelect={handleInstagram}
                        />
                      </span>
                    </CopyToClipboard>
                  </div>
                </section>
                <footer className="z-10 mt-10 flex items-center justify-between py-6 ">
                  <HollowButton
                    title="Back"
                    color="bafWhite"
                    border="bafWhite"
                    type="button"
                    backgroundHoverWhite
                    medium
                    handleClick={handleClose}
                    className="w-[120px] md:w-[150px]"
                  />
                  {/* <HollowButton
                        title="Share"
                        color="bafBlack"
                        background="bafWhite"
                        border="bafWhite"
                        type="button"
                        medium
                        handleClick={handleShare}
                        disabled={!facebookSelected || !instagramSelected}
                        className="w-[120px] md:w-[150px]"
                        backgroundHover
                      /> */}
                </footer>
              </>
            )}

            {page === 2 && (
              <div className="mt-12 md:mt-20">
                <h2 className="text-center font-bold">Thanks for sharing</h2>
                <p className="mt-8 text-center">
                  You have successfully shared{" "}
                  <span className="text-darkPurple"> {campaignName}</span>.
                </p>
                <footer className="mt-40 flex justify-center w-full  mx-auto">
                  <HollowButton
                    title="Close"
                    color="bafWhite"
                    border="bafWhite"
                    type="button"
                    backgroundHoverWhite
                    medium
                    handleClick={handleClose}
                    className="w-[120px] md:w-[150px]"
                  />
                </footer>
              </div>
            )}
          </ModalWrapper>
        </>
      ) : null}
    </>
  );
}
