import React, { useState } from "react";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

export default function SignUpInput({
  title,
  value,
  onChange,
  id,
  type,
  error,
  onBlur,
  maxWidth,
  disabled,
}) {
  const [show, setShow] = useState(false);

  // handle eye icon toggle
  const toggle = () => {
    setShow(!show);
  };
  return (
    <div className="my-4 w-full md:w-auto ">
      <div className="Input form-group relative mt-8 max-h-fit w-full">
        <input
          disabled={disabled}
          type={type === "password" ? (show ? "text" : "password") : type}
          id={id}
          className={`${error && 'text-errorRed'} form-control !focus:outline-none } peer w-full rounded-md 
            border-0 px-3 py-2 shadow-[inset_-4px_-4px_4px_#FFFFFF,inset_4px_4px_4px_rgba(0,0,0,0.05)] !outline-none outline-none focus:border-2 focus:border-bafBlack`}
          value={value}
          onChange={onChange}
          name={id}
          onBlur={onBlur}
        />
        <label
          htmlFor={id}
          className={`${
            error && "text-errorRed"
          } col-form-label peer-focus:text-black pointer-events-none absolute left-[-10px] -top-5 mt-2 bg-bafWhite px-4 text-base transition-all peer-focus:-top-8 peer-focus:text-sm`}
        >
          {title}
        </label>
        {type === "password" ? (
          show === false ? (
            <div className="absolute top-1 right-5 mt-1 text-2xl">
              <AiOutlineEyeInvisible onClick={toggle} />
            </div>
          ) : (
            <div className="absolute top-1 right-5 mt-1 text-2xl">
              <AiOutlineEye onClick={toggle} />
            </div>
          )
        ) : null}
      </div>
      <p className="ErrorMessage ml-1 mt-2 text-[10px] italic text-errorRed">
        {error}
      </p>
    </div>
  );
}
