import React, { useEffect } from "react";
import LayoutBtnOn from "../images/layout-button-on.svg";
import LayoutBtnOff from "../images/layout-button-off.svg";
import HamburgerIconOn from "../images/hamburger-menu-on.svg";
import HamburgerIconOff from "../images/hamburger-menu-off.svg";

export default function LayoutButton({
  cardView,
  handleClickCardLayout,
  handleClickTableLayout,
  className,
}) {
  const downloadBtnClassName =
    "border-none px-2 py-3 w-full text-left font-normal rounded-none";

  return (
    <div className={`LayoutButtonWrapper flex gap-4 ${className || null}`}>
      {
        <img
          src={`${cardView ? LayoutBtnOn : LayoutBtnOff}`}
          alt="layout-icon"
          onClick={handleClickCardLayout}
          className="cursor-pointer"
        />
      }
      <img
        src={`${cardView ? HamburgerIconOff : HamburgerIconOn}`}
        alt="hamburger-icon"
        onClick={handleClickTableLayout}
        className="cursor-pointer"
      />
    </div>
  );
}
