import React, { useState } from "react";
import AffiliateTableRow from "./AffiliatesTableRow";
import avatar from "../images/tire-avatar.png";

export default function AffiliatesTable({
  affiliates,
  setRefreshView,
  token,
  refresh,
}) {
  const [selectedAffiliates, setSelectedAffiliates] = useState([
    { id: "1", selected: false },
    { id: "2", selected: false },
    { id: "3", selected: false },
  ]);

  //   const affiliatesWithId =
  //     affiliates &&
  //     affiliates.map((affiliate, i) => {
  //       return { id: i, selected: false, ...affiliate };
  //     });

  //* Set Selected Affiliate Based On ID
  const handleSelect = (id) => {
    const updatedAffiliates =
      selectedAffiliates &&
      selectedAffiliates.map((aff) => {
        if (aff.id === id) {
          return { ...aff, selected: !aff.selected };
        }
        return aff;
      });

    setSelectedAffiliates(updatedAffiliates);
  };

  return (
    <div className="AffiliatesTable w-full rounded-lg shadow-lg bg-bafWhite">
      <table className="w-full table-fixed">
        <thead className="">
          <tr className="CategoryRow grid grid-cols-[6fr_3.5fr_3.5fr_3.5fr_2fr_2fr_1fr] justify-between border-[#ABABAB] mb-4 px-4 pt-6 lg:px-8 gap-2">
            <th className={`text-start text-[#ABABAB] text-xs`}>Affiliate</th>
            <th
              className={`hidden md:table-cell text-start text-[#ABABAB] text-xs`}
            >
              Total Leads
            </th>
            <th
              className={`hidden md:table-cell text-start text-[#ABABAB] text-xs`}
            >
              Total Shares
            </th>
            <th
              className={`hidden md:table-cell text-start text-[#ABABAB] text-xs`}
            >
              Campaigns
            </th>
          </tr>
        </thead>
        <tbody>
          {affiliates &&
            affiliates.map((aff) => (
              <AffiliateTableRow
                token={token}
                key={aff.id}
                id={aff.id}
                selected={aff.selected}
                handleSelect={handleSelect}
                affiliateData={aff}
                setRefreshView={setRefreshView}
                refresh={refresh}
              />
            ))}
          {affiliates && affiliates.length === 0 && (
            <div className="AffiliatesTableRow w-full bg-veryLightGreen last:rounded-b-md">
              <tr
                className={` w-[100%] flex justify-between items-center p-4 md:px-8 md:gap-4`}
              >
                <td className="font-bold tracking-wider">
                  No affiliates found.
                </td>
              </tr>
            </div>
          )}
        </tbody>
      </table>
    </div>
  );
}
