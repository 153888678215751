import React, { Suspense } from "react";
import SignUp from "./Authentication/SignUp";
import LoginPage from "./Authentication/LoginPage";
import BrentTest from "./Pages/BrentTest";
import LoadScreen from "./Shared/LoadScreen";
import Header from "./Shared/Header";
import { Routes, Route, Navigate } from "react-router-dom";
import Test from "./Pages/Test";
import UnaffiliatePage from "./Pages/UnaffiliatePage";
import NotFoundPage from "./Pages/404page";
import ForgotEmail from "./Authentication/ForgotEmail";
import ForgotPhone from "./Authentication/ForgotPhone";
import ResetPassword from "./Authentication/ResetPassword";

//* CODE SPLITTING
const OverviewPage = React.lazy(() => import("./Pages/Overview/OverviewPage"));
const NotificationsPage = React.lazy(() =>
  import("./Pages/Notifications/NotificationsPage")
);
const CompanyProfilePage = React.lazy(() =>
  import("./Pages/CompanyProfile/CompanyProfilePage")
);

const SingleCampaignPage = React.lazy(() =>
  import("./Pages/SingleCampaign/SingleCampaignPage")
);
const WalletPage = React.lazy(() => import("./Pages/Wallet/WalletPage"));

const AffiliateProfilePage = React.lazy(() =>
  import("./Pages/AnotherAffiliateProfile/AffiliateProfilePage")
);

const MyProfilePage = React.lazy(() =>
  import("./Pages/MyProfile/MyProfilePage")
);

const ActivityPage = React.lazy(() => import("./Pages/Activity/ActivityPage"));

const CampaignsPage = React.lazy(() =>
  import("./Pages/Campaigns/CampaignsPage")
);

const TopAffiliatesPage = React.lazy(() =>
  import("./Pages/TopAffiliates/TopAffiliatesPage")
);

const BrandPage = React.lazy(() => import("./Pages/Brands/BrandPage"));
const ProtectedRoute = ({ token, children }) => {
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function App() {
  const token = localStorage.getItem("token");
  const userInfo = localStorage.getItem("user");
  let userObj = {};
  if (userInfo) {
    userObj = JSON.parse(userInfo);
  }

  // const user = JSON.parse(userStorage);
  const loggedIn = !!token;
  console.log(userInfo);

  // if (loggedIn) {
  //   document.title = `Brandaf - ${userObj.companyName}`;
  // }
  return (
    <div className="App bg-lightGray">
      <Header token={token} />

      <Routes>
        <Route
          path="brent-test"
          element={
            <Suspense fallback={<div></div>}>
              {/* <ProtectedRoute token={token}> */}
              <BrentTest />
              {/* </ProtectedRoute> */}
            </Suspense>
          }
        />

        <Route
          path="/sign-in"
          element={
            <Suspense fallback={""}>
              {loggedIn ? <Navigate to="/overview" /> : <SignUp />}
            </Suspense>
          }
        />

        <Route
          path="/login"
          element={
            <Suspense fallback={""}>
              {loggedIn ? <Navigate to="/overview" /> : <LoginPage />}
            </Suspense>
          }
        />

        <Route
          path="forgot-email"
          element={
            <Suspense fallback={""}>
              <ForgotEmail token={token} />
            </Suspense>
          }
        />

        <Route
          path="forgot-phone"
          element={
            <Suspense fallback={""}>
              <ForgotPhone token={token} />
            </Suspense>
          }
        />

        <Route
          path="reset-password/resetCode/:resetCode/affId/:affId"
          element={
            <Suspense fallback={""}>
              <ResetPassword token={token} />
            </Suspense>
          }
        />

        <Route
          path="/"
          element={
            <Suspense fallback={<LoadScreen />}>
              <ProtectedRoute token={token}>
                <OverviewPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="/overview"
          element={
            <Suspense fallback={<LoadScreen />}>
              <ProtectedRoute token={token}>
                <OverviewPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="/single-campaign/:id"
          element={
            <Suspense fallback={<LoadScreen />}>
              <ProtectedRoute token={token}>
                <SingleCampaignPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="notifications"
          element={
            <Suspense fallback={<LoadScreen />}>
              <ProtectedRoute token={token}>
                <NotificationsPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="/wallet"
          element={
            <Suspense fallback={<LoadScreen />}>
              <ProtectedRoute token={token}>
                <WalletPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="/activity"
          element={
            <Suspense fallback={<LoadScreen />}>
              <ProtectedRoute token={token}>
                <ActivityPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="/top-affiliates"
          element={
            <Suspense fallback={<LoadScreen />}>
              <ProtectedRoute token={token}>
                <TopAffiliatesPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="/Evan-testing"
          element={
            <Suspense fallback={""}>
              <Test />
            </Suspense>
          }
        />

        <Route
          path="/unaffiliate"
          element={
            <Suspense fallback={""}>
              <UnaffiliatePage />
            </Suspense>
          }
        />

        <Route
          path="/404page"
          element={
            <Suspense fallback={""}>
              <NotFoundPage />
            </Suspense>
          }
        />
        <Route
          path="/profile"
          element={
            <Suspense fallback={<LoadScreen />}>
              <ProtectedRoute token={token}>
                <MyProfilePage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />

        <Route
          path="/brands"
          element={
            <Suspense fallback={<LoadScreen />}>
              <ProtectedRoute token={token}>
                <BrandPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="/company-profile/:id"
          element={
            <Suspense fallback={<LoadScreen />}>
              <ProtectedRoute token={token}>
                <CompanyProfilePage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="/campaigns"
          element={
            <Suspense fallback={<LoadScreen />}>
              <ProtectedRoute token={token}>
                <CampaignsPage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />
        <Route
          path="/affiliate-profile/:id"
          element={
            <Suspense fallback={<LoadScreen />}>
              <ProtectedRoute token={token}>
                <AffiliateProfilePage token={token} />
              </ProtectedRoute>
            </Suspense>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
