import React from "react";
import { twMerge } from "tailwind-merge";

export default function HollowButton({
  title,
  handleClick,
  small,
  color,
  medium,
  large,
  mediumLarge,
  fullWidth,
  background,
  type,
  className,
  disabled,
  backgroundHoverPurple,
  backgroundHoverWhite,
  backgroundHoverBlack,
  border,
  tooltip,
  form,
}) {
  const hollowBtnClassName = twMerge(
    `HollowButton z-0 transition-all min-w-fit duration-200 border ${border ? `border-${border}` : "border-bafBlack"
    }  rounded-3xl font-bold text-[12px] md:text-[14px] text-center py-1  px-2 md:px-4 md:whitespace-nowrap max-w-[250px]
    ${disabled
      ? "border-darkGray text-darkGray"
      : `bg-${background} text-${color} ${backgroundHoverWhite
        ? "hover:bg-bafWhite hover:border-bafBlack hover:text-bafBlack"
        : backgroundHoverPurple
          ? "hover:bg-darkPurple hover:border-transparent hover:text-bafWhite"
          : backgroundHoverBlack
            ? "hover:bg-bafBlack hover:border-bafBlack hover:text-bafWhite"
            : "hover:bg-darkPurple hover:text-bafWhite hover:border-transparent"
      }`
    }

    
    ${small && "max-w-[116px] h-9"}
    ${medium && " h-12 min-w-[155px] max-w-[165px]"}
    ${large && "w-[250px] h-12"}
    ${fullWidth && "w-full"}
    ${mediumLarge && "h-12"}
    ${className || ""}
    `
  );

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={handleClick}
      className={hollowBtnClassName}
      data-tip={tooltip && "Please fill all fields and save payment info"}
      form={form}
    >
      {title}
    </button>
  );
}
