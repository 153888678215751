// import axios from "axios";
import { React } from "react";
import { ReactComponent as CloseIcon } from "../images/modal-close-button-white.svg";
import HollowButton from "../Shared/HollowButton";

export default function LeavePageModal({ showModal, handleModal, navigate }) {
  const toggleModal = () => {
    handleModal();
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="LeavePageModal fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-bafGrey bg-opacity-30 outline-none backdrop-brightness-[0.2] focus:outline-none">
            <div className="relative my-6 mx-auto h-[85%] w-[70%] min-w-[350px] max-w-[750px] rounded-2xl bg-bafBlack md:h-[70%] md:w-[80%]">
              <div className="relative flex h-full w-full flex-col justify-center rounded-2xl border-0 bg-bafBlack shadow-lg outline-none focus:outline-none">
                <button
                  className="absolute border-0 bg-transparent text-black top-4 right-4"
                  onClick={() => handleModal()}
                >
                  <CloseIcon className="w-5 h-5" />
                </button>
                <div className="flex flex-col items-center p-5">
                  <h3 className="text-center text-[35px]  font-semibold text-bafWhite w-[80%] md:text-[40px]">
                    Leaving Page?
                  </h3>
                  <p className="mt-11 text-center text-xl font-semibold text-bafWhite md:w-[85%] lg:w-[75%]">
                    You have made changes to this page. Any unsaved information
                    will be discarded.
                  </p>
                  <p className="mt-8 text-center text-xl font-semibold text-bafWhite md:mt-12 lg:w-[70%]">
                    Do you want to continue?
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center p-6 pt-12 gap-11 md:flex-row md:pt-20">
                  <HollowButton
                    title="Leave"
                    color="bafWhite"
                    background="bafBlack"
                    backgroundHoverWhite
                    type="button"
                    className="rounded-full w-[150px] h-14 p-3 border border-bafWhite"
                    handleClick={navigate}
                  />
                  <HollowButton
                    title="Cancel"
                    color="bafBlack"
                    background="bafWhite"
                    backgroundHoverPurple
                    type="button"
                    className="rounded-full w-[150px] h-14 p-3"
                    handleClick={() => toggleModal()}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
