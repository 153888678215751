import React, { useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Authentication/auth-context";
import { ReactComponent as CloseIcon } from "../images/modal-close-button-white.svg";
import HollowButton from "../Shared/HollowButton";

export default function JoinBrandModal({
  showModal,
  handleModal,
  token,
  dealerId,
  companyName,
}) {
  const unFollowBrand = () => {
    axios
      .post(
        `${process.env.REACT_APP_PROD_URL}unFollowBrand`,
        { dealerId: dealerId },
        {
          headers: { Authorization: token, "Access-Control-Allow-Origin": "*" },
        }
      )
      .then((res) => {
        console.log(res);

        if (res.data.responseCode === 0) {
          alert(`${res.data.successMsg}`);
          window.location.reload();
        } else {
          alert("Leave brand unsuccessful. Please try again.");
          window.location.reload();
        }
      });
  };

  const toggleModal = () => {
    handleModal();
    window.location.reload();
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-bafBlack bg-opacity-10 outline-none backdrop-brightness-[0.2] focus:outline-none">
            <div className="relative my-6 mx-auto h-[90%] w-[90%] max-w-[750px] rounded-2xl bg-bafBlack md:min-w-[350px] md:h-[70%] md:max-h-[800px]">
              <div className="relative flex h-full w-full flex-col justify-center rounded-2xl border-0 bg-black shadow-lg outline-none focus:outline-none">
                <div className="flex h-[400p] flex-col items-center justify-evenly p-5 md:h-[300px]">
                  <h1 className="text-center font-semibold text-bafWhite xs:text-[40px] md:w-[80%]">
                    {`You have joined ${companyName}!`}
                  </h1>
                  <p className="mt-8 xs:mt-12 text-center text-xl font-semibold text-bafWhite md:mt-12 md:w-[80%]">
                    You can start sharing campaigns from this brand!
                  </p>

                  <button
                    className="absolute border-0 bg-transparent text-black top-4 right-4"
                    onClick={() => toggleModal()}
                  >
                    <CloseIcon className="w-5 h-5" />
                  </button>
                </div>
                <div className="mt-8 xs:mt-12 flex flex-col justify-center items-center gap-11 md:flex-row">
                  <HollowButton
                    title="Undo"
                    color="bafWhite"
                    border="bafWhite"
                    type="button"
                    fullWidth
                    backgroundHoverWhite
                    handleClick={() => unFollowBrand()}
                    className="rounded-full max-w-[176px] h-14"
                  />
                  <HollowButton
                    title="Got it!"
                    color="bafBlack"
                    background="bafWhite"
                    type="button"
                    fullWidth
                    handleClick={() => toggleModal()}
                    className="rounded-full max-w-[176px] h-14"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
