export default function UnaffiliatePage() {
  return (
    <div className="bg-[url('/src/images/404-crying-astronaut-mobile.gif')] md:bg-[url('/src/images/404-crying-astronaut.gif')] bg-bafBlack bg-contain bg-no-repeat bg-bottom md:bg-cover lg:[background-position:_0%] relative z-10 min-h-screen flex justify-center overflow-hidden md:justify-start">
      <header className="p-12 md:mt- md:w-[65%] lg:mt-20 xl:mt-40 xl:w-[60%] xl:p-24">
        <h1 className="font-semibold text-bafWhite text-[40px]">
          We're Sad To See You Go!
        </h1>
        <p className="text-bafWhite mt-8 text-sm">
          Before we lose you completely, you have{" "}
          <span className="font-bold shadow-[inset_0_-0.6em_#16DB93]">
            15 days
          </span>{" "}
          to request for account recovery.
        </p>
        <p className="text-bafWhite mt-4 text-sm">
          Contact{" "}
          <a
            href="mailto:info@brandaffiliate.com"
            className="shadow-[inset_0_-0.6em_#A590E3] font-bold"
          >
            info@brandaffiliate.com
          </a>{" "}
          to restore your account or for any assistance.
        </p>
      </header>
    </div>
  );
}
