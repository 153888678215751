import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";

export default function useStateValues() {
  const [interests, setInterests] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_PROD_URL}getIntrestArea`)
      .then((res) => setInterests(res.data.response));
  }, []);
  return interests;
}
