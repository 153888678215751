import React, { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import Pagination from "../Shared/Pagination";
import BrandRow from "./BrandRow";

export default function BrandTable({
  leads,
  currentPage,
  total,
  brands,
  brandType,
  handlePageChange,
  token,
  index,
  brandFilter,
}) {
  const [selectedAffiliates, setSelectedAffiliates] = useState([
    { id: "1", selected: false },
    { id: "2", selected: false },
    { id: "3", selected: false },
  ]);
  const leadsWithId =
    leads &&
    leads.map((lead, i) => {
      return { id: i, selected: false, ...lead };
    });

  //* Set Selected Affiliate Based On ID
  const handleSelect = (id) => {
    const updatedAffiliates = selectedAffiliates.map((aff) => {
      if (aff.id === id) {
        return { ...aff, selected: !aff.selected };
      }
      return aff;
    });

    setSelectedAffiliates(updatedAffiliates);
  };

  const [pathName, setPathName] = useState([]);

  useEffect(() => {
    setPathName(window.location.pathname);
  }, []);

  const PAGE_SIZE = 1;

  const groupIndex = useMemo(
    () => Math.floor(currentPage / PAGE_SIZE),
    [currentPage]
  );

  let totalPages = 0;
  if (leads && leads.length >= 6) {
    totalPages = Math.ceil(total / leads.length);
  }

  const isLast = useMemo(() => {
    return Math.ceil((currentPage + 1) / 4) < Math.ceil(totalPages / 4);
  }, [currentPage, totalPages]);

  const isFirst = useMemo(() => {
    return currentPage < 4;
  }, [currentPage]);

  let pages = Math.floor(
    total > 6 ? Math.ceil(total / 6) : Math.floor(total / 6) || 0
  );

  return (
    <>
      <div className="BrandTable mb-4 w-full rounded-md bg-bafWhite">
        <table className="w-full table-fixed ">
          <thead className="">
            <tr
              className={`border-[#ABABAB] px-4 pt-6 pb-4 flex gap-2 md:gap-4 md:px-6 md:pt-7
              ${
                brandFilter === "topPerforming"
                  ? "grid-cols-[0.5fr_3fr_1fr_1fr_0.5fr] md:grid md:grid-cols-[0.60fr_3fr_1.5fr_1.5fr_1.67fr_3.33fr]"
                  : "grid-cols-[3fr_1fr_1fr_0.5fr] md:grid md:grid-cols-[3.33fr_1.67fr_1.67fr_1.67fr_3.33fr]"
              }
              `}
            >
              {brandFilter === "topPerforming" && (
                <th className={`text-start text-[#ABABAB] text-xs`}>Rank</th>
              )}
              <th
                className={`text-start text-xs text-[#ABABAB] w-[50%] md:w-auto`}
              >
                Brand
              </th>
              <th
                className={`text-start text-xs text-[#ABABAB] w-[20%] md:w-auto`}
              >
                Affiliates
              </th>
              <th
                className={`text-start text-xs text-[#ABABAB] w-[30%] md:w-auto`}
              >
                Campaigns
              </th>
              <th
                className={`hidden text-start text-xs text-[#ABABAB] md:table-cell md:w-auto`}
              >
                Joined Date
              </th>
            </tr>
          </thead>
        </table>
        <table className="w-full table-fixed">
          {brands.map((brand, index) => (
            <BrandRow
              brands={brand}
              brandType={brandType}
              handleSelect={handleSelect}
              pathName={pathName}
              token={token}
              index={index}
              currentPage={currentPage}
              brandFilter={brandFilter}
            />
          ))}

          {brands && brands.length === 0 && (
            <tr
              className={`BrandTableRow flex w-full items-center justify-between p-4 last:rounded-b-md odd:bg-[#B5FFB8] odd:bg-opacity-[0.15] md:gap-4 md:px-8`}
            >
              <td>No brands found.</td>
            </tr>
          )}
        </table>
      </div>
    </>
  );
}
